﻿export class Configuracion {
    id: number = 0;
    importeMaximo: number;
    importeMinimo: number;
    pedirOrdenCompra: boolean;
    tipoOrdenCompraId: number;
    diasTolerancia: number;
    permitirIngresoFacturasSinComplemento: boolean;
    inicioValidacionFacturasSinComplemento: Date;
    tipoEnvioFacturaSinOC: string;
}