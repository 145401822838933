import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Sociedad } from 'ClientApp/app/entidades/sociedad';
import { Servicios } from 'ClientApp/app/servicios/servicios';
import { DatepickerMode, PopupPlacement } from 'ng2-semantic-ui';
import * as _ from 'lodash';
import { ExportToXlsxService } from 'ClientApp/app/servicios/exportToXls';
import { Proveedor } from 'ClientApp/app/entidades/indice';

@Component({
  selector: 'app-reporte-flujo-compras',
  templateUrl: './reporte-flujo-compras.component.html',
  styleUrls: ['./reporte-flujo-compras.component.css']
})
export class ReporteFlujoComprasComponent implements OnInit {

  titulo: string = 'Flujo de Compras';
  Procesando: boolean;
  sociedades: Sociedad[] = [];
  sociedadComercial: any;
  origen: any;
  origenes: any[] = [];
  diferencia: any;
  diferencias: any[] = [];
  proveedor: any;
  proveedores: Proveedor[];
  fechaRegistroDe: any;
  fechaRegistroA: any;
  modoFecha = DatepickerMode.Date;
  cvsTodas: any[] = [];
  posicionCalendario: PopupPlacement = PopupPlacement.Bottom;  
  cvsTotal: number = 0;
  cvsPaginas: any[] = [];
  cvsPagina: any[] = [];
  itemsPerPage: number = 10;
  ocStr = '';
  page: number = 1;
  pages: number = 1;
  filter: string;
  key = 'cv';
  reverse = false;
 
  


  constructor(
    private servicios: Servicios,
    private exportToXlsxService: ExportToXlsxService    

  ) {     
    this.origen = 'xmonitor';
    this.origenes =[
      {
        value: 'xmonitor',
        display: 'Xmonitor'
      },
      {
        value: 'sap',
        display: 'SAP'
      },
      {
        value: 'otro',
        display: 'Otro'
      }
    ];  
    
    this.diferencia = 'Todas';
    this.diferencias = [
      {
        value: '1',
        display: 'Todas'
      },
      {
        value: '2',
        display: 'Si'
      },
      {
        value: '3',
        display: 'No'
      }
    ]
  }



  ngOnInit() {
    this.cargarSociedades();
    this.cargarProveedores();
    this.cargarReporte();    
    
  }

 

  sort( key) {
    this.key = key;
    this.reverse = !this.reverse;
  }  

  seleccionarOrigen(){
    this.sociedadComercial = null;
    this.fechaRegistroDe = null;
    this.fechaRegistroA = null;
    this.cargarReporte();
  } 

  cargarSociedades() {
		this.servicios.sociedades.obtenerTodos().subscribe((lista) => {
			this.sociedades = lista;            
		});
	}

  cargarProveedores() {
    this.servicios
        .proveedores
        .ObtenerProveedores()
        .subscribe(
            proveedores => {                                
                proveedores.sort((a: any, b: any) => a.razonSocial - b.razonSocial); 
                this.proveedores = proveedores;                                                                             
            }
        );
  }

  seleccionarItemsPerPage() {
    this.page = 1;
  }

  limpiarFiltro() {    
    this.sociedadComercial = null;
    this.fechaRegistroDe = null;
    this.fechaRegistroA = null;
    this.proveedor = null;
    this.page = 1;
    this.cvsPaginas = _.cloneDeep(this.cvsTodas);
    this.cvsTotal = this.cvsPaginas.length; 
  }

  exportarExcel() {    
    this.exportToXlsxService.reporteFlujoCompas('Reporte Flujo de Compras', this.cvsPaginas, this.origen);
  }

  aplicarFiltro() {
    this.Procesando = true;
    this.page = 1;
    this.cvsPaginas = [];    
    if(String(this.origen) === 'xmonitor') {          
      if (this.sociedadComercial) {       
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => Number(mCV.sociedadId) === Number(this.sociedadComercial)));
      } else {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }

      if (this.diferencia == 2) {       
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === true));
      } else if (this.diferencia == 3){
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === false));
      }else{
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }
      
      if(this.proveedor) {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => (mCV.proveedor) === (this.proveedor)));
      }      
      
      if (this.fechaRegistroDe && this.fechaRegistroA) {        
        if(new Date(this.fechaRegistroDe).getTime() <= new Date(this.fechaRegistroA).getTime() ) {
          this.cvsPaginas = _.cloneDeep(this.cvsPaginas).filter(mCV => new Date(mCV.fechaOCReferi).getTime() >= new Date(this.fechaRegistroDe).getTime() && new Date(mCV.fechaOCReferi).getTime() <= new Date(this.fechaRegistroA).getTime());          
        }         
      }      

      if(this.ocStr != ''){
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => Number(mCV.cv) === Number(this.ocStr)));
      }

      this.cvsPaginas.sort(
        (objA, objB) => new Date(objB.fechaOCReferi).getTime() - new Date(objA.fechaOCReferi).getTime(),
      );
    } else  if(String(this.origen) === 'otro' ) {      
      if (this.sociedadComercial) {        
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => Number(mCV.sociedadId) === Number(this.sociedadComercial)));
      } else {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }
      
      if (this.diferencia == 2) {       
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === true));
      } else if (this.diferencia == 3){
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === false));
      }else{
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }

      if(this.proveedor) {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => (mCV.proveedor) === (this.proveedor)));
      }
      
      
      if (this.fechaRegistroDe && this.fechaRegistroA) {        
        if(new Date(this.fechaRegistroDe).getTime() <= new Date(this.fechaRegistroA).getTime() ) {          
          this.cvsPaginas = _.cloneDeep(this.cvsPaginas).filter(mCV => new Date(mCV.fechaAuthReferi).getTime() >= new Date(this.fechaRegistroDe).getTime() && new Date(mCV.fechaAuthReferi).getTime() <= new Date(this.fechaRegistroA).getTime());          
        }         
      } 

      if(this.ocStr != ''){
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.folioFiscal === this.ocStr));
      }

      this.cvsPaginas.sort(
        (objA, objB) => new Date(objB.fechaOCReferi).getTime() - new Date(objA.fechaOCReferi).getTime(),
      );
    } else {
      console.log('si sap')
      this.cvsPaginas = this.cvsTodas;
      if (this.sociedadComercial) {       
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => Number(mCV.sociedadId) === Number(this.sociedadComercial)));
      } else {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }

      if (this.diferencia == 2) {       
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === true));
      } else if (this.diferencia == 3){
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => mCV.erroneo === false));
      }else{
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas);
      }
      
      if(this.proveedor) {
        this.cvsPaginas  = _.cloneDeep(this.cvsTodas.filter(mCV => (mCV.proveedor) === (this.proveedor)));
      }    
      
      if (this.fechaRegistroDe && this.fechaRegistroA) {        
        if(new Date(this.fechaRegistroDe).getTime() <= new Date(this.fechaRegistroA).getTime() ) {
          this.cvsPaginas = _.cloneDeep(this.cvsPaginas).filter(mCV => new Date(mCV.fechaOCSAP).getTime() >= new Date(this.fechaRegistroDe).getTime() && new Date(mCV.fechaOCSAP).getTime() <= new Date(this.fechaRegistroA).getTime());          
        }         
      }      
      this.cvsPaginas.sort(
        (objA, objB) => new Date(objB.fechaOCSAP).getTime() - new Date(objA.fechaOCSAP).getTime(),
      );      
    }    
    
   
    
    this.cvsTotal = this.cvsPaginas.length;    
    this.Procesando = false;
  }


  cargarReporte() {
    this.Procesando = true;
    this.page = 1;    
    if(String(this.origen) === 'xmonitor') {
      this.servicios.archivos.reporteFlujoCompras()
      .then(async cvs => {        
        this.cvsTodas = [];
        for(const cv of cvs){          
          this.cvsTodas.push(
            {
              cv: cv.cv,
              proveedor: cv.proveedor,              
              fechaOCReferi: cv.fechaOCReferi,
              fechaOCSAP: cv.fechaOCSAP,
              fechaAuthReferi: cv.fechaAuthReferi,
              numFactSAP: cv.numFactSAP,
              userRegistroFactura: cv.userRegistroFactura,
              fechaRegistroFactura: cv.fechaRegistroFactura,
              fechaPagoSAP: cv.fechaPagoSAP,
              importePagadoSAPCurrency: cv.importePagadoSAPCurrency,
              importePagadoSAPPesos: cv.importePagadoSAPPesos,
              currency: cv.currency,
              importeFacturaReferi: cv.importeFacturaReferi,
              importeFacturaSAPCurrency: cv.importeFacturaSAPCurrency,
              importeFacturaSAPPesos: cv.importeFacturaSAPPesos,
              erroneo : await this.marcar(cv,this.origen),
              sociedadId: cv.sociedadId,
              id: cv.id
            }
          )          
        }       
       
        this.cvsTodas.sort(
          (objA, objB) => new Date(objB.fechaOCReferi).getTime() - new Date(objA.fechaOCReferi).getTime(),
        );
       
        this.aplicarFiltro();
      })
      .catch(error => {
        this.Procesando = false;
      });
    } else  if(String(this.origen) === 'otro') {
      this.servicios.archivos.reporteFlujoComprasOtros()
      .then(async archivos => {        
        this.cvsTodas = []              
        for(const archivo of archivos) {            
          this.cvsTodas.push(
            {
              folioFiscal: archivo.folioFiscal,
              proveedor: archivo.proveedor,              
              fechaAuthReferi: archivo.fechaAuthReferi,
              fechaOCSAP: archivo.fechaOCSAP,
              numFactSAP: archivo.numFactSAP,
              userRegistroFactura: archivo.userRegistroFactura,
              fechaRegistroFactura: archivo.fechaRegistroFactura,
              fechaPagoSAP: archivo.fechaPagoSAP,
              importePagadoSAPCurrency: archivo.importePagadoSAPCurrency,
              importePagadoSAPPesos: archivo.importePagadoSAPPesos,
              currency: archivo.currency,
              importeFacturaReferi: archivo.importeFacturaReferi,
              importeFacturaSAPCurrency: archivo.importeFacturaSAPCurrency,
              importeFacturaSAPPesos: archivo.importeFacturaSAPPesos,
              erroneo : await this.marcar(archivo,this.origen),
              sociedadId: archivo.sociedadId,
              id: archivo.id
            }
          )
        }
        
      
        this.cvsTodas.sort(
          (objA, objB) => new Date(objB.fechaOCReferi).getTime() - new Date(objA.fechaOCReferi).getTime(),
        );
        
        this.aplicarFiltro();
      })
      .catch(error => {
        this.Procesando = false;
      });
    } else {
      // ORIGEN SAP
      this.servicios.archivos.reporteFlujoComprasSAP()
      .then(async cvs => {  
        console.log('cvs-->',cvs)
        this.cvsTodas = [];
        for(const cv of cvs){          
          this.cvsTodas.push(
            {
              cv: cv.cv,
              proveedor: cv.proveedor,              
              fechaOCReferi: cv.fechaOCReferi,
              fechaOCSAP: cv.fechaOCSAP,
              fechaAuthReferi: cv.fechaAuthReferi,
              numFactSAP: cv.numFactSAP,
              userRegistroFactura: cv.userRegistroFactura,
              fechaRegistroFactura: cv.fechaRegistroFactura,
              fechaPagoSAP: cv.fechaPagoSAP,
              importePagadoSAPCurrency: cv.importePagadoSAPCurrency,
              importePagadoSAPPesos: cv.importePagadoSAPPesos,
              currency: cv.currency,
              importeFacturaReferi: cv.importeFacturaReferi,
              importeFacturaSAPCurrency: cv.importeFacturaSAPCurrency,
              importeFacturaSAPPesos: cv.importeFacturaSAPPesos,
              erroneo : await this.marcar(cv,this.origen),
              sociedadId: cv.sociedadId,
              id: cv.id
            }
          )          
        }     
        
      
        this.cvsTodas.sort(
          (objA, objB) => new Date(objB.fechaOCSAP).getTime() - new Date(objA.fechaOCSAP).getTime(),
        );        
        this.aplicarFiltro();
      })
      .catch(error => {
        this.Procesando = false;
      });

    }
  }

  public marcar(renglon: any, origen: String): Promise<Boolean> {
    return new Promise((resolve) => {
      if(String(this.origen) === 'xmonitor' || String(this.origen) === 'sap') {
        const rengloValidacionXmonitor = {
          currency: renglon.currency,
          valor1: renglon.currency === 'USD' ? Math.trunc(renglon.importeFacturaSAPCurrency) : Math.trunc(renglon.importeFacturaSAPPesos),
          valor2: renglon.fechaPagoSAP ? renglon.currency === 'USD' ? Math.trunc(renglon.importePagadoSAPCurrency) : Math.trunc(renglon.importePagadoSAPPesos): null,
          valor3:  renglon.currency === 'USD' ? Math.trunc(renglon.importePagadoSAPCurrency) : Math.trunc(renglon.importePagadoSAPPesos),
          valor4: renglon.importeFacturaReferi ? Math.trunc(renglon.importeFacturaReferi) : '0.0'      
        }
        if(rengloValidacionXmonitor.currency && rengloValidacionXmonitor.valor1 && (rengloValidacionXmonitor.valor2 || rengloValidacionXmonitor.valor3)) {
          if(rengloValidacionXmonitor.valor2 && rengloValidacionXmonitor.valor3) {
            resolve(!(String(rengloValidacionXmonitor['valor1']) === String(rengloValidacionXmonitor['valor2']) && String(rengloValidacionXmonitor['valor1']) === String(rengloValidacionXmonitor['valor3']) && String(rengloValidacionXmonitor['valor1']) === String(rengloValidacionXmonitor['valor4'])));
          } else {
            if (rengloValidacionXmonitor['valor2']) {
              resolve(!(String(rengloValidacionXmonitor['valor1']) !== String(rengloValidacionXmonitor['valor2'])));
            } else {
              resolve(!(String(rengloValidacionXmonitor['valor1']) !== String(rengloValidacionXmonitor['valor3'])));
            }
          }
          
        } else {
          if (rengloValidacionXmonitor.valor1) {
            if (rengloValidacionXmonitor.valor2) {
              resolve(!(String(rengloValidacionXmonitor['valor1']) !== String(rengloValidacionXmonitor['valor2'])));
            }
            if (rengloValidacionXmonitor.valor3) {
              resolve(!(String(rengloValidacionXmonitor['valor1']) !== String(rengloValidacionXmonitor['valor3'])));
            }
            if (rengloValidacionXmonitor.valor4) {
              resolve((String(rengloValidacionXmonitor['valor4']) !== String(rengloValidacionXmonitor['valor1'])));
            }  
          }
          if (rengloValidacionXmonitor.valor2) {
            if (rengloValidacionXmonitor.valor3) {
              resolve(!(String(rengloValidacionXmonitor['valor2']) !== String(rengloValidacionXmonitor['valor3'])));
            }            
          }
          resolve(false);
        }
      } else {
        const rengloValidacionOtros = {         
          valor1:  renglon.currency === 'USD' ? Math.trunc(renglon.importeFacturaSAPCurrency) : Math.trunc(renglon.importeFacturaSAPPesos),
          valor2 : renglon.fechaPagoSAP ? renglon.currency === 'USD' ? Math.trunc(renglon.importePagadoSAPCurrency) : Math.trunc(renglon.importePagadoSAPPesos): null,          
          valor3:  renglon.currency === 'USD' ? Math.trunc(renglon.importePagadoSAPCurrency) : Math.trunc(renglon.importePagadoSAPPesos),
          valor4: renglon.importeFacturaReferi ? Math.trunc(renglon.importeFacturaReferi) : '0.0'
        }
        if(rengloValidacionOtros.valor1 && (rengloValidacionOtros.valor2 || rengloValidacionOtros.valor3)) {
          if(rengloValidacionOtros.valor2 && rengloValidacionOtros.valor3) {
            resolve(!(String(rengloValidacionOtros['valor1']) === String(rengloValidacionOtros['valor2']) && String(rengloValidacionOtros['valor1']) === String(rengloValidacionOtros['valor3']) && String(rengloValidacionOtros['valor1']) === String(rengloValidacionOtros['valor4'])));
          } else {
            if (rengloValidacionOtros['valor2']) {
              resolve(!(String(rengloValidacionOtros['valor1']) !== String(rengloValidacionOtros['valor2'])));
            } else {
              resolve(!(String(rengloValidacionOtros['valor1']) !== String(rengloValidacionOtros['valor3'])));
            }
          }
          
        } else {
          if (rengloValidacionOtros.valor1) {
            if (rengloValidacionOtros.valor2) {
              resolve(!(String(rengloValidacionOtros['valor1']) !== String(rengloValidacionOtros['valor2'])));
            }
            if (rengloValidacionOtros.valor3) {
              resolve(!(String(rengloValidacionOtros['valor1']) !== String(rengloValidacionOtros['valor3'])));
            }
          }
          if (rengloValidacionOtros.valor2) {
            if (rengloValidacionOtros.valor3) {
              resolve(!(String(rengloValidacionOtros['valor2']) !== String(rengloValidacionOtros['valor3'])));
            }            
          }          
          resolve(false);
        }         
      } 
    });
  }

  handlePageChangeRecibos(event) {
    this.page = event;    
  }

}
