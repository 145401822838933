﻿import {
	Component,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
} from '@angular/core';
import { ModalTemplate, SuiModalService } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormaModal, Notificacion } from '../../compartida/indice';
import {
	Archivo,
	Sociedad,
	OrdenCompra,
	Configuracion,
} from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { ArchivoPruebaEntrega } from '../../entidades/archivos-prueba-entrega';
import { OrdenCompraSeleccionadas } from '../../entidades/ordenes-compra-seleccionadas';
import { first } from 'rxjs/operators';

@Component({
	selector: 'forma-archivos',
	templateUrl: 'forma.component.html',
})
export class FormaArchivos extends FormaModal {
	@ViewChild('modalTemplateArchivo') modalTemplate: ModalTemplate<
		void,
		void,
		void
	>;
	@ViewChild('forma', { read: NgForm }) forma: NgForm;
	@ViewChild('txtArchivo', { read: ElementRef }) txtarchivo: ElementRef;
	@ViewChild('flXml', { read: ElementRef }) flXml: ElementRef;
	@ViewChild('flPdf', { read: ElementRef }) flPdf: ElementRef;
	@ViewChild('flPdfPruebasEntrega', { read: ElementRef })
	flPdfPruebasEntrega: ElementRef;
	@Output() procesoTerminado: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	archivo: Archivo;
	archivoPEPDF: ArchivoPruebaEntrega;
	sociedades: Sociedad[];
	ordenesCompras: OrdenCompra[];
	configuracion: Configuracion;
	ordenesComprasSeleccionadas: OrdenCompraSeleccionadas[] = [];
	ordenCompraSeleccionada: OrdenCompraSeleccionadas;
	minimo: number = 0;
	maximo: number = 0;
	geneOC: boolean = false;
	tipoComprobante: boolean;
	mostrarCheckOC: boolean;
	desactivarTodo: boolean = false;
	esMesActualXML: boolean = false;
	mostrarDatos1: boolean;
	validacionOC = '';
	numFac = '';
	coincidencia: string[];
	esExtranjero = false;
	aplicaPruebasEntregaSociedad = false;
	aplicaPruebasEntregaProveedor = false;
	regexRfc: RegExp = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;

	errorSociedad = false;
	errorMoneda = false;
	compraAbiertaXmonitor = false;
	errorImportes: boolean;
	errorIVA: boolean;
	errorRetencion: boolean;

	errorMismoProveedor = false;
	errorMetodoPago = false;
	errorRegimenFiscal = false;

	condicioPagoProveedor: any;

	get tieneNumeroFactura(): boolean {
		if (!this.esExtranjero) {
			return true;
		} else {
			if (this.numFac.length > 0) {
				return true;
			} else {
				return false;
			}
		}
	}

	get cumpleConOrdenesCompra(): boolean {
		return (
			(this.geneOC && this.ordenesComprasSeleccionadas.length > 0) ||
			!this.geneOC
		);
	}

	get correspondenOrdenesCompra(): boolean {
		if (this.ordenesComprasSeleccionadas.length > 0) {
			if (!this.validacionOC) {
				return false;
			}

			const ocEscritas = this.validacionOC.split(',');
			const ocSelecionadas = this.ordenesComprasSeleccionadas.map(
				(o) => o.numero
			);

			const faltantes1 = ocSelecionadas.filter(
				(numero) => ocEscritas.indexOf(numero) < 0
			);
			const faltantes2 = ocEscritas.filter(
				(numero) => ocSelecionadas.indexOf(numero) < 0
			);

			this.coincidencia = faltantes1.concat(faltantes2);
			return faltantes1.length == 0 && faltantes2.length == 0;
		} else {
			return true;
		}
	}

	// @ViewChild(TablaCatalogoArchivos) tablaCatArchivos: TablaCatalogoArchivos;
	// @ViewChild(CatalogoArchivos) CatArchivos: CatalogoArchivos;
	constructor(
		public modalService: SuiModalService,
		private snotifyService: SnotifyService,
		public servicios: Servicios,
		private notificacion: Notificacion
	) {
		super(modalService);
	}

	async ngOnInit() {
		this.errorMoneda = false;
		this.aplicaPruebasEntregaSociedad = false;
		this.aplicaPruebasEntregaProveedor = false;

	}

	obtenerSociedades() {

		let sociedades = this.servicios.sociedades.obtenerTodos().pipe(first())
			.subscribe(ss => {
				if (ss && ss.length > 0) {
					this.sociedades = ss;
				}
			});
	}

	async obtenerOrdenCompra() {
		//OBTENGO SI LA SOCIEDAD APLICA PRUEBAS DE ENTREGA
		let sociedadSel = this.servicios.sociedades.obtener(this.archivo.idSociedad).pipe(first())
			.subscribe(async ss => {
				if (ss && ss.aplicaPruebasEntrega) {
					let usuarioId = this.servicios.autenticacion.credencial.idUsuario;
					let proveedor: any = await this.servicios.proveedores
						.obtener(usuarioId)
						.toPromise();
					this.aplicaPruebasEntregaProveedor = proveedor.aplicaPruebasEntrega;
				} else {
					this.aplicaPruebasEntregaProveedor = false;
				}
			});

		let proveedorId = this.servicios.autenticacion.credencial.idUsuario;
		let ordenesCompra = await this.servicios.ordenCompra
			.OrdenesCompraProveedorSociedad(proveedorId, this.archivo.idSociedad)
			.toPromise();

		this.ordenesCompras = ordenesCompra;
		if (!this.geneOC) {
			this.desactivarTodo = false;
		} else {
			this.desactivarTodo = false;
		}
	}

	obtenerTolerancia() {
		this.servicios.configuracion.obtenerTodos().subscribe(async (conf) => {
			this.configuracion = conf.find((c) => c.id == 1);

			let usuarioId = this.servicios.autenticacion.credencial.idUsuario;
			let proveedor: any = await this.servicios.proveedores
				.obtener(usuarioId)
				.toPromise();
			this.condicioPagoProveedor = proveedor.condicionPago;

			if (this.configuracion.tipoOrdenCompraId === 1) {

				if (
					proveedor &&
					proveedor.aplicaOrdenCompra === 'Si' &&
					proveedor.aplicaConOSinOrdenCompra === 'No'
				) {
					this.geneOC = true;
					this.mostrarDatos1 = true;
					this.mostrarCheckOC = false;
					this.archivo.aplicaConOSinOrdenCompra = true;
				} else if (
					proveedor &&
					proveedor.aplicaOrdenCompra === 'No' &&
					proveedor.aplicaConOSinOrdenCompra === 'Si'
				) {
					this.geneOC = true;
					this.mostrarDatos1 = true;
					this.mostrarCheckOC = true;
					this.archivo.aplicaConOSinOrdenCompra = true;
				} else if (
					proveedor &&
					proveedor.aplicaOrdenCompra === 'Si' &&
					proveedor.aplicaConOSinOrdenCompra === 'Si'
				) {
					this.geneOC = true;
					this.mostrarDatos1 = true;
					this.mostrarCheckOC = true;
					this.archivo.aplicaConOSinOrdenCompra = true;
				}
			} else {
				if (this.configuracion.pedirOrdenCompra == false) {
					this.mostrarDatos1 = false;
				} else {
					this.geneOC = true;
					this.mostrarDatos1 = true;
				}
			}
			if (
				this.configuracion != null &&
				this.configuracion.importeMaximo != 0 &&
				this.configuracion.importeMinimo != 0
			) {
				this.minimo = this.configuracion.importeMinimo;
				this.maximo = this.configuracion.importeMaximo;
			} else {
				this.snotifyService.warning(
					'No se encuentran los montos maximos y minimos de la tolerancia!',
					{
						closeOnClick: true,
						pauseOnHover: true,
						position: SnotifyPosition.centerTop,
					}
				);
				this.errorImportes = false;
			}

			this.esExtranjero = !this.regexRfc.test(proveedor.rfc);

		});
	}

	precargaOrdenCompra(ordenesCompras: number[]): void {
		debugger
		if (this.ordenesComprasSeleccionadas.length > 0) {
			this.ordenesComprasSeleccionadas.splice(
				0,
				this.ordenesComprasSeleccionadas.length
			);
		}

		ordenesCompras.forEach((ordenCompra) => {
			let oc = this.ordenesCompras.find((s) => s.idOrdenCompra == ordenCompra);

			if (oc) {
				if (oc.abiertaXmonitor == true) {
					this.compraAbiertaXmonitor = true;
				} else {
					this.ordenCompraSeleccionada = new OrdenCompraSeleccionadas();
					this.ordenCompraSeleccionada.numero = oc.numero;
					this.ordenCompraSeleccionada.idOrdenCompra = ordenCompra;
					(this.ordenCompraSeleccionada.iva = oc.iva),
						(this.ordenCompraSeleccionada.retencion = oc.retencion),
						(this.ordenCompraSeleccionada.montoCubierto = oc.montoCubierto),
						(this.ordenCompraSeleccionada.subTotal = oc.subTotal),
						(this.ordenCompraSeleccionada.total = oc.total - oc.montoCubierto);
					this.ordenCompraSeleccionada.moneda = oc.moneda;
					this.ordenesComprasSeleccionadas.push(this.ordenCompraSeleccionada);
					this.compraAbiertaXmonitor = false;
				}
			}
		});

		console.dir(this.ordenesComprasSeleccionadas);
		let total = this.ordenesComprasSeleccionadas.reduce(
			(sum, current) => sum + current.total,
			0
		);
		let iva = this.ordenesComprasSeleccionadas.reduce(
			(sum, current) => sum + current.iva,
			0
		);
		let retencion = this.ordenesComprasSeleccionadas.reduce(
			(sum, current) => sum + current.retencion,
			0
		);

		this.archivo.totalOrdenCompra = total;
		this.archivo.ivaOrdenCompra = iva;
		this.archivo.retencionOrdenCompra = retencion;
		this.archivo.monedaOrdenDeCompra = this.ordenCompraSeleccionada.moneda;
		this.errorIVA = this.validarIVA();
		this.errorRetencion = this.validarRetencion();
		this.errorImportes = this.validarMontos();
		// let oc = this.ordenesCompras.find(s => s.idOrdenCompra == id);
		// this.archivo.totalOrdenCompra = (oc.total - oc.montoCubierto);
		// this.archivo.ivaOrdenCompra = oc.iva;
		// this.archivo.idOrdenCompra = oc.idOrdenCompra;
		// this.archivo.montoCubierto = oc.montoCubierto;
		// this.archivo.retencionOrdenCompra = oc.retencion;
		// this.XML();
	}

	async XML() {
		let datos = new FormData();
		try {
			let xml = (<HTMLInputElement>this.flXml.nativeElement).files[0];
			datos.append('xml', xml);
			let archivoresult = await this.servicios.archivos
				.leerXML(datos, this.archivo.idSociedad)
				.toPromise();
			if (archivoresult != null) {
				this.archivo.subtotal = archivoresult.subtotal;
				this.archivo.iva = archivoresult.iva;
				this.archivo.total = archivoresult.total;
				this.archivo.retencion = archivoresult.retencion;
				this.archivo.moneda = archivoresult.moneda;
				this.archivo.folio = archivoresult.folio;
				this.archivo.formaPago = archivoresult.formaPago;
				this.archivo.codigoPostalValido = archivoresult.codigoPostalValido;
				this.archivo.razonSocialValida = archivoresult.razonSocialValida;
				this.archivo.mismoProveedor = archivoresult.mismoProveedor;
				this.archivo.mismoRegimenFiscal = archivoresult.mismoRegimenFiscal;

				if (archivoresult.tipoComprobante === 'P') {
					this.tipoComprobante = true;
					return;
				} else {
					this.tipoComprobante = false;
				}

				if (archivoresult.mesTimbrado && archivoresult.mesTimbrado == 0) {
					this.esMesActualXML = true;
					return;
				}

				if (!archivoresult.esMesActualXML) {
					this.esMesActualXML = true;
					return;
				}

				if (this.geneOC === true) {
					this.errorIVA = this.validarIVA();
					this.errorRetencion = this.validarRetencion();
					this.errorImportes = this.validarMontos();
				} else {
					this.errorImportes = false;
					this.errorIVA = false;
					this.errorRetencion = false;
					return;
				}

				if (this.archivo.monedaOrdenDeCompra != this.archivo.moneda) {
					this.errorMoneda = true;
				} else {
					this.errorMoneda = false;
				}

				if (this.archivo.mismoProveedor == false) {
					this.errorMismoProveedor = true
				} else {
					this.errorMismoProveedor = false;
				}

				if (this.archivo.mismoRegimenFiscal == false) {
					this.errorRegimenFiscal = true
				} else {
					this.errorRegimenFiscal = false;
				}

				this.validarMetodoPago(archivoresult);

				const sociedadSeleccionada = this.sociedades.find(s => s.id === this.archivo.idSociedad)
				this.errorSociedad = sociedadSeleccionada.rfc != archivoresult.sociedadRFC;

			} else {
				this.snotifyService.error(
					'Error al cargar el archivo XML, verifique nuevamente',
					{
						closeOnClick: true,
						pauseOnHover: true,
						position: SnotifyPosition.centerTop,
					}
				);
				datos = null;
			}
		} catch (error) { }
	}


	public validarIVA(): boolean {
		if (!this.esExtranjero) {
			if (this.archivo.ivaOrdenCompra == this.archivo.iva) {
				this.errorIVA = false;
			} else {
				this.errorIVA = true;
				this.validoToleraCIA();
			}
		} else {
			this.errorIVA = false;
		}
		return this.errorIVA;
	}

	public validarRetencion(): boolean {
		if (!this.esExtranjero) {
			if (this.archivo.retencionOrdenCompra == this.archivo.retencion) {
				this.errorRetencion = false;
			} else {
				this.errorRetencion = true;
				this.validoToleraCIA();
			}
		} else {
			this.errorRetencion = false;
		}
		return this.errorRetencion;
	}

	public validarMontos(): boolean {
		if (!this.ordenCompraSeleccionada) {
			return false;
		}

		let montoConMaximo = this.archivo.totalOrdenCompra + this.maximo;
		let montoConMinimo = this.archivo.totalOrdenCompra + this.minimo;

		// || this.archivo.total <= montoConMaximo
		// ||  this.archivo.total <= montoConMinimo ---ESTAS LAS AGREGAMOS AL PRIMER IF
		if (
			this.archivo.total == montoConMaximo ||
			this.archivo.total == montoConMinimo ||
			this.archivo.total.toFixed(2) == this.archivo.totalOrdenCompra.toFixed(2)
		) {
			this.errorImportes = false;
		} else if (
			this.archivo.total > montoConMinimo ||
			this.archivo.total > montoConMaximo
		) {
			this.errorImportes = true;
			this.validoToleraCIA();
		} else if (
			this.archivo.totalOrdenCompra > this.archivo.total ||
			this.archivo.totalOrdenCompra > this.archivo.total
		) {
			this.errorImportes = true;
			this.validoToleraCIA();
		} else {
			this.errorImportes = false;
		}
		return this.errorImportes;

	}

	public validoToleraCIA() {
		let montoConMaximo = parseFloat(this.archivo.totalOrdenCompra.toFixed(2)) + parseFloat(this.maximo.toFixed(2));
		let montoConMinimo = parseFloat(this.archivo.totalOrdenCompra.toFixed(2)) - parseFloat(this.minimo.toFixed(2));

		// || this.archivo.total <= montoConMaximo
		// ||  this.archivo.total <= montoConMinimo ---ESTAS LAS AGREGAMOS AL PRIMER IF
		if (
			this.archivo.total <= montoConMaximo &&
			this.archivo.total >= montoConMinimo
		) {
			this.errorImportes = false;
			this.errorIVA = false;
			this.errorRetencion = false;
		}
	}


	public validarMetodoPago(archivoXml: any): void {
		this.errorMetodoPago = false;
		if (String(archivoXml.metodoPago) === 'PUE') {
			if (this.condicioPagoProveedor !== '-1') {
				this.errorMetodoPago = true;
			}
		}
	}

	async abrirNuevo() {
		this.abrir('Nuevo', true);
		let nuevoArchivo: Archivo = new Archivo();
		this.archivo = nuevoArchivo;
		this.archivo.aplicaConOSinOrdenCompra = true;
		this.geneOC = false;
		this.mostrarDatos1 = false;
		this.errorImportes = false;
		this.mostrarCheckOC = false;
		this.tipoComprobante = false;
		await this.obtenerSociedades();
		this.obtenerTolerancia();
	}

	cancelar(): void {
		this.archivo = new Archivo();
		this.errorImportes = false;
		this.tipoComprobante = false;
		this.esMesActualXML = false;
		this.errorMoneda = false;
		this.cerrar();
	}

	enfocar(): void { }

	limpiar(): void {
		this.flPdf.nativeElement.value = '';
		this.flXml.nativeElement.value = '';
		this.flPdfPruebasEntrega.nativeElement.value = '';

		this.archivo = new Archivo();
		this.archivo.iva = 0;
		this.archivo.ivaOrdenCompra = 0;
		this.archivo.retencion = 0;
		this.archivo.retencionOrdenCompra = 0;
		this.archivo.total = 0;
		this.archivo.totalOrdenCompra = 0;
		this.archivo.subTotalOrdenCompra = 0;
		this.archivo.subtotal = 0;
		this.ordenesCompras = [];
		this.obtenerTolerancia();
	}

	guardar(): void {
		this.Procesando = true;

		if (this.archivo.ordenesComprasSeleccionadas.length == 0) {
			this.archivo.idOrdenCompra = 0;
		}

		let sociedad = this.sociedades.find((s) => s.id == this.archivo.idSociedad);
		this.archivo.sociedadRFC = sociedad.rfc;
		this.archivo.idSociedad = sociedad.id;
		this.archivo.ordenesComprasSeleccionadas = this.ordenesComprasSeleccionadas;

		let datos = new FormData();

		if (!this.esExtranjero) {
			let xml = (<HTMLInputElement>this.flXml.nativeElement).files[0];
			let nombre = xml.name.substring(0, xml.name.indexOf('.'));
			this.archivo.nombreArchivo = nombre;
			datos.append('xml', xml);
		}

		let pdf = (<HTMLInputElement>this.flPdf.nativeElement).files[0];
		datos.append('pdf', pdf);

		let pdfPruebasEntrega = (<HTMLInputElement>(
			this.flPdfPruebasEntrega.nativeElement
		)).files;
		for (let index = 0; index < pdfPruebasEntrega.length; index++) {
			const element = pdfPruebasEntrega[index];
			datos.append('pdfPruebasEntrega', element);
		}

		this.archivo.numFac = this.numFac;
		datos.append('archivoJson', JSON.stringify(this.archivo));
		datos.append('numFac', this.numFac);
		console.dir(datos);
		let mensaje: string;
		mensaje = 'CFDI guardado.';
		let observable = this.servicios.archivos.guardarCompleto(datos);

		observable.subscribe(
			() => {
				this.notificacion.mostrarExito(mensaje);
				this.Procesando = false;
				this.limpiar();
				this.procesoTerminado.emit(true);
			},
			(error) => {
				let httpError = <HttpErrorResponse>error;
				this.notificacion.mostrarError(httpError.error);
				this.Procesando = false;
			}
		);
	}

	aplicaOC(valor: boolean): void {
		debugger
		console.log(valor);
		if (valor === false) {
			this.geneOC = false;
			this.mostrarDatos1 = false;
			this.archivo = new Archivo();
			this.flPdf.nativeElement.value = '';
			this.flXml.nativeElement.value = '';
			this.flPdfPruebasEntrega.nativeElement.value = '';
		} else {
			this.geneOC = true;
			this.mostrarDatos1 = true;
			this.archivo = new Archivo();
			this.flPdf.nativeElement.value = '';
			this.flXml.nativeElement.value = '';
			this.flPdfPruebasEntrega.nativeElement.value = '';
		}
	}
}
