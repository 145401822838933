import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalTemplate, SuiModalService } from 'ng2-semantic-ui';
import { FormaModal } from '../../compartida/formaModal';

@Component({
  selector: 'app-aviso',
  templateUrl: './aviso.component.html',
  styleUrls: ['./aviso.component.css']
})
export class AvisoComponent extends FormaModal implements OnInit {
  @ViewChild('modalTemplateAviso') modalTemplate: ModalTemplate<void, void, void>;

  constructor(public modalService: SuiModalService) { 
    super(modalService)
  }

  ngOnInit() {
    this.abrir('COMUNICADO',false, true);
  }

}
