import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { Configuracion } from "../../entidades/indice";
import { HttpErrorResponse } from "@angular/common/http";
import { Servicios } from "../../servicios/indice";
import { Notificacion } from "../../compartida/indice";

@Component({
  selector: "menu-lateral",
  templateUrl: "./menu-lateral.component.html",
  styleUrls: ["./menu-lateral.component.css"],
})
export class MenuLateralComponent implements OnInit {
  @Input() tipoMenu: 1 | 2;
  @Output() salio = new EventEmitter<void>();
  @Output() clic = new EventEmitter<void>();
  configuracion: Configuracion;
  configuraciones: Configuracion[];
  idConfiguracion: number;
  constructor(
    private servicios: Servicios,
    private notificacion: Notificacion
  ) {}

  habilitarOrdenCompra: boolean;
  alerta(s: any) {
    alert(s);
  }

  ngOnInit() {
    this.habilitarOC();
  }

  habilitarOC(): void {
    this.idConfiguracion = this.servicios.autenticacion.credencial.idCorporativo;

    if (this.idConfiguracion > 0) {
      let observable = this.servicios.configuracion.obtener(
        this.idConfiguracion
      );
      observable.subscribe((confs) => {
        this.habilitarOrdenCompra = this.habilitarOrdenCompra;
      });
    }
  }
}
