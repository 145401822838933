﻿import { Servicio } from "./servicio";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { EnviarToken } from "../entidades/enviartoken";

export class ServicioEnviarToken extends Servicio<EnviarToken> {
    constructor(http: HttpClient) {
        super(http, "enviarToken");
    }

    enviarToken(usuario: string): Observable<string> {
        let ruta = this.Ruta + "/" + usuario + "/EnviarToken";
        return this.ClienteHttp.get(ruta)
            .pipe(map((proveedor: string) => proveedor));

    }

}