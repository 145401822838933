import { EnvioComunicadoComponent } from './paginas/envio-comunicado/envio-comunicado.component';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SuiModule } from 'ng2-semantic-ui';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule  } from '@angular/material';
import { MatIconModule} from '@angular/material/icon'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { DataTablesModule } from 'angular-datatables';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { RECAPTCHA_LANGUAGE } from 'ng-recaptcha';


import { AppComponent } from './app.component';
import { Confirmacion, Notificacion } from './compartida/indice';
// import * as p from './paginas/indice';
import * as s from './servicios/indice';
import { AccesoComponent } from './paginas/acceso/acceso.component';
import { ServicioAutenticacion } from './servicios/indice';
import { MenuConfiguracionComponent } from './compartida/menu/menu-configuracion.component';
import { MenuLateralComponent } from './compartida/menu/menu-lateral.component';
import { SuiAccordionPanelMod } from './compartida/accordion/components/accordion-panel';
import { SuiAccordionMod } from './compartida/accordion/components/accordion';
import { ConfiguracioncorreoComponent } from './paginas/configuracioncorreo/configuracioncorreo.component';
import { TrakingCFDIComponent } from './paginas/traking-cfdi/traking-cfdi.component';
import { CatalogoArchivos } from './paginas/validacion/catalogo.component';
import { ReppagosComponent } from './paginas/reppagos/reppagos.component';
import { ProveedorComponent } from './paginas/proveedor/proveedor.component';
import { RestablecercontrasenaComponent } from './paginas/restablecercontrasena/restablecercontrasena.component';
import { TablaCatalogoUsuario } from './paginas/usuario/tabla.component';
import { TablaCatalogoSociedad } from './paginas/sociedad/tabla.component';
import { TablaCatalogoCorreo } from './paginas/correo/tabla.component';
import { TablaCatalogoArchivos } from './paginas/validacion/tabla.component';
import { ArchivoUnicoComponent } from './paginas/validacion/archivo-unico.component';
import { TablaCatalogoPagos } from './paginas/pago/tabla.component';
import { Inicio } from './paginas/inicio/inicio.component';
import { CatalogoCorporativo } from './paginas/corporativo/catalogo.component';
import { CatalogoReenvioFacturas } from './paginas/reenvioFacturas/catalogo.component';
import { CatalogoUsuario } from './paginas/usuario/catalogo.component';
import { CatalogoSociedad } from './paginas/sociedad/catalogo.component';
import { ReparchivosComponent } from './paginas/reparchivos/reparchivos.component';
import { CatalogoProveedor } from './paginas/proveedor/catalogo.component';
import { EnviartokenComponent } from './paginas/enviartoken/enviartoken.component';
import { OrdencompraComponent } from './paginas/ordencompra/ordencompra.component';
import { ConfiguracionComponent } from './paginas/configuracion/configuracion.component';
import { CatalogoPagos } from './paginas/pago/catalogo.component';
import { FormaUsuario } from './paginas/usuario/forma.component';
import { FormaSociedad } from './paginas/sociedad/forma.component';
import { CatalogoCorreo } from './paginas/correo/catalogo.component';
import { FormaCorreo } from './paginas/correo/forma.component';
import { FormaProveedor } from './paginas/proveedor/forma.component';
import { TablaCatalogoProveedor } from './paginas/proveedor/tabla.component';
import { FormaArchivos } from './paginas/validacion/forma.component';
import { ValidacionComponent } from './paginas/validacion/validacion.component';
import { FormaPagos } from './paginas/pago/forma.component';
import { FormaOrdenCompra } from './paginas/ordencompra/forma.component';
import { ReparchivoSinComplementoComponent } from './paginas/reparchivos-sin-complemento/reparchivos.component';
import { ErrorEnvioSapComponent } from './paginas/fallos-envio-sap/fallo-envio-sap.component';
import { ProveedoresSapComponent } from './compartida/proveedores-sap/proveedores-sap.component';
import { ArticulosSapComponent } from './compartida/articulos-sap/articulos-sap.component';
import { DatePipe } from '@angular/common';
import { ScrollingModule } from '@angular/cdk-experimental/scrolling';
import { AvisoComponent } from './paginas/aviso/aviso.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SeleccionProveedoresComponent } from './paginas/seleccion-proveedores/seleccion-proveedores.component';
import { ReporteFlujoComprasComponent } from './paginas/reporte-flujo-compras/reporte-flujo-compras.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReporteFacturasComponent } from './paginas/reporte-facturas/reporte-facturas.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AvisosComponent } from './paginas/avisos/avisos/avisos.component';
import { AvisoModalComponent } from './paginas/avisos/aviso-modal/aviso-modal.component';
import { TablaAvisosComponent } from './paginas/avisos/tabla-avisos/tabla-avisos.component';
import { AvisoMostrarComponent } from './paginas/avisos/aviso-mostrar/aviso-mostrar.component';
import { CatalogoReenvioCorreoPruebasEntrega } from './paginas/reenvioCorreoPruebasEntrega/catalogo.component';

let routes = [
	{ path: '', component: AccesoComponent },
	{ path: 'Inicio', component: Inicio },
	{ path: 'Corporativo', component: CatalogoCorporativo },
	{ path: 'ReenvioFacturas', component: CatalogoReenvioFacturas },
	{ path: 'ReenvioApe', component: CatalogoReenvioCorreoPruebasEntrega },
	{ path: 'Usuarios', component: CatalogoUsuario },
	{ path: 'Sociedades', component: CatalogoSociedad },
	{ path: 'Correos', component: ConfiguracioncorreoComponent },
	{ path: 'Validacion', component: CatalogoArchivos },
	{ path: 'Reporte', component: ReparchivosComponent },
	{ path: 'ReportePagos', component: ReppagosComponent },
	{ path: 'ReporteFlujoCompras', component: ReporteFlujoComprasComponent },
	{ path: 'ReporteFacturas', component: ReporteFacturasComponent },
	{ path: 'Proveedores', component: ProveedorComponent },
	{ path: 'CuentaProveedor', component: CatalogoProveedor },
	{ path: 'EnviarToken', component: EnviartokenComponent },
	{
		path: 'Restablecercontrasena',
		component: RestablecercontrasenaComponent,
		pathMatch: 'full',
	},
	{ path: 'OrdenCompra', component: OrdencompraComponent },
	{ path: 'Configuracion', component: ConfiguracionComponent },
	{ path: 'Comunicados', component: EnvioComunicadoComponent },
	{ path: 'Pagos', component: CatalogoPagos },
	{ path: 'Tracking', component: TrakingCFDIComponent },
	{
		path: 'ReporteArchivoSinComplemento',
		component: ReparchivoSinComplementoComponent,
	},
	{ path: 'ErroresEnviosSap', component: ErrorEnvioSapComponent },
	{ path: 'Avisos', component: AvisosComponent }
	
];

@NgModule({
	declarations: [
		AppComponent,
		Confirmacion,
		Inicio,
		CatalogoCorporativo,
		CatalogoReenvioFacturas,
		CatalogoUsuario,
		FormaUsuario,
		TablaCatalogoUsuario,
		CatalogoSociedad,
		FormaSociedad,
		TablaCatalogoSociedad,
		CatalogoCorreo,
		FormaCorreo,
		TablaCatalogoCorreo,
		CatalogoProveedor,
		CatalogoReenvioCorreoPruebasEntrega,
		FormaProveedor,
		TablaCatalogoProveedor,
		ProveedorComponent,
		CatalogoArchivos,
		FormaArchivos,
		TablaCatalogoArchivos,
		ArchivoUnicoComponent,
		ValidacionComponent,
		CatalogoPagos,
		FormaPagos,
		TablaCatalogoPagos,
		AccesoComponent,
		MenuConfiguracionComponent,
		MenuLateralComponent,
		ReparchivosComponent,
		SuiAccordionPanelMod,
		SuiAccordionMod,
		EnviartokenComponent,
		RestablecercontrasenaComponent,
		OrdencompraComponent,
		FormaOrdenCompra,
		ConfiguracionComponent,
		ConfiguracioncorreoComponent,
		ReppagosComponent,
		TrakingCFDIComponent,
		ReparchivoSinComplementoComponent,
		ErrorEnvioSapComponent,
		ProveedoresSapComponent,
		ArticulosSapComponent,
		AvisoComponent,
		EnvioComunicadoComponent,
		SeleccionProveedoresComponent,
		ReporteFlujoComprasComponent,
		ReporteFacturasComponent,
		ReporteFacturasComponent,
		AvisosComponent,
		AvisoModalComponent,
		TablaAvisosComponent,
		AvisoMostrarComponent
	],
	imports: [
		BrowserModule,
		FormsModule,
		HttpModule,
		HttpClientModule,
		RouterModule.forRoot(routes, {
			useHash: true,
			enableTracing: false,
		}),
		MatDialogModule,
		MatButtonModule,
		MatIconModule,
		BrowserAnimationsModule,
		SuiModule,
		DataTablesModule,
		SnotifyModule,
		RecaptchaModule.forRoot(),
		RecaptchaFormsModule,
		ScrollingModule,
		AngularEditorModule,
		NgxPaginationModule,
		NgxSpinnerModule,
		
	],
	exports: [
		NgxSpinnerModule,
	],
	providers: [
		s.Servicios,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: s.Interceptor,
			multi: true,
		},
		{
			provide: RECAPTCHA_LANGUAGE,
			useValue: 'es',
		},
		{ provide: 'SnotifyToastConfig', useValue: ToastDefaults },
		SnotifyService,
		ServicioAutenticacion,
		Notificacion,
		DatePipe,
	],
	entryComponents: [ProveedoresSapComponent, AvisoComponent, AvisoMostrarComponent],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
