import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA  } from '@angular/material/dialog';
import { Servicios } from 'ClientApp/app/servicios/indice';




@Component({
  selector: 'app-aviso-mostrar',
  templateUrl: './aviso-mostrar.component.html',
  styleUrls: ['./aviso-mostrar.component.css']
})
export class AvisoMostrarComponent  implements OnInit{
  
  constructor( 
    public servicios: Servicios,
    public dialogRef: MatDialogRef<AvisoMostrarComponent>,
    @Inject(MAT_DIALOG_DATA) public aviso: any
  ){            
  }

  ngOnInit() {    
    
  }

  descargarArchivo(archivo: any) {
    this.servicios.avisos.descargarArchivo(archivo.id)
    .subscribe(
      archivoDescargado => {
          let a = document.createElement("a");
          let blob = new Blob([archivoDescargado], { type: "octet/stream" });
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = archivo.nombre;
          a.click();
          window.URL.revokeObjectURL(url);
      }
  );
    
  }

  close() {   
    this.dialogRef.close();
  }

  esPDF(nombreArchivo: string) {    
    const extension = nombreArchivo.split('.').pop();
    return extension === 'pdf' || extension === 'PDF'
  }
}
