﻿import { HttpClient, HttpInterceptor, HttpRequest, HttpEvent, HttpHandler } from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class Servicio<T> {

    private readonly ruta;
    get Ruta() { return this.ruta };
    get ClienteHttp() { return this.http };

    constructor(private http: HttpClient, controlador: string) {
        this.ruta = 'api/' + controlador;
    }

    obtener(id: number): Observable<T> {
        return this.http.get(`${this.ruta}/${id}`)
            .pipe(map((data: T) => {
                return data;
            }));
    }

    obtenerTodos(): Observable<T[]> {        
        return this.http.get(this.ruta)
            .pipe(map((data: T[]) => {
                return data;
            }));
    }

    guardar(entidad: T): Observable<T> {
        return this.http.post(this.ruta, entidad)
            .pipe(map((data: T) => {
                return data;
            }));
    }

    actualizar(id: string, entidad: T): Observable<T> {
        let ruta = this.ruta + '/' + id;
        return this.http.put(ruta, entidad)
            .pipe(map((data: T) => {
                return data;
            }));
    }

    eliminar(id: string): Observable<boolean> {
        let ruta = this.ruta + '/' + id;
        return this.http.delete(ruta)
            .pipe(map((data: T) => {
                return true;
            }));
    }

}