﻿import { Injectable } from '@angular/core';
import { HttpRequest, HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, window } from 'rxjs/operators';
import { Usuario } from '../entidades/indice';
import { Router } from '@angular/router';
import { Credencial } from '../entidades/credencial';
import { RestablecerContrasena } from '../entidades/restablecercontrasena';

@Injectable()
export class ServicioAutenticacion {
    private ruta: string;
    private persistente: boolean;
    tokenActualizado: Subject<boolean> = new Subject();

    public get estaAutenticado() {
        let credencial = this.obtenerCredencial();
        if (!credencial)
            return false;
        return (credencial.token != "" && new Date(credencial.expira) > new Date())
    }
    public get credencial() {
       return this.obtenerCredencial();
       
    }
    
    constructor(private http: HttpClient, private router: Router) {
        this.ruta = 'api/Acceso';
        this.persistente = false;
    }

    limpiarCredencial(): void {
        localStorage.clear();
    }

    almacenarCredencial(credencial: Credencial): void {
        localStorage.setItem('credencial', JSON.stringify(credencial));
    }

    private obtenerCredencial(): Credencial {
        let json = localStorage.getItem('credencial');
        if (!json)
            return { expira: "", token: "", tokenActualizable: "", idTipoUsuario: 0, idUsuario:0, idCorporativo: 0, nombreUsuario: "" };
        return JSON.parse(json);
    }

    public obtenerToken(): string {
        return this.obtenerCredencial().token;
    }

    public obtenerTokenActualizable(): string {
        return this.obtenerCredencial().tokenActualizable;
    }

    public acceder(usuario: Usuario): Observable<Credencial> {
        return this.http.post(this.ruta, usuario)
            .pipe(map((data: Credencial) => {
                this.almacenarCredencial(data);
                return data;
            }));
    }

    public salir() {
        this.limpiarCredencial();
    }

    public refrescarToken(): Observable<Credencial> {
        let ruta = `${this.ruta}/RefrescarToken/${this.obtenerTokenActualizable()}`;
        return this.http.get(ruta)
            .pipe(map((data: Credencial) => {                
                this.almacenarCredencial(data);
                return data;
            }));
    }

    enviarToken(usuario: string): Observable<string> {
        let ruta = `${this.ruta}/EnviarToken/${usuario}`;
        return this.http.get(ruta)
            .pipe(map((t: string) => t));

    }


    public restablecer() {
        this.limpiarCredencial();
        this.router.navigateByUrl('/restablecercontrasena');
    }


    actualizarToken(usuario: string, contrasena: string, token: string): Observable<string> {
        let ruta = `${this.ruta}/RestablecerContrasena/${token}`;
        return this.http.post(ruta, {NombreUsuario:usuario, contrasena:contrasena, confirmacionContrasena: contrasena, correo:'a@a'})
            .pipe(map((res: string) => res));
    }
}