import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import {  map } from 'rxjs/operators';
import { Subject, Observable, of } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Servicios, ServicioArchivos } from '../../servicios/indice';
import { forEach } from '@angular/router/src/utils/collection';
import { Pago, Proveedor, Sociedad } from '../../entidades/indice';
import { Notificacion } from '../../compartida/indice';
import { DatepickerMode } from 'ng2-semantic-ui';

@Component({
    selector: 'app-reppagos',
    templateUrl: './reppagos.component.html',
    styleUrls: ['./reppagos.component.css']
})
export class ReppagosComponent implements OnInit {

    idSeleccionado: number;
    Procesando: boolean;
    @Output() xmlOprimido: EventEmitter<Pago> = new EventEmitter<Pago>();
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    seleccionado:string;
    titulo: string = "Reporte complementos de pago";
    private oyente: () => void;

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Pago> = new Subject();
    modoFecha = DatepickerMode.Date;

    pagos: Pago[];
    pago: Pago;  
    proveedores: Proveedor[];
    sociedades: Sociedad[];
    tipoBusqueda = 0;
 
    val: number;
    cargaInicial = false;
    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit() {
        this.pago = new Pago;
        
        this.cargarTabla();   
        this.Procesando = true;
        this.cargarProveedores();
        this.cargarSociedades();     
    }

    cargarProveedores() {
        this.servicios
            .proveedores
            .ObtenerProveedores()
            .subscribe(
                lista => {
                    this.proveedores = lista;
                    console.log('proveedores-->', this.proveedores);
                    /*
                        codigo
                        : 
                        "P0016"
                        correo
                        : 
                        "ccadena@proesmma.com"
                        descripcion
                        : 
                        "PRO091204KP3-PROESMMA S.A DE C.V"
                        idProveedor
                        : 
                        220
                        nombreUsuario
                        : 
                        "P0016"
                        razonSocial
                        : 
                        "PROESMMA S.A DE C.V"
                        rfc
                        : 
                        "PRO091204KP3"
                    */
                }
            );
    }

    cargarSociedades() {
        this.servicios
            .sociedades
            .obtenerTodos()
            .subscribe(
                lista => {
                    this.sociedades = lista;
                    console.log('sociedades-->', this.sociedades);
                }
            );
    }


    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.renderer.destroy();
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let nombre = objetivo.getAttribute('data-nombre');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == "xml") {
                    this.descargarXml(id, nombre);
                }
                if (tipo = "pdf") {

                    this.descargarPdf(id, nombre);
                }
            }
        });
    }


    limpiar(): void {
        this.cargaInicial = false;

        /*
        this.pago.nombrePago = null;
        this.pago.sociedadNombreComercial = null;
        this.pago.proveedorRazonSocial = null;
        this.pago.fechaDesde = null;
        this.pago.fechaHasta = null;
        */
        this.pago = new Pago();

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
        });
    }



    buscar(): void {
        this.Procesando = true;        
        this.recargarTabla();        
    }

    

    cargarTabla(): void {
        this.Procesando = true;
        var n = 0;
        this.dtOptions = {
            language: { url: '/js/espanol.json' },
            searching: false,
            ajax: (dataTablesParameters: any, callback) => {
                this.obtenerReporte()
                .subscribe(
                    lista => {
                        this.pagos = lista;
                        this.Procesando = false;
                        callback({ data: lista });
                    }
                );
               
            },
            columns: [
                { title: '', data: 'idPago', visible: false },
                { title: '', data: 'idProveedor', visible: false },
                { title: 'Sociedad', data: 'sociedadNombreComercial' },
                { title: 'Proveedor', data: 'proveedorRazonSocial' },
                { title: 'UUID del complemento', data: 'nombrePago' },
                { title: 'Fecha de registro ', data: 'fechaRegistro', render: (data: string) => (new Date(data)).toLocaleDateString() },
                { title: 'Fecha de pago ', data: 'fechaTimbro', render: (data: string) => (new Date(data)).toLocaleDateString() },
                {
                    title: 'Opciones',
                    data: 'idPago',
                    width: "15%",
                    render: (data: any, type: any, full: Pago) => {
                        return `<div class="ui icon buttons">
                                    <button class="ui orange basic button" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombrePago + `" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
                                        <i class="large file excel outline icon" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombrePago + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombrePago + `" data-inverted="" data-tooltip="Descargar PDF Factura" data-position="top center">
                                       <i class="large file pdf outline icon" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombrePago + `"></i>
                                    </button>
                                </div>`;
                    }
                }
            ]
        };
    }

    obtenerReporte(): Observable<any[]> {                
        if (!this.cargaInicial) {
            return of([]) ; 
        } else {
            return this.servicios.pagos.obtenerReporte(this.pago).pipe(
                map((lista: any) => {
                  return lista;
                })
            );
        }
    }

    reporteExcel(): void {
        let observable = this.servicios
            .pagos
            .obtenerReporteExcel(this.pago)
            .subscribe(
                xls => {
                    let a = document.createElement("a");
                    let blob = new Blob([xls], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "Reporte_de_pagos" + ".xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }


    descargarPdf(id: number, nombre: string): void {
        let observable = this.servicios
            .pagos
            .descargarPdf(id)
            .subscribe(
                pdf => {
                    let a = document.createElement("a");
                    let blob = new Blob([pdf], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 100);
                    
                }
            );

    }



    descargarXml(id: number, nombre: string): void {
        let observable = this.servicios
            .pagos
            .descargarXml(id)
            .subscribe(
                xml => {
                    let a = document.createElement("a");
                    let blob = new Blob([xml], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".xml";
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 100);
                }
            );
    }


    public recargarTabla(): void {
        this.cargaInicial = true;
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
        });
    }



    ngOnDestroy(): void {
        this.oyente();
    }
}
