﻿import {
  ModalTemplate,
  TemplateModalConfig,
  SuiModalService,
  ActiveModal,
} from "ng2-semantic-ui/dist";

export abstract class FormaModal {
  abstract modalTemplate: ModalTemplate<void, void, void>;
  private ventana: ActiveModal<void, {}, void>;
  private mantenerAbierto: boolean;
  private mostrarMantenerAbierto: boolean;
  public titulo: string;
  private procesando: boolean;
  private tamano: "mini" | "tiny" | "small" | "normal" | "large" = "normal";

  public set Tamano(valor: "mini" | "tiny" | "small" | "normal" | "large") {
    this.tamano = valor;
  }

  public get MantenerAbierto() {
    return this.mantenerAbierto;
  }
  public set MantenerAbierto(valor: boolean) {
    this.mantenerAbierto = valor;
  }

  public get MostrarMantenerAbierto() {
    return this.mostrarMantenerAbierto;
  }
  public set MostrarMantenerAbierto(valor: boolean) {
    this.mostrarMantenerAbierto = valor;
  }

  public get Titulo() {
    return this.titulo;
  }
  public set Titulo(valor: string) {
    this.titulo = valor;
  }

  public get Procesando() {
    return this.procesando;
  }
  public set Procesando(valor: boolean) {
    this.procesando = valor;
  }

  constructor(public modalService: SuiModalService) {}

  public abrir(titulo: string, mostrarMantenerAbierto: boolean, mostrarCerrar = false): void {
    const config = new TemplateModalConfig<void, void, void>(
      this.modalTemplate
    );
    this.titulo = titulo;
    this.mostrarMantenerAbierto = false; //mostrarMantenerAbierto;
    if (!mostrarMantenerAbierto) this.mantenerAbierto = false;

    config.size = this.tamano;
    config.isClosable = mostrarCerrar;

    this.ventana = this.modalService.open(config);
  }

  protected cerrar() {
    if (this.ventana) {
      this.ventana.destroy();
    }
  }
}
