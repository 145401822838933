import { first } from 'rxjs/operators';
import { Usuario } from './../../entidades/usuario';
import { Proveedor } from './../../entidades/proveedor';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalTemplate, SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { Servicios } from '../../servicios/servicios';
import { StaticInjector } from '@angular/core/src/di/injector';

class Destinatario {
  seleccionado: boolean;
  nombre: string;
  correo: string;
}


@Component({
  selector: 'app-seleccion-proveedores',
  templateUrl: './seleccion-proveedores.component.html',
  styleUrls: ['./seleccion-proveedores.component.css']
})
export class SeleccionProveedoresComponent implements OnInit {
  @ViewChild('modalTemplate')
  public modalTemplate: ModalTemplate<{
    seleccionado: boolean,
    proveedor: Proveedor
  }[], string, string>

  titulo = 'Seleccionar destinatarios';
  destinatarios: Destinatario[]

  ordenamiento: {columna: string, ascendente: boolean} = {columna:'', ascendente:true};

  @Output() selecionados: EventEmitter<string[]> = new EventEmitter<string[]>();

  get haySeleccionadas() {
    return this.destinatarios.filter(d => d.seleccionado).length;
  }

  constructor(public modalService: SuiModalService, public ctx: Servicios) { }

  ngOnInit() {
    this.cargarDestinatarios();
  }

  cargarDestinatarios() {
    this.ctx.proveedores.ObtenerProveedores().pipe(first()).subscribe(u => {
      this.destinatarios = u.map(u => { return { nombre: u.razonSocial, seleccionado: true, correo: u.correo } })
    })
  }

  public open() {
    const config = new TemplateModalConfig<{
      seleccionado: boolean,
      proveedor: Proveedor
    }[], string, string>(this.modalTemplate);
    // config.context = confirmConfig;
    config.size = 'normal';
    config.isClosable = false;
    this.modalService
      .open(config)
      .onApprove(result => {
        const destinatariosSeleccionados = this.destinatarios.filter(d => d.seleccionado).map(d => d.correo);
        this.selecionados.emit(destinatariosSeleccionados);
      })
      .onDeny(result => { /* deny callback */ });
  }

  seleccionarTodos(evento: any) {
    this.destinatarios.forEach(d => {
      d.seleccionado = evento.srcElement.checked;
    })
  }

  seleccionarDestinatario(seleccionado: boolean, nombre: string) {
    const elemento = this.destinatarios.find(d => d.nombre == nombre);
    elemento.seleccionado = seleccionado;
  }

  ordenar(columna:string): any[] {
    
    if(this.ordenamiento.columna == columna){
      this.ordenamiento.ascendente = !this.ordenamiento.ascendente
    }else{
      this.ordenamiento.columna = columna;
      this.ordenamiento.ascendente = true;
    }
    let sortedArray = this.destinatarios.sort((a,b)=>{
      if(a[columna] > b[columna]){
        return (this.ordenamiento.ascendente) ? 1 : -1;
      }
      if(a[columna] < b[columna]){
        return (this.ordenamiento.ascendente) ? -1 : 1;
      }
      return 0;
    })
  return sortedArray;
}

}
