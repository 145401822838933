﻿import { Servicio } from "./servicio";
import { RestablecerContrasena } from "../entidades/indice";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioRestablecerContrasena extends Servicio<RestablecerContrasena> {

    constructor(http: HttpClient) {
        super(http, "api/Acceso");
    }

    reesContrasena(usuario: string): Observable<string> {
        let ruta = this.Ruta + "/" + usuario + "/AutorizacionProveedor";
        return this.ClienteHttp.get(ruta)
            .pipe(map((usu: string) => usu));
    }

  

}