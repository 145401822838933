import { Component, HostListener, OnInit, DoCheck, ViewChild } from '@angular/core';
import { SuiModule, SuiSidebarModule, SuiLocalizationService } from 'ng2-semantic-ui';
import es from "ng2-semantic-ui/locales/es";
import { ServicioAutenticacion} from './servicios/indice';
import { Usuario, Sociedad } from './entidades/indice';
import { Router, NavigationStart } from '@angular/router';
import { Credencial } from './entidades/credencial';
import { SuiSidebar, SidebarDirection } from 'ng2-semantic-ui/dist';


@Component({
    selector: 'app-cfdi',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, DoCheck {
    @ViewChild(SuiSidebar) sidebar: SuiSidebar;
   
    
    constructor(
        public localizationService: SuiLocalizationService, 
        public autenticacion: ServicioAutenticacion, 
        private router: Router
    ) {
        localizationService.load("es", es);
        localizationService.setLanguage("es");
    }

    ngOnInit() {        
      
    }

    

    ngDoCheck() {
        let rutaSinArgumentos: string = this.router.url.split("?")[0];
        let rutasValida = rutaSinArgumentos == '/' || rutaSinArgumentos == '/Restablecercontrasena' ;
        if (!this.autenticacion.estaAutenticado && !rutasValida) {
            this.router.navigateByUrl('/');
            this.salir();
        } 
    }

    salir(): void {
        this.autenticacion.salir();
        this.sidebar.isVisible = false;        
    }

    descargarAyuda(){
        return window.open('images/Manual_Usuario.pdf');
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
    }
}
