﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Proveedor, Corporativo, Configuracion } from '../../entidades/indice';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';
import { DatepickerMode, SuiModalService } from 'ng2-semantic-ui';
import { Notificacion } from '../../compartida/indice';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { TipoOrdenCompra } from '../../entidades/tipo-orden-compra';

@Component({
    selector: 'app-configuracion',
    templateUrl: './configuracion.component.html',
    styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {

    titulo: string = "Configuración";
    procesando: boolean;
    configuracion: Configuracion;
    configuraciones: Configuracion[];
    idConfiguracion: number;
    tiposOrdenesCompra: TipoOrdenCompra[];
    pageDimmed: boolean = false;
    mostrarCargarOrdenesCompra: boolean;
    tipoEnvioFacturasSinOC: any;

    constructor(public modalService: SuiModalService, private snotifyService: SnotifyService, private servicios: Servicios, private notificacion: Notificacion) { }
    ngOnInit() {
        this.configuracion = new Configuracion;
        this.cargarConfiguracion();
        this.cargarTiposOrdenCompra();
    }
    cargarConfiguracion(): void {
        this.idConfiguracion = this.servicios.autenticacion.credencial.idCorporativo;

        let observable = this.servicios.configuracion.obtener(this.idConfiguracion);
        observable
            .subscribe(
                confs => {
                    this.configuracion = confs;
                    if (this.configuracion.tipoOrdenCompraId == 1) {
                        this.mostrarCargarOrdenesCompra = false;
                    } else {
                        this.mostrarCargarOrdenesCompra = true;
                    }
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError("Lo sentimos aun no se han creado las configuraciones necesarias");
                    this.procesando = false;
                }
            );
    }

    guardar(): void {
        let max = this.configuracion.importeMaximo;
        let min = this.configuracion.importeMinimo;

        // if (max < 0 || min < 0) {
        //     this.snotifyService.warning("Los valores no pueden ser negativos, verifique nuevamente.", {
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         position: SnotifyPosition.centerTop
        //     });
        // }

        this.procesando = true;
        let mensaje: string = "Configuración actualizada.";
        if (this.configuracion.tipoOrdenCompraId == 1) {
            this.configuracion.pedirOrdenCompra = true;
        }

        let observable = this.servicios.configuracion.actualizar(this.idConfiguracion.toString(), this.configuracion);
        observable
            .subscribe(
                proveedores => {
                    this.notificacion.mostrarExito(mensaje);
                    this.procesando = false;
                    this.cargarConfiguracion();
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );

    }

    ngOnDestroy(): void {
    }

    cargarTiposOrdenCompra() {
        this.servicios.tiposOrdenCompra
            .obtenerTodos()
            .subscribe(
                lista => {
                    this.tiposOrdenesCompra = lista;
                }
            );
    }

    seleccionarCargarOrdenCompra(valor): void {
        if (valor == 1) {
            this.mostrarCargarOrdenesCompra = false;
        }
        else {
            this.mostrarCargarOrdenesCompra = true;
        }
    }
}
