﻿import { Component, Renderer2, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion, Notificacion } from '../../compartida/indice';
import { Usuario } from '../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'tabla-usuario',
    templateUrl: 'tabla.component.html'
})
export class TablaCatalogoUsuario implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Usuario> = new Subject();
    @Output() editarOprimido: EventEmitter<Usuario> = new EventEmitter<Usuario>();
    @Output() eliminarOprimido: EventEmitter<number> = new EventEmitter<number>();
    private oyente: () => void;
    usuarios: Usuario[];
    idSeleccionado: number;

    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit(): void {
        this.cargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'a') {
                    this.editar(id);
                } else {
                    this.idSeleccionado = id;
                    this.preguntarSieliminar();
                }
            }
        });
    }

    editar(id: number): void {
        let usuario = this.usuarios.find(u => u.idUsuario == id);
        this.editarOprimido.emit(usuario);
    }

    preguntarSieliminar(): void {
        this.confirmacion.open({
            texto: "¿Seguro que desea continuar?",
            textoCancelar: "No",
            textoOk: "Si",
            titulo: "Confirmacion"
        });
    }

    eliminar() {
        this.servicios
            .usuarios
            .eliminar(this.idSeleccionado.toString())
            .subscribe(
                eliminado => {
                    this.notificacion.mostrarExito("Usuario eliminado.");
                    this.recargarTabla();
                },
            error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: {url: '/js/espanol.json'},
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .usuarios
                    .obtenerTodos()
                    .subscribe(usuarios => {
                        this.usuarios = usuarios;
                        callback({ data: usuarios });
                    });
            },
            columns: [
                { title: 'Id', data: 'idUsuario', visible: false },
                { title: 'Nombre', data: 'nombreUsuario' },
                { title: 'Correo', data: 'correo' },
                {
                    title: 'Opciones',
                    data: 'idUsuario',
                    width: "15%",
                    render: function (data: any, type: any, full: any) {
                        return `<div class="ui icon buttons">
                                    <button class="ui orange basic button" acc tipo="a" data-id="` + data + `" data-inverted="" data-tooltip="Editar" data-position="top center">
                                        <i class="large edit icon" acc tipo="a" data-id="` + data + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="e" data-id="` + data + `" data-inverted="" data-tooltip="Eliminar" data-position="top center">
                                        <i class="large trash alternate icon" acc tipo="e" data-id="` + data + `"></i>
                                    </button>
                                </div>`;
                    }
                }
            ]
        };
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

    ngOnDestroy(): void {
        this.oyente();
    }
}