import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Servicios, ServicioOrdenCompra } from '../../servicios/indice';
import { OrdenCompra, Proveedor } from '../../entidades/indice';
import { Notificacion, Confirmacion } from '../../compartida/indice';
import { DatepickerMode } from 'ng2-semantic-ui';
import { FormaOrdenCompra } from './forma.component';
import { NgForm } from '@angular/forms';


@Component({
    selector: 'app-ordencompra',
    templateUrl: './ordencompra.component.html',
    styleUrls: ['./ordencompra.component.css']
})
export class OrdencompraComponent implements OnInit {
    titulo: string = "Ordenes de compras";
    idSeleccionado: number;
    Procesando: boolean;
    private oyente: () => void;
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @Output() xmlOprimido: EventEmitter<OrdenCompra> = new EventEmitter<OrdenCompra>();
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    dtTrigger: Subject<OrdenCompra> = new Subject();
    dtOptions: DataTables.Settings = {};
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    @ViewChild(FormaOrdenCompra) formaOrdenCompra: FormaOrdenCompra;

    proveedores: Proveedor[];
    ordenesCompra: OrdenCompra[];
    ordencompra: OrdenCompra;
    modoFecha = DatepickerMode.Date;
    tipoBusqueda = 0;
    mostrarDatos1: boolean;
    mostrarDatos2: boolean;


    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit() {
        this.ordencompra = new OrdenCompra;
        this.cargarProveedores();
        this.cargarTabla();
    }

    actdvFecha1(valor): void {
        if (valor == 1) {
            this.mostrarDatos1 = true;
            this.mostrarDatos2 = false;
            this.ordencompra.fechaRegistroDesde = null;
            this.ordencompra.fechaRegistroHasta = null;
        }
        if (valor == 2) {
            this.mostrarDatos1 = false;
            this.mostrarDatos2 = true;
            this.ordencompra.fechaFinalDesde = null;
            this.ordencompra.fechaFinalHasta = null;
        }
    }

    cargarProveedores() {
        this.servicios
            .proveedores
            .ObtenerProveedores()
            .subscribe(
                lista => {
                    this.proveedores = lista;
                }
            );
    }

    nuevo(): void {
        this.formaOrdenCompra.abrirNuevo();
    }

    limpiar(): void {
        this.forma.resetForm();

        this.mostrarDatos1 = false;
        this.mostrarDatos2 = false;
        this.tipoBusqueda = 0;

        this.recargarTabla();
    }

    buscar(): void {
        this.Procesando = true;
        this.recargarTabla();
        this.Procesando = false;
    }

    reporteExcel(): void {
        let observable = this.servicios
            .ordenCompra
            .obtenerReporteExcelC(this.ordencompra)
            .subscribe(
                xls => {
                    let a = document.createElement("a");
                    let blob = new Blob([xls], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = "Reporte_ordenes_de_compra" + ".xlsx";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: { url: '/js/espanol.json' },
            searching: false,
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .ordenCompra
                    .obtenerReporte(this.ordencompra)
                    .subscribe(

                        lista => {
                            this.ordenesCompra = lista;
                            callback({ data: lista });
                        }
                    );
                this.Procesando = false;
            },
            columns: [
                { title: '', data: 'idOrdenCompra', visible: false },
                { title: '', data: 'idProveedor', visible: false },
                { title: 'Proveedor', data: 'proveedorRazonSocial' },
                { title: 'Orden de Compra', data: 'numero' },
                { title: 'Fecha de alta ', data: 'fechaRegistro', render: (data: string) => (new Date(data)).toLocaleDateString() },
                {
                    title: 'Estatus',
                    data: 'cerrada',
                    width: "10%",
                    render: (data: any, type: any, full: OrdenCompra) => {

                        if (full.cerrada == "Cerrada") {
                            return `<div class="ui icon buttons">
                                            <button class="ui  basic button"  data-tooltip="Conciliada" data-position="top center">
                                                <i class="check square icon orange" style="font-size:22px; margin-left:20px !important;"></i>
                                            </button>
                                       </div>`;
                        } else {
                            return `<div class="ui icon buttons">
                                            <button class="ui  basic button"  data-inverted="" data-tooltip="Por conciliar" data-position="top center">
                                                <i class="window close outline icon orange" style="font-size:22px; margin-left:20px !important;"></i>
                                            </button>
                                       </div>`;
                        }
                    }
                },
                //{ title: 'Sub-Total', data: 'subTotal' },
                //{ title: 'IVA', data: 'iva' },
                { title: 'Total', data: 'total' },
                {
                    title: 'Conciliado',
                    data: 'montoCubierto',
                    render: (data: any, type: any, full: OrdenCompra) => {
                        return `<i class="caret up icon" style="color:#078f1a"></i>` + (full.total < full.montoCubierto ? full.total : full.montoCubierto);
                    }
                },
                {
                    title: 'Por conciliar',
                    data: 'porPagar',
                    render: (data: any, type: any, full: OrdenCompra) => {
                        return `<i class="caret down icon" style="color:#ff0000"></i>` + (full.porPagar < 0 ? 0 : full.porPagar);
                    }
                },
                {
                    title: 'Opciones',
                    data: 'idOrdenCompra',
                    width: "15%",
                    render: (data: any, type: any, full: OrdenCompra) => {
                        return `<div class="ui icon buttons" style="font-size:22px; margin-left:25px !important;">
                                    <button class="ui orange basic button" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.numero + `" data-inverted="" data-tooltip="Descargar Orden de compra PDF" data-position="top center">
                                       <i class="large file pdf outline icon" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.numero + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="cerrar" data-id="` + data + `"  data-inverted="" data-tooltip="Cerrar Orden de Compra Manualmente" data-position="top center">
                                       <i class="clipboard check large icon" acc tipo="cerrar" data-id="` + data + `"></i>
                                    </button>
                                </div>`;
                    }
                },
                {
                    title: 'Cancelada',
                    data: 'cancelada',
                    width: "5%",
                    render: (data: any, type: any, full: OrdenCompra) => {
                        if (full.cancelada) {
                            return `<div class="ui icon buttons">
                                            <button class="ui  basic button"  data-tooltip="Cancelada" data-position="top center">
                                                <i class="check square icon orange" style="font-size:22px; margin-left:20px !important;"></i>
                                            </button>
                                       </div>`;
                        } else {
                            return `<div class="ui icon buttons">
                                        <button class="ui orange basic button" acc tipo="cancelar" data-id="` + full.idOrdenCompra + `"  data-inverted="" data-tooltip="Cancelar orden de compra" data-position="top center">
                                            <i class="ban large icon" acc tipo="cancelar" data-id="` + full.idOrdenCompra + `"></i>
                                        </button>
                                    </div>`;
                        }
                    }
                }
            ]
        };
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.renderer.destroy();
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let nombre = objetivo.getAttribute('data-nombre');
                let id = parseInt(objetivo.getAttribute('data-id'));
                this.idSeleccionado = id;
                if (tipo == 'pdf') {
                    this.descargarPdf(this.idSeleccionado, nombre);
                }
                if (tipo == 'cerrar') {
                    this.preguntarSiCerrarManualmente();
                }
                if (tipo == 'cancelar') {
                    this.preguntarSiCancelar();
                }
            }
        });
    }

    preguntarSiCerrarManualmente(): void {
        this.confirmacion.open({
            texto: "�Desea cerrar la orden de compra manualmente?",
            textoCancelar: "No",
            textoOk: "Si",
            titulo: "Confirmaci�n",
            accionOk: this.cerrar.bind(this)
        });
    }

    cerrar() {
        this.servicios
            .ordenCompra
            .CerrarManualmente(this.idSeleccionado)
            .subscribe(
                cerrada => {
                    if (cerrada == "true") {
                        this.notificacion.mostrarExito("Orden de compra cerrada manualmente.");
                        this.recargarTabla();
                    } else {
                        this.notificacion.mostrarAdvertencia("La orden de compra ya ha sido cerrada anteriormente, verifique nuevamente.");
                        this.recargarTabla();
                    }
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }

    async preguntarSiCancelar(): Promise<void> {
        const sePuede = await this.sePuedeCancelar();
        if (sePuede) {
            this.confirmacion.open({
                texto: "�Desea cancelar la orden de compra?",
                textoCancelar: "No",
                textoOk: "Si",
                titulo: "Confirmaci�n",
                accionOk: this.cancelar.bind(this)
            });
        }
        else {
            this.notificacion.mostrarAdvertencia("La orden de compra tiene facturas asignadas. No se puede cancelar.");
        }
    }

    async sePuedeCancelar(): Promise<boolean> {
        return await this.servicios.ordenCompra.sePuedeCancelar(this.idSeleccionado).toPromise();
    }

    cancelar() {
        this.servicios
            .ordenCompra
            .cancelar(this.idSeleccionado)
            .subscribe(
                cerrada => {
                    if (cerrada == "true") {
                        this.notificacion.mostrarExito("Orden de compra cancelada.");
                        this.recargarTabla();
                    } else {
                        this.notificacion.mostrarAdvertencia("La orden de compra ya ha sido cancelada anteriormente, verifique nuevamente.");
                        this.recargarTabla();
                    }
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }


    descargarPdf(id: number, nombre: string): void {
        let observable = this.servicios
            .ordenCompra
            .descargarPdf(id)
            .subscribe(
                pdf => {
                    let a = document.createElement("a");
                    let blob = new Blob([pdf], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
            console.log('ordenesCompra-->', this.ordenesCompra);
        });
    }

    ngOnDestroy(): void {
        this.oyente();
        this.formaOrdenCompra.cancelar();
    }
}
