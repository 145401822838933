﻿import { ServicioAvisosService } from './servicio-avisos.service';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ServicioUsuario } from "./servicioUsuario";
import { ServicioSociedad } from "./servicioSociedad";
import { ServicioCorreo} from "./servicioCorreo";
import { ServicioArchivos } from "./servicioArchivos";
import { ServicioAutenticacion } from "./servicioAutenticacion";
import { ServicioProveedor } from "./servicioProveedor";
import { Router } from "@angular/router";
import { ServicioCorporativo } from "./servicioCorporativo";
import { ServicioRestablecerContrasena } from "./indice";
import { ServicioOrdenCompra } from "./indice";
import { ServicioConfiguracion } from "./servicioConfiguracion";
import { ServicioPagos } from "./servicioPago";
import { ServicioTipoOrdenCompra } from "./servicioTiposOrdenCompra";
import { ServicioSap } from "./servicio-sap";
import { ServicioComunicado } from "./servicioComunicado";
@Injectable()
export class Servicios {
    autenticacion: ServicioAutenticacion;
    usuarios: ServicioUsuario;
    sociedades: ServicioSociedad;
    correos: ServicioCorreo;
    archivos: ServicioArchivos;
    proveedores: ServicioProveedor;
    corporativos: ServicioCorporativo;
    restablecerContrasena: ServicioRestablecerContrasena;
    ordenCompra: ServicioOrdenCompra;
    configuracion: ServicioConfiguracion;
    pagos: ServicioPagos;
    tiposOrdenCompra: ServicioTipoOrdenCompra;
    comunicados: ServicioComunicado;

    //SAP
    servicioSap: ServicioSap

    //Avisos
    avisos: ServicioAvisosService

    constructor(private http: HttpClient, private router: Router) {
        this.usuarios = new ServicioUsuario(http);
        this.sociedades = new ServicioSociedad(http);
        this.correos = new ServicioCorreo(http);
        this.archivos = new ServicioArchivos(http);
        this.autenticacion = new ServicioAutenticacion(http, router);
        this.proveedores = new ServicioProveedor(http);
        this.corporativos = new ServicioCorporativo(http);
        this.restablecerContrasena = new ServicioRestablecerContrasena(http);
        this.ordenCompra = new ServicioOrdenCompra(http);
        this.configuracion = new ServicioConfiguracion(http);
        this.pagos = new ServicioPagos(http);
        this.tiposOrdenCompra = new ServicioTipoOrdenCompra(http);
        this.servicioSap = new ServicioSap(http);
        this.comunicados = new ServicioComunicado(http);
        this.avisos = new ServicioAvisosService(http);
    }
}