﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormaPagos } from './forma.component';
import { Sociedad } from '../../entidades/indice';
import { TablaCatalogoPagos } from './tabla.component';

@Component({
    selector: "catalogo-pagos",
    templateUrl: "catalogo.component.html"
})
export class CatalogoPagos implements OnDestroy {
    @ViewChild(FormaPagos) formPagos: FormaPagos;
    @ViewChild(TablaCatalogoPagos) tablaCatalogo: TablaCatalogoPagos;

    titulo: string = "Validacio&#769;n de Pagos";

    nuevo(): void {
        this.formPagos.abrirNuevo();
    }

    recargarTabla(): void {
        this.tablaCatalogo.recargarTabla();
    }

    ngOnDestroy(): void {
        this.formPagos.cancelar();
    }
}