import { Servicios } from '../../../servicios/indice';
import { Component, ViewChild, OnDestroy } from '@angular/core';
import { AvisoModalComponent } from '../aviso-modal/aviso-modal.component';
import { TablaAvisosComponent } from '../tabla-avisos/tabla-avisos.component';
import { Notificacion } from '../../../compartida/indice';
import { Aviso } from 'ClientApp/app/entidades/indice';

@Component({
  selector: 'app-avisos',
  templateUrl: './avisos.component.html',
  styleUrls: ['./avisos.component.css']
})
export class AvisosComponent implements OnDestroy {
  @ViewChild(AvisoModalComponent) avisoModalComponent!: AvisoModalComponent;
  @ViewChild(TablaAvisosComponent) tablaAvisos: TablaAvisosComponent;
  titulo = 'Avisos';
  constructor(public servicios: Servicios, private notificacion: Notificacion) {}
  nuevo() {    
    this.avisoModalComponent.abrirNuevo();    
  }

  editar(idAviso: number): void {
    this.avisoModalComponent.idAviso = idAviso;
    this.avisoModalComponent.abrirEditar();
  }

  recargarTabla(): void {
		this.tablaAvisos.recargarTabla();
	}

  ngOnDestroy(): void {
    this.avisoModalComponent.cancelar();
  }

}
