﻿export class OrdenCompra {
    idOrdenCompra: number;
    numero: string;
    cerrada: string;
    subTotal: number;
    iva: number;
    retencion: number;
    total: number;
    porPagar: number;
    montoCubierto: number;
    fechaRegistro: Date;
    idProveedor: number;
    proveedorRazonSocial: string;
    rfc: string;
    fechaRegistroDesde: Date;
    fechaRegistroHasta: Date;
    fechaFinalDesde: Date;
    fechaFinalHasta: Date;
    nombreArchivo: string;
    descripcion:string;
    moneda:string;
    cancelada: boolean;
    tipoBusqueda: string;
    abiertaXmonitor: boolean;
}