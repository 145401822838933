import { Aviso } from './../entidades/aviso';
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Servicio } from "./servicio";

export class ServicioAvisosService extends Servicio<Aviso> {

  constructor(
    http: HttpClient
  ) 
  {
    super(http, "avisos");
  }

  crearAviso(datos: FormData): Observable<Aviso> {
    let ruta = this.Ruta;
    return this.ClienteHttp.post(ruta, datos)
        .pipe(map((aviso: Aviso) => aviso));
  } 

  actualizarAviso(datos: FormData): Observable<Aviso> {
    let ruta = this.Ruta;
    return this.ClienteHttp.put(ruta, datos)
        .pipe(map((aviso: Aviso) => aviso));
  } 

  obtenerAvisosVigentes() : Observable<Aviso[]> {
    let ruta = this.Ruta + '/BuscarAvisosVigentes';
    return this.ClienteHttp.get<Aviso[]>(ruta).pipe(
      map((avisos: Aviso[]) => avisos)
    );
  } 

  descargarArchivo(idAviso: number): Observable<Blob> {
    let ruta = this.Ruta + "/ArchivoAviso/" + idAviso;
    return this.ClienteHttp.get(ruta, { responseType: 'blob' })
        .pipe(map((archivo: Blob) => archivo)); 
  }

  getAviso(idAviso: number): Promise<Aviso> {    
    let ruta = this.Ruta + "/Aviso/" + idAviso;
    return new Promise((resolve, reject) => {
      this.ClienteHttp.get(ruta).toPromise()
      .then( (aviso: Aviso) => {        
        resolve(aviso);
      })
      .catch((error) => {
        reject(error.error.err.message);
      });
    });
  }


}
