﻿import {
    Component,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { FormaArchivos } from './forma.component';
import { TablaCatalogoArchivos } from './tabla.component';
import { Servicios } from '../../servicios/indice';
import { Notificacion } from '../../compartida/indice';

@Component({
    selector: 'catalogo-archivos',
    templateUrl: 'catalogo.component.html',
})
export class CatalogoArchivos implements OnDestroy {
    @ViewChild(FormaArchivos) formaSociedad: FormaArchivos;
    @ViewChild(TablaCatalogoArchivos) tablaCatalogo: TablaCatalogoArchivos;

    constructor(public servicios: Servicios, private notificacion: Notificacion) { }

    titulo: string = 'Validacio&#769;n y envío de XML Facturas';

    async nuevo() {

        let tienePendientes = await this.tieneFacturasSinPagoRelacionado();
        let hoy = new Date();
        if (tienePendientes && hoy.getDay() < 11) {
            const msj = "Existen facturas sin complemento de pago, para ingresar una nueva factura es necesario que no haya facturas sin su complemento";
            this.notificacion.mostrarAdvertencia(msj);
        }
        else {
            let tieneComplementoSinPago = await this.tieneComplementoSinPago();
            if (tieneComplementoSinPago) {
                const msj = "Existen facturas sin complemento de pago XML, para ingresar una nueva factura es necesario que no haya facturas sin su complemento";
                this.notificacion.mostrarAdvertencia(msj);

            } else {
                await this.formaSociedad.abrirNuevo();
            }

        }

    }


    async tieneComplementoSinPago(): Promise<boolean> {
        return this.servicios.archivos.tieneComplementoAsociado().toPromise();
    }

    async tieneFacturasSinPagoRelacionado(): Promise<boolean> {
        return this.servicios.archivos.tieneFacturasSinPagoRelacionado().toPromise();
    }

    recargarTabla(): void {
        this.tablaCatalogo.recargarTabla();
    }

    ngOnDestroy(): void {
        this.formaSociedad.cancelar();
    }
}
