﻿export class Pago {
    idPago: number;
    nombrePago: string;
    fechaRegistro: Date;
    cancelado: boolean;
    total: number;

    archivosId: number[] = [];
    archivoNombre: string;
    archivoTotal: number;
    pue: boolean;
    facPue: string;

    idProveedor: number;
    proveedorRazonSocial: string;
    proveedorRFC: string;

    sociedadNombreComercial: string;
    sociedadRFC: string;

    fechaDesde: Date;
    fechaHasta: Date;
    fechaTimbro: Date;

    facturasRel: String;
    ocRel: String;


    uuids: string[];
}