﻿import { Servicio } from "./servicio";
import { Sociedad } from "../entidades/indice";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioSociedad extends Servicio<Sociedad> {

    constructor(http: HttpClient) {
        super(http, "sociedades");
    }

    aplicaPruebasEntrega(idSociedad: number): Promise<boolean> {
        let ruta = this.Ruta + "/" + idSociedad + "/AplicaPruebasEntrega";
        return new Promise((resolve, reject) => {
          this.ClienteHttp.get(ruta).toPromise()
            .then(aplicaPruebas => {
              resolve(Boolean(aplicaPruebas));
            })
            .catch(error => [
              resolve(false)
            ]);
        });
      }
}