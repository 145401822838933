import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'menu-configuracion',
    templateUrl: './menu-configuracion.component.html',
    styleUrls: ['./menu-configuracion.component.css']
})
export class MenuConfiguracionComponent implements OnInit {
    @Input() tipoMenu: 1 | 2;
    @Input() nombreUsuario: string;
    @Output() salio = new EventEmitter<void>();
    @Output() clic = new EventEmitter<void>();
    constructor() { }

    ngOnInit() {
    }

}
