﻿export class Sociedad {
    id: number;
    rfc: string;
    razonSocial: string;
    nombreComercial: string;
    correoElectronico: string;
    activo: boolean;
    fechaRegistro: Date;
    codigoPostal: string;
    aplicaPruebasEntrega: boolean;
    regimenFisca: string;
}