﻿import { Servicio } from "./servicio";
import { Correo, Pago, Archivo } from "../entidades/indice";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioPagos extends Servicio<Pago> {

    constructor(http: HttpClient) {
        super(http, "pagos");
    }

    guardarCompleto(datos: FormData): Observable<Pago> {
        let ruta = this.Ruta;
        return this.ClienteHttp.post(ruta, datos)
            .pipe(map((archivo: Pago) => archivo));
    }

    descargarXml(idPago: number | string): Observable<string> {
        let ruta = this.Ruta + "/" + idPago + "/DescargarXml";
        return this.ClienteHttp.get(ruta, { responseType: 'text' })
            .pipe(map((xml: string) => xml));
    }

    descargarPdf(idPago: number | string): Observable<Blob> {
        let ruta = this.Ruta + "/" + idPago + "/DescargarPdf";

        return this.ClienteHttp.get(ruta, { responseType: 'blob' })
            .pipe(map((pdf: Blob) => pdf));

    }


    obtenerReporte(entidad: Pago): Observable<Pago[]> {
        let ruta = this.Ruta + "/Buscar";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((data: Pago[]) => {
                return data;
            }));
    }

    obtenerReporteExcel(entidad: Pago): Observable<Blob> {
        let ruta = this.Ruta + "/BuscarExcel";
        return this.ClienteHttp.post(ruta, entidad, { responseType: 'blob' })
            .pipe(map((xls: Blob) => xls));
    }

    leerXML(datos: FormData): Observable<Pago> {
        let ruta = `${this.Ruta}/LeerXML`;
        return this.ClienteHttp.post(ruta, datos)
            .pipe(map((archivo: Pago) => archivo));
    }


    obtenerSinComplementoPago(rfc: string): Observable<Archivo[]> {
        let ruta = this.Ruta + "/" + rfc + "/BuscarProveedoresSinComplementoPago";
        return this.ClienteHttp.get(ruta)
        .pipe(map((archivos: Archivo[]) => archivos));
    }
}