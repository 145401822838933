﻿import { DetallesArchivo } from './../entidades/detallesArchivo';
import { Servicio } from "./servicio";
import { Correo, Archivo } from "../entidades/indice";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Respuesta } from "../entidades/respuesta";
import { ArchivoDescargaMasiva } from '../entidades/archivo-descarga-masiva';
import { ArchivoComprimido } from '../entidades/archivo-comprimido';
import { DetalleArchivo } from '../entidades/detalle-archivo';
export class ServicioArchivos extends Servicio<Archivo> {

    constructor(http: HttpClient) {
        super(http, "archivos");
    }

    guardarCompleto(datos: FormData): Observable<Archivo> {
        let ruta = this.Ruta;
        return this.ClienteHttp.post(ruta, datos)
            .pipe(map((archivo: Archivo) => archivo));
    }

    descargarXml(idArchivo: number): Observable<string> {
        let ruta = this.Ruta + "/" + idArchivo + "/DescargarXml";
        return this.ClienteHttp.get(ruta, { responseType: 'text' })
            .pipe(map((xml: string) => xml));
    }

    descargarPdf(idArchivo: number | string): Observable<Blob> {
        let ruta = this.Ruta + "/" + idArchivo + "/DescargarPdf";

        return this.ClienteHttp.get(ruta, { responseType: 'blob' })
            .pipe(map((pdf: Blob) => pdf));

    }

    descargarRecepcionPdf(idArchivo: number): Observable<Blob> {
        let ruta = this.Ruta + "/" + idArchivo + "/DescargarRecepcionPdf";

        return this.ClienteHttp.get(ruta, { responseType: 'blob' })
            .pipe(map((pdf: Blob) => pdf));
    }

    obtenerArchivosSinPagar(): Observable<Archivo[]> {
        let ruta = this.Ruta + "/ArchivosSinPagar";
        return this.ClienteHttp.get(ruta)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    PedidoEnSap(folio: string): Promise<boolean> {
        let ruta = this.Ruta + "/" + Number(folio) + "/PedidoEnSap";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then(estaEnSap => {
                    resolve(Boolean(estaEnSap));
                })
                .catch(error => [
                    resolve(false)
                ]);
        });
    }

    ProveedorMoneda(folio: string): Promise<boolean> {
        let ruta = this.Ruta + "/" + Number(folio) + "/ProveedorMoneda";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then(proveedorMonedaCorrecto => {
                    resolve(Boolean(proveedorMonedaCorrecto));
                })
                .catch(error => [
                    resolve(false)
                ]);
        });
    }

    ImportePedidoUSD(folio: string): Promise<boolean> {
        let ruta = this.Ruta + "/" + Number(folio) + "/ImportePedidoUSD";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then(importeCorrectoUsd => {
                    resolve(Boolean(importeCorrectoUsd));
                })
                .catch(error => [
                    resolve(false)
                ]);
        });
    }

    ImportePedidoMXP(folio: string): Promise<boolean> {
        let ruta = this.Ruta + "/" + Number(folio) + "/ImportePedidoMXP";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then(importeCorrectoUsd => {
                    resolve(Boolean(importeCorrectoUsd));
                })
                .catch(error => [
                    resolve(false)
                ]);
        });
    }

    descargaMasiva(listaArchivos: ArchivoDescargaMasiva): Observable<ArchivoComprimido> {
        let ruta = this.Ruta + "/DescargaMasiva";
        return this.ClienteHttp.post(ruta, listaArchivos)
            .pipe(map((data: ArchivoComprimido) => {
                return data;
            }));
    }
    DetallesArchivo(): Observable<DetallesArchivo[]> {
        let ruta = this.Ruta + "/DescripcionArchivo";
        return this.ClienteHttp.get(ruta)
            .pipe(map((data: DetallesArchivo[]) => {
                return data;
            }));
    }

    detallesArchivoSinComplementoPago(): Observable<DetallesArchivo[]> {
        let ruta = this.Ruta + "/DescripcionArchivoSinComplementoPago";
        return this.ClienteHttp.get(ruta)
            .pipe(map((data: DetallesArchivo[]) => {
                return data;
            }));
    }

    obtenerReporte(entidad: Archivo): Observable<Archivo[]> {
        let ruta = this.Ruta + "/Buscar";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    buscarFacturas(criterio: any): Observable<Archivo[]> {
        let ruta = this.Ruta + "/BuscarFacturas";
        return this.ClienteHttp.post(ruta, criterio)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    public validarCFDI(cfdi: any): Promise<any> {
        let ruta = this.Ruta + "/ValidarCFDI";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.post(ruta, cfdi).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    console.log('error-->', error);
                    resolve(false);
                });
        });
    }

    tieneRecepcion(id: number): Observable<boolean> {
        let ruta = this.Ruta + "/" + id + "/TieneRecepcion";
        return this.ClienteHttp.get<boolean>(ruta);
    }

    tieneAutorizacion(id: number): Observable<boolean> {
        let ruta = this.Ruta + "/" + id + "/TieneAutorizacion";
        return this.ClienteHttp.get<boolean>(ruta);
    }

    obtenerReporteTrackingProveedor(entidad: Archivo): Observable<Archivo[]> {
        let ruta = this.Ruta + "/BuscarTrackingProveedor";
        console.log('archivo-->', entidad);
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    obtenerReporteExcel(entidad: Archivo): Observable<Blob> {
        let ruta = this.Ruta + "/BuscarExcel";
        return this.ClienteHttp.post(ruta, entidad, { responseType: 'blob' })
            .pipe(map((xls: Blob) => xls));
    }

    obtenerReporteExcelSinComplemento(entidad: Archivo): Observable<Blob> {
        let ruta = this.Ruta + "/BuscarExcelSinComplementos";
        return this.ClienteHttp.post(ruta, entidad, { responseType: 'blob' })
            .pipe(map((xls: Blob) => xls));
    }

    obtenerReporteExcelConComplemento(entidad: Archivo): Observable<Blob> {
        let ruta = this.Ruta + "/BuscarExcelConComplementos";
        return this.ClienteHttp.post(ruta, entidad, { responseType: 'blob' })
            .pipe(map((xls: Blob) => xls));
    }


    pagoFacturaFA(entidad: Archivo): Observable<Archivo> {
        let ruta = this.Ruta + "/pagoFacturaFA";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((archivo: Archivo) => archivo));
    }


    pagoFacturaCA(entidad: Archivo): Observable<Archivo> {
        let ruta = this.Ruta + "/pagoFacturaCA";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((archivo: Archivo) => archivo));
    }

    leerXML(datos: FormData, sociedadId: number): Observable<Archivo> {
        let ruta = `${this.Ruta}/LeerXML/${sociedadId}`;
        return this.ClienteHttp.post(ruta, datos)
            .pipe(map((archivo: Archivo) => archivo));
    }

    obtenerReporteSinComplementos(entidad: Archivo): Observable<Archivo[]> {
        let ruta = this.Ruta + "/BuscarFacturasSinComplemento";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    obtenerReporteConComplementos(entidad: Archivo): Observable<Archivo[]> {
        let ruta = this.Ruta + "/BuscarFacturasConComplemento";
        return this.ClienteHttp.post(ruta, entidad)
            .pipe(map((data: Archivo[]) => {
                return data;
            }));
    }

    cancelarOrdenCompra(id: number): Observable<Respuesta> {
        let ruta = this.Ruta + "/" + id + "/CancelarOrdenCompra";
        return this.ClienteHttp.get(ruta)
            .pipe(map((respuesta: Respuesta) => respuesta));
    }

    tieneComplementoAsociado(): Observable<boolean> {
        let ruta = this.Ruta + "/TieneComplementoAsociado";
        return this.ClienteHttp.get<boolean>(ruta);
    }

    tieneFacturasSinPagoRelacionado(): Observable<boolean> {
        let ruta = this.Ruta + "/TieneFacturasSinPagoRelacionado";
        return this.ClienteHttp.get<boolean>(ruta);
    }

    obtenerDetallesArchivo(archivoId: number) {
        let ruta = this.Ruta + '/ObtenerDetalles/' + archivoId;
        return this.ClienteHttp.get<DetalleArchivo[]>(ruta);
    }

    rechazarCompra(idArchivo: number): Promise<boolean> {
        let ruta = this.Ruta + "/RechazarFactura/" + idArchivo;
        return new Promise((resolve, reject) => {
            this.ClienteHttp.delete(ruta).toPromise()
                .then((success: any) => {
                    console.log('success-->', success);
                    resolve(true);
                })
                .catch((error) => {

                    resolve(false);
                });
        });
    }

    public reporteFlujoCompras(): Promise<any> {
        let ruta = this.Ruta + "/ReporteFlujoCompras";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success.filter(c => String(c.canceled) !== 'Y' && String(c.canceled) !== 'C'))
                })
                .catch((error) => {
                    resolve([]);
                });
        });
    }

    public reporteFlujoComprasOtros(): Promise<any> {
        let ruta = this.Ruta + "/ReporteFlujoComprasOtros";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    console.log('error-->', error);
                    resolve([]);
                });
        });
    }

    public reporteFlujoComprasSAP(): Promise<any> {
        let ruta = this.Ruta + "/ReporteFlujoComprasSap";
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    console.log('error-->', error);
                    resolve([]);
                });
        });
    }


    public pruebasEntregaAceptadas(idArchivo): Promise<any> {
        let ruta = this.Ruta + "/PruebasEntregaAceptadas/" + idArchivo;
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    console.log('error-->', error);
                    resolve(false);
                });
        });
    }

    public usuarioResponsable(folioCV): Promise<any> {
        let ruta = this.Ruta + "/UsuarioResponsable/" + folioCV;
        return new Promise((resolve, reject) => {
            this.ClienteHttp.get(ruta).toPromise()
                .then((success: any) => {
                    resolve(success);
                })
                .catch((error) => {
                    console.log('error-->', error);
                    resolve(false);
                });
        });
    }

}