﻿import { Servicio } from "./servicio";
import { Correo } from "../entidades/indice";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioCorreo extends Servicio<Correo> {

    constructor(http: HttpClient) {
        super(http, "correos");
    }
}