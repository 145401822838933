﻿import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion } from '../../compartida/indice';
import { Pago } from '../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';
import { StringifyOptions } from 'querystring';

@Component({
    selector: 'tabla-pagos',
    templateUrl: 'tabla.component.html'
})
export class TablaCatalogoPagos implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Pago> = new Subject();
    @Output() xmlOprimido: EventEmitter<number> = new EventEmitter<number>();
    @Output() pdfOprimido: EventEmitter<number> = new EventEmitter<number>();
    private oyente: () => void;
    pagos: Pago[];

    constructor(private servicios: Servicios, private renderer: Renderer2) { }

    ngOnInit(): void {
        this.cargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();

        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let nombre = objetivo.getAttribute('data-nombre');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'xml') {
                    this.descargarXml(id, nombre);
                } else {
                    this.descargarPdf(id, nombre);
                }
            }
        });
    }

    descargarPdf(id: number, nombre: string): void {
        let observable = this.servicios
            .pagos
            .descargarPdf(id)
            .subscribe(
                pdf => {
                    let a = document.createElement("a");
                    let blob = new Blob([pdf], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    descargarXml(id: number, nombre: string): void {
        let observable = this.servicios
            .pagos
            .descargarXml(id)
            .subscribe(
                xml => {
                    let a = document.createElement("a");
                    let blob = new Blob([xml], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".xml";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: { url: '/js/espanol.json' },
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .pagos
                    .obtenerTodos()
                    .subscribe(pagos => {
                        this.pagos = pagos;
                        callback({ data: pagos });
                    });
            },
            columns: [
                { title: 'Id', data: 'idPago', visible: false },
                { title: 'Sociedad', data: 'sociedadNombreComercial' },
                { title: 'Folio de complemento', data: 'nombrePago' },
                { title: 'OC Relacionadas', data: 'ocRel' },
                { title: 'Facts Relacionadas', data: 'facturasRel' },
                { title: 'Fecha', data: 'fechaRegistro', render: (data: string) => (new Date(data)).toLocaleDateString()},
                { title: 'Total', data: 'total' },
                {
                    title: 'Opciones',
                    data: 'idPago',
                    width: "15%",
                    render: (data: any, type: any, full: Pago) => {
                        return `<div class="ui icon buttons">
                                    <button class="ui orange basic button" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombrePago + `" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
                                        <i class="large file excel outline icon" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombrePago + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombrePago + `" data-inverted="" data-tooltip="Descargar PDF" data-position="top center">
                                       <i class="large file pdf outline icon" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombrePago + `"></i>
                                    </button>
                                </div>`;
                    }
                }
            ]
        };
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });

        //this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //    dtInstance.ajax.reload();
        //});

    }


    ngOnDestroy(): void {
        this.oyente();
    }
}