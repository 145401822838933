﻿import { Comunicado } from './../entidades/comunicado';
import { Servicio } from "./servicio";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioComunicado extends Servicio<Comunicado> {

    constructor(http: HttpClient) {
        super(http, "Comunicado");
    }

    enviar(comunicado: FormData){
        return this.ClienteHttp.post<boolean>(this.Ruta, comunicado);
    }

}