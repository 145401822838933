import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-validacion',
    templateUrl: './validacion.component.html',
    styleUrls: ['./validacion.component.css']
})
export class ValidacionComponent implements OnInit {
    titulo = "Validacio&#769;n de CFDIs";
    constructor() { }

    ngOnInit() {
    }

}
