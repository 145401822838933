import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal } from '../../compartida/indice';
import { EnviarToken } from '../../entidades/indice';
import { Servicios, ServicioAutenticacion } from '../../servicios/indice';

@Component({
    selector: 'app-enviartoken',
    templateUrl: './enviartoken.component.html',
    styleUrls: ['./enviartoken.component.css']
})
export class EnviartokenComponent extends FormaModal {
    @ViewChild('mdEnviarToken') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('frmEnviarToken', { read: NgForm }) forma: NgForm;
    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();
 
    enviar: EnviarToken;
    tipoProceso: "enviar" | "nuevo" | "registro";
    constructor(public modalService: SuiModalService, private servicios: Servicios, private autenticacion: ServicioAutenticacion, private snotifyService: SnotifyService) {
        super(modalService);
        this.crearNuevo();
        this.Tamano = "tiny";
    }
 

    crearNuevo(): void {
        this.Procesando = false;
        this.enviar = new EnviarToken;
    }
    abrirRegistrar(): void {
        this.crearNuevo();
        this.tipoProceso = "enviar"
        this.abrir("Restablecer contrasen&#771;a", false);
    }


    limpiar(): void {
        this.forma.resetForm();
        this.enviar = new EnviarToken;
        if (!this.MantenerAbierto) {
            this.cerrar();
        }
    }
 
    envToken(): void {
        //this.procesando = true;
        var usuario = this.enviar.emailUsuario;
        this.autenticacion
            .enviarToken(usuario)
            .subscribe(
                result => {
                    if (result == "Enviado") {
                      
                        this.snotifyService.success("Verifique su correo para restablecer su contrase&ntilde;a...", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });
                        this.limpiar();
                        this.procesoTerminado.emit(true);
                        //this.procesando = false;
                    } else {
                        this.snotifyService.error("Erro al enviar correo!, verifique nuevamente...", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });
                        //this.procesando = false;
                    }
                }
                , error => {
                    let httpError = <HttpErrorResponse>error;
                    this.snotifyService.error("Lo sentimos no existe ese usuario, verifique nuevamente!", {
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerTop
                    });
                    //this.procesando = false;
                });
    }

    cancelar(): void {
        this.cerrar();
    }

}
