﻿import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion, Notificacion } from '../../compartida/indice';
import { Correo } from '../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'tabla-correo',
    templateUrl: 'tabla.component.html'
})
export class TablaCatalogoCorreo implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Correo> = new Subject();
    @Output() editarOprimido: EventEmitter<Correo> = new EventEmitter<Correo>();
    @Output() eliminarOprimido: EventEmitter<number> = new EventEmitter<number>();
    private oyente: () => void;
    correos: Correo[];
    idSeleccionado: number;

    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit(): void {
        this.cargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'a') {
                    this.editar(id);
                } else {
                    this.idSeleccionado = id;
                    this.preguntarSieliminar();
                }
            }
        });
    }

    editar(id: number): void {
        let usuario = this.correos.find(u => u.idCorreo == id);
        this.editarOprimido.emit(usuario);
    }

    preguntarSieliminar(): void {
        this.confirmacion.open({
            texto: "¿Seguro que desea continuar?",
            textoCancelar: "No",
            textoOk: "Si",
            titulo: "Confirmacion"
        });
    }

    eliminar() {
        this.servicios
            .correos
            .eliminar(this.idSeleccionado.toString())
            .subscribe(
                eliminado => {
                    this.notificacion.mostrarExito("Correo eliminado.");
                    this.recargarTabla();
                },
            error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: {url: '/js/espanol.json'},
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .correos
                    .obtenerTodos()
                    .subscribe(correos => {
                        this.correos = correos;
                        callback({ data: correos });
                    });
            },
            columns: [
                { title: 'Id', data: 'idCorreo', visible: false },
                { title: 'Dirección', data: 'direccion' },
                { title: 'Servidor', data: 'servidor' },
                { title: 'Puerto', data: 'puerto' },
                {
                    title: 'SSL',
                    data: 'usaSSL',
                    width: "15%",
                    render: function (data: any, type: any, full: any) {
                        let icono = data ? "check circle outline" : "circle outline";
                        return `<div class="ui field">
                                    <i class="large ` + icono + ` icon"></i>
                                </div>`;
                    }
                },
                {
                    title: '',
                    data: 'idCorreo',
                    width: "15%",
                    render: function (data: any, type: any, full: any) {
                        return `<div class="ui field">
                                    <div class="ui icon buttons center aligned field">
                                        <button class="ui orange basic button" acc tipo="a" data-id="` + data + `" data-inverted="" data-tooltip="Editar" data-position="top center">
                                            <i class="large edit icon" acc tipo="a" data-id="` + data + `"></i>
                                        </button>
                                    </div>
                                </div>`;
                    }
                }
            ]
        };
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

    ngOnDestroy(): void {
        this.oyente();
    }
}