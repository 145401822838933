﻿import { Servicio } from "./servicio";
import { Configuracion } from "../entidades/indice";
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioConfiguracion extends Servicio<Configuracion> {
    constructor(http: HttpClient) {
        super(http, "configuracion");
    }
}