﻿import { Servicio } from "./servicio";
import { Correo, Corporativo } from "../entidades/indice";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioCorporativo extends Servicio<Corporativo> {

    constructor(http: HttpClient) {
        super(http, "corporativos");
    }
}