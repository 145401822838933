﻿import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion, Notificacion } from '../../compartida/indice';
import { Archivo } from '../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'tabla-archivos',
    templateUrl: 'tabla.component.html'
})
export class TablaCatalogoArchivos implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Archivo> = new Subject();
    @Output() xmlOprimido: EventEmitter<number> = new EventEmitter<number>();
    @Output() pdfOprimido: EventEmitter<number> = new EventEmitter<number>();
    private oyente: () => void;
    archivos: Archivo[];

    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit(): void {
        this.cargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();

        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let nombre = objetivo.getAttribute('data-nombre');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'xml') {
                    this.descargarXml(id, nombre);
                }  
                if (tipo == 'pdf') {
                    this.descargarPdf(id, nombre);
                } 

                if (tipo == 'oc') {
                    this.servicios
                        .archivos
                        .cancelarOrdenCompra(id)
                        .subscribe(
                            respuesta => {
                                console.log(respuesta);
    
                                if (respuesta.ok) {
                                    this.notificacion.mostrarExito("Orden de compra cancelada");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                    });
    
                                } else {
                                    this.notificacion.mostrarExito("Error al cancelar la orden de compra.");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                       
                                    });
                                }
                            }, error => {
                                let httpError = <HttpErrorResponse>error;
                                this.notificacion.mostrarError(httpError.error);
                            });
                }
            }

            
         
        });
    }

    descargarPdf(id: number, nombre: string): void {
        let observable = this.servicios
            .archivos
            .descargarPdf(id)
            .subscribe(
                pdf => {
                    let a = document.createElement("a");
                    let blob = new Blob([pdf], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".pdf";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    descargarXml(id: number, nombre: string): void {
        let observable = this.servicios
            .archivos
            .descargarXml(id)
            .subscribe(
                xml => {
                    let a = document.createElement("a");
                    let blob = new Blob([xml], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".xml";
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: { url: '/js/espanol.json' },
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .archivos
                    .obtenerTodos()
                    .subscribe(archivos => {
                        this.archivos = archivos;
                        console.log('archivos-->', this.archivos);
                        callback({ data: archivos });
                    });
            },
            columns: [
                { title: 'Id', data: 'idArchivo', visible: false },
                { title: 'Sociedad', data: 'sociedadNombreComercial' },
                { title: 'Nombre', width: "20%", data: 'nombreArchivo' },
                { title: 'Fecha de registro', data: 'fechaRegistro', render: (data: string) => (new Date(data)).toLocaleDateString() },
                { title: 'Orden de compra', data: 'numeroOrdenCompra' },
                { title: 'Fecha probable de pago', data: 'fechaPago', render: (data: string) => data != null ? (new Date(data)).toLocaleDateString() : "" },
                { title: 'Estatus', data: 'autorizado' },
                { title: 'UUID Complemento', data: 'uuidComplemento' },
                { title: 'Total', width: "10%", data: 'total', render: (data: string) => (new String("$ " + data)) },
                {
                    title: 'Cancelar',
                    data: 'idArchivo',
                    width: "7%",
                    render: (data: any, type: any, full: Archivo) => {

                        if(full.fechaAutorizo != null || full.autorizado === "Autorizado" || full.autorizado === "Pagado")
                        {
                            return `<div class="ui icon buttons">
                                        <button class="ui basic button" acc tipo="oc" data-id="` + full.idArchivo + `" data-inverted="" data-tooltip="No es posible cancelar" data-position="top center" disabled>
                                            <i class="check square icon orange" acc tipo="oc" data-id="` + full.idArchivo + `" style="font-size:22px; margin-left:20px !important;"></i>
                                        </button>
                                    </div>`;
                        }else{
                            return `<div class="ui icon buttons">
                                        <button class="ui basic button" acc tipo="oc" data-id="` + full.idArchivo + `" data-inverted="" data-tooltip="Cancelar" data-position="top center">
                                            <i class="check square icon orange" acc tipo="oc" data-id="` + full.idArchivo + `" style="font-size:22px; margin-left:20px !important;"></i>
                                        </button>
                                    </div>`;
                        }
                    }
                },
                {
                    title: 'Opciones',
                    data: 'idArchivo',
                    width: "15%",
                    render: (data: any, type: any, full: Archivo) => {
                        return `<div class="ui icon buttons">
                                    <button class="ui orange basic button" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombreArchivo + `" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
                                        <i class="large file excel outline icon" acc tipo="xml" data-id="` + data + `" data-nombre="` + full.nombreArchivo + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombreArchivo + `" data-inverted="" data-tooltip="Descargar PDF" data-position="top center">
                                       <i class="large file pdf outline icon" acc tipo="pdf" data-id="` + data + `" data-nombre="` + full.nombreArchivo + `"></i>
                                    </button>
                                </div>`;
                    }
                }
            ]
        };
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });

        //this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //    dtInstance.ajax.reload();
        //});

    }


    ngOnDestroy(): void {
        this.oyente();
    }
}