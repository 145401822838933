﻿import { SuiModalService, TemplateModalConfig, ModalTemplate } from 'ng2-semantic-ui';
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';

export interface confirmConfig {
    titulo: string,
    texto: string,
    textoOk: string,
    textoCancelar: string,
    accionOk?: Function
} 

@Component({
    selector: 'confirmacion',
    templateUrl: 'confirmacion.component.html'
})
export class Confirmacion {
    @ViewChild('modalTemplate')
    public modalTemplate: ModalTemplate<confirmConfig, string, string>
    @Output() aprovado: EventEmitter<string> = new EventEmitter<string>();

 
    constructor(public modalService: SuiModalService) { }

    public open(confirmConfig: confirmConfig) {
        const config = new TemplateModalConfig<confirmConfig, string, string>(this.modalTemplate);
        config.context = confirmConfig;
        config.size = "mini";
        this.modalService
            .open(config)
            .onApprove(result => 
                { 
                    if(confirmConfig.accionOk)
                    {
                        confirmConfig.accionOk(); 
                    } 
                    this.aprovado.emit(); 
                })
            .onDeny(result => { /* deny callback */ });
    }

    private articuloSeleccionado($event){
        this.aprovado.emit($event);
    }
}

 