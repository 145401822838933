import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ModalTemplate, SuiModalService, TemplateModalConfig } from 'ng2-semantic-ui';
import { ActiveModal } from 'ng2-semantic-ui/dist/modules/modal/classes/active-modal';
import { DetalleArchivo } from '../../entidades/detalle-archivo';
import { OPRC } from '../../entidades/oprc';
import { OPRJ } from '../../entidades/oprj';
import { Servicios } from '../../servicios/servicios';
import { FormaModal } from '../formaModal';

class opcionSelect {
	codigo: string;
	descripcion: string;
}

@Component({
	selector: 'app-articulos-sap',
	templateUrl: './articulos-sap.component.html',
	styleUrls: ['./articulos-sap.component.css'],
})
export class ArticulosSapComponent implements OnInit {
	@ViewChild('modalArticuloSap') modalTemplate: ModalTemplate<
		void,
		void,
		void
	>;
	@Input() archivoId: number;
	sociedadRFC: string;
	private ventana: ActiveModal<void, {}, void>;
	detalles: DetalleArchivo[] = [];
	centroCostos: string;
	proyecto: string;
	articulosSap: opcionSelect[];
	centrosCostos: opcionSelect[];
	proyectos: opcionSelect[];

	@Output() articuloSeleccionado: EventEmitter<{detalleArchivo: DetalleArchivo[], centroCostos: string, proyecto: string}> =
		new EventEmitter<{detalleArchivo: DetalleArchivo[], centroCostos: string, proyecto: string}>();

	get hayArticulosSinSeleccionar(): boolean {
		if(this.detalles.length > 0){
			return this.detalles.filter(d => !d.codigoArticuloSAP).length > 0;
		}
	}

	get formularioInvalido(): boolean{
		let invalido = false;
		if(this.detalles.length > 0){
			invalido = invalido || this.detalles.filter(d => !d.codigoArticuloSAP).length > 0 
			|| this.detalles.filter(d => !d.codigoProyectoSap).length > 0
			|| this.detalles.filter(d => !d.codigoCentroCostosSap).length > 0;

		}else{
			invalido = true;
		}
		// invalido = invalido || !this.proyecto;
		// invalido = invalido || !this.centroCostos;
		
		return invalido;
	}

	constructor(
		public modalService: SuiModalService,
		private servicios: Servicios
	) {
	}

	ngOnInit() {
	}

	cargarDetallesArticulo(){
		this.servicios.archivos.obtenerDetallesArchivo(this.archivoId).subscribe(ds => {
			if(ds.length){
				this.detalles = ds;
			}
		});
	}

	cargarCentrosCosto(){
		this.servicios.servicioSap.obtenerCentrosCostos(this.sociedadRFC).subscribe((centros) => {
			this.centrosCostos = centros.map((c) => {
				return {
					codigo: c.prcCode,
					descripcion: `${c.prcCode} ${c.prcName}`,
				};
			});
		});
	}

	cargarProyectos(){
		this.servicios.servicioSap.obtenerProyectos(this.sociedadRFC).subscribe((proyectos) => {
			this.proyectos = proyectos.map((p) => {
				return {
					codigo: p.prjCode,
					descripcion: p.prjCode,
				};
			});
		});
	}

	public cargarArticulosSap(): void {
		this.servicios.servicioSap.obtenerArticulos(this.sociedadRFC).subscribe((articulos) => {
			this.articulosSap = articulos.map((a) => {
				return {
					codigo: a.itemCode,
					descripcion: `${a.itemCode} ${a.itemName}`,
				};
			});
		});
	}

	limpiar(){
		this.centroCostos = null;
		this.proyecto = null;
	}

	public abrir(archivoId: number, sociedadRFC: string): void {
		const config = new TemplateModalConfig<void, void, void>(
		  this.modalTemplate
		);
	
		config.size = 'small';
		config.isClosable = false;

		this.limpiar();

		this.ventana = this.modalService.open(config);
		this.archivoId = archivoId;
		this.sociedadRFC = sociedadRFC;
		this.cargarArticulosSap();
		this.cargarCentrosCosto();
		this.cargarProyectos();
		this.cargarDetallesArticulo();
	  }
	
	  protected seleccionar() {
		this.articuloSeleccionado.emit({detalleArchivo: this.detalles, proyecto: this.proyecto, centroCostos: this.centroCostos});
		if (this.ventana) {
		  this.ventana.destroy();
		}
	  }
}
