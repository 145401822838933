﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormaSociedad } from './forma.component';
import { Sociedad } from '../../entidades/indice';
import { TablaCatalogoSociedad } from './tabla.component';

@Component({
    selector: "catalogo-sociedad",
    templateUrl: "catalogo.component.html"
})
export class CatalogoSociedad implements OnDestroy {
    @ViewChild(FormaSociedad) formaSociedad: FormaSociedad;
    @ViewChild(TablaCatalogoSociedad) tablaCatalogo: TablaCatalogoSociedad;

    titulo: string = "Sociedades";

    nuevo(): void {
        this.formaSociedad.abrirNuevo();
    }

    editar(sociedadEvento: Sociedad): void {
        this.formaSociedad.sociedad = sociedadEvento;
        this.formaSociedad.abrirEditar();
    }

    recargarTabla(): void {
        this.tablaCatalogo.recargarTabla();
    }

    ngOnDestroy(): void {
        this.formaSociedad.cancelar();
    }
}