import {
	Component,
	Renderer2,
	ViewChild,
	Output,
	EventEmitter,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Observable, Subscription } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Servicios, ServicioArchivos } from '../../servicios/indice';
import { forEach } from '@angular/router/src/utils/collection';
import { Archivo, Proveedor, Sociedad } from '../../entidades/indice';
import { Notificacion, Confirmacion } from '../../compartida/indice';
import { DatepickerMode, PopupPlacement } from 'ng2-semantic-ui';
import { MascaraMoneda } from '../../compartida/mascara-moneda';

@Component({
	selector: 'app-reparchivos',
	templateUrl: './reparchivos.component.html',
	styleUrls: ['./reparchivos.component.css'],
})
export class ReparchivoSinComplementoComponent implements OnInit, OnDestroy {
	idSeleccionado: number;
	Procesando: boolean;
	@Output() xmlOprimido: EventEmitter<Archivo> = new EventEmitter<Archivo>();
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild(Confirmacion) confirmacion: Confirmacion;

	titulo: string = 'Facturas vs Complemento pago';
	private oyente: () => void;

	dtOptions: DataTables.Settings = {};
	dtTrigger: Subject<Archivo> = new Subject();
	modoFecha = DatepickerMode.Date;
	conPago = false;
	sinPago = false;

	archivos: Archivo[];
	archivo: Archivo;
	proveedores: Proveedor[] = [];
	sociedades: Sociedad[] = [];
	tipoBusqueda = 0;
	mostrarDatos1: boolean;
	mostrarDatos2: boolean;
	mostrarDatos3: boolean;

	val: number;
	posicionCalendario: PopupPlacement = PopupPlacement.Bottom;

	constructor(
		private servicios: Servicios,
		private renderer: Renderer2,
		private notificacion: Notificacion
	) {}

	ngOnInit(): void {
		this.archivo = new Archivo();
		this.cargarTabla();
		this.cargarProveedores();
		this.cargarSociedades();
	}

	cargarProveedores() {
		this.servicios.proveedores.ObtenerProveedores().subscribe((lista) => {
			this.proveedores = lista;
		});
	}

	cargarSociedades() {
		this.servicios.sociedades.obtenerTodos().subscribe((lista) => {
			this.sociedades = lista;
		});
	}

	actdvFecha1(valor): void {
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;

		if (valor == 1) {
			this.mostrarDatos1 = true;
			this.mostrarDatos2 = false;
			this.mostrarDatos3 = false;
			this.val = valor;
		}
		if (valor == 2) {
			this.mostrarDatos1 = false;
			this.mostrarDatos2 = true;
			this.mostrarDatos3 = false;
			this.val = valor;
		}
		if (valor == 3) {
			this.mostrarDatos1 = false;
			this.mostrarDatos2 = false;
			this.mostrarDatos3 = true;

			this.val = valor;
		}
		this.recargarTabla();
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
		this.renderer.destroy();
		this.oyente = this.renderer.listen('body', 'click', (event) => {
			let objetivo = <HTMLElement>event.target;
			if (objetivo.hasAttribute('acc')) {
				let tipo = objetivo.getAttribute('tipo');
				let nombre = objetivo.getAttribute('data-nombre');
				const dataId = objetivo.getAttribute('data-id');
				let id = 0;
				if (dataId) {
					id = parseInt(dataId);
				}
				this.idSeleccionado = id;
				if (tipo == 'xml') {
					this.descargarXml(id, nombre);
				}
				if (tipo == 'pdf') {
					this.descargarPdf(id, nombre);
				}
				if (tipo == 'pdfOC') {
					this.descargarOC(id, nombre);
				}
				if (tipo == 'autorizo') {
					this.preguntarSiAutorizo();
				}
				if (tipo == 'programado') {
					this.pagoProgramado();
				}
				if (tipo == 'pdfPE') {
					this.descargarPE(id, nombre);
				}
				if (tipo == 'xmlPago') {
					this.descargarXmlComplemento(objetivo.getAttribute('data-uuid'));
				}
				if (tipo == 'pdfPago') {
					this.descargarPDFComplemento(objetivo.getAttribute('data-uuid'));
				}
			}
		});
	}

	pagoProgramado() {
		if (this.archivo.fechaPago == null) {
			this.notificacion.mostrarAdvertencia(
				'Seleccion una fecha para realizar el pago programado.'
			);
			return;
		}
		this.archivo.idArchivo = this.idSeleccionado;
		this.servicios.archivos.pagoFacturaFA(this.archivo).subscribe(
			(autorizo) => {
				let auto = autorizo.fechaPago;
				if (auto != null) {
					this.notificacion.mostrarExito('Factura pagada.');
					this.recargarTabla();
				} else {
					this.notificacion.mostrarError(
						'Error! La factura no ha sido pagada.'
					);
				}
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});
			},
			(error) => {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});

				let httpError = <HttpErrorResponse>error;
				this.notificacion.mostrarError(
					'Error al pagar la factura verifique nuevamente'
				);
			}
		);
	}

	preguntarSiAutorizo(): void {
		this.confirmacion.open({
			texto: '�Esta seguro en autorizar el documento?',
			textoCancelar: 'No',
			textoOk: 'Si',
			titulo: 'Confirmaci�n',
		});
	}

	PagoAutorizacion() {
		this.archivo.idArchivo = this.idSeleccionado;
		this.servicios.archivos.pagoFacturaCA(this.archivo).subscribe(
			(autorizo) => {
				let auto = autorizo.fechaAutorizo;
				if (auto != null) {
					this.notificacion.mostrarExito('Factura autorizada.');
					this.recargarTabla();
				} else {
					this.notificacion.mostrarError(
						'Error! La factura no ha sido autorizada.'
					);
				}
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});
			},
			(error) => {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});

				let httpError = <HttpErrorResponse>error;
				this.notificacion.mostrarError(
					'Error al autorizar la factura la factura verifique nuevamente'
				);
			}
		);
	}

	descargarOC(id: number, nombre: string): void {
		let observable = this.servicios.ordenCompra
			.descargarPdf(id)
			.subscribe((pdf) => {
				let a = document.createElement('a');
				let blob = new Blob([pdf], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = nombre + '.pdf';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarPdf(id: number, nombre: string): void {
		let observable = this.servicios.pagos.descargarPdf(id).subscribe((pdf) => {
			let a = document.createElement('a');
			let blob = new Blob([pdf], { type: 'octet/stream' });
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = nombre + '.pdf';
			document.body.appendChild(a);
			a.click();
			setTimeout(() => {
				a.remove();
				window.URL.revokeObjectURL(url);
			}, 100);
		});
	}

	descargarXmlComplemento(uuid: string): void {
		let observable = this.servicios.pagos
			.descargarXml(uuid)
			.subscribe((xml) => {
				let a = document.createElement('a');
				let blob = new Blob([xml], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = uuid + '.xml';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarPDFComplemento(uuid: string): void {
		let observable = this.servicios.pagos
			.descargarPdf(uuid)
			.subscribe((pdf) => {
				let a = document.createElement('a');
				let blob = new Blob([pdf], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = uuid + '.pdf';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarXml(id: number, nombre: string): void {
		let observable = this.servicios.archivos
			.descargarXml(id)
			.subscribe((xml) => {
				let a = document.createElement('a');
				let blob = new Blob([xml], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = nombre + '.xml';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarPE(id: number, nombre: string): void {
		let observable = this.servicios.ordenCompra
			.descargarPdfPE(id)
			.subscribe((pdf) => {
				for (const element of pdf) {
					let pdf = element;
					const byteArray = new Uint8Array(
						atob(pdf.pdfFile)
							.split('')
							.map((char) => char.charCodeAt(0))
					);
					let a = document.createElement('a');
					let blob = new Blob([byteArray], { type: 'octet/stream' });
					let url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = pdf.nombre;
					document.body.appendChild(a);
					a.click();
					setTimeout(() => {
						a.remove();
						window.URL.revokeObjectURL(url);
					}, 100);
				}
			});
	}

	cargarTabla(): void {
		var n = 0;
		this.dtOptions = {
			language: { url: '/js/espanol.json' },
			searching: false,
			ajax: (dataTablesParameters: any, callback) => {
				if (
					(this.sinPago && this.conPago) ||
					(!this.sinPago && !this.conPago)
				) {
					this.servicios.archivos
						.obtenerReporte(this.archivo)
						.subscribe((lista) => {
							this.archivos = lista;
							callback({ data: lista });
						});
					this.Procesando = false;
				} else if (this.conPago) {
					this.servicios.archivos
						.obtenerReporteConComplementos(this.archivo)
						.subscribe((lista) => {
							this.archivos = lista;
							callback({ data: lista });
						});
					this.Procesando = false;
				} else if (this.sinPago) {
					this.servicios.archivos
						.obtenerReporteSinComplementos(this.archivo)
						.subscribe((lista) => {
							this.archivos = lista;
							callback({ data: lista });
						});
					this.Procesando = false;
				}
			},
			columns: [
				{ title: '', data: 'idArchivo', visible: false },
				{ title: '', data: 'idProveedor', visible: false },
				{ title: 'Proveedor', data: 'proveedorRazonSocial' },
				{ title: 'OC', data: 'numeroOrdenCompra' },
				{ title: 'Código identificador x UUID', data: 'nombreArchivo' },
				{ title: 'UUID Pago', data: 'nombreArchivoPago' },
				{
					title: 'Fecha de alta ',
					data: 'fechaRegistro',
					render: (data: string) => new Date(data).toLocaleDateString(),
				},
				{
					title: 'Fecha de pago ',
					data: 'fechaPago',
					render: (data: string) => new Date(data).toLocaleDateString(),
				},
				{
					title: 'Total',
					data: 'total',
					width: '15%',
					render: (data: number) =>
						data != null ? MascaraMoneda.format(data) : '$ 0.00',
				},
				{ title: 'Moneda', data: 'moneda' },
				{
					title: 'Opciones',
					data: 'nombreArchivoPago',
					width: '15%',
					render: (data: any, type: any, full: Archivo) => {
						return (
							`<div class="ui icon buttons">
                                            <button class="ui orange basic button" acc tipo="xmlPago" data-uuid="` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
                                                <i class="large file excel outline icon" acc tipo="xmlPago" data-uuid="` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`"></i>
                                            </button>
                                            <button class="ui orange basic button" acc tipo="pdfPago" data-uuid="` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`" data-inverted="" data-tooltip="Descargar PDF Factura" data-position="top center">
                                               <i class="large file pdf outline icon" acc tipo="pdfPago" data-uuid="` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`"></i>
                                            </button>
                                        </div>`
						);
					},
				},
			],
		};
	}

	buscar(): void {
		this.Procesando = true;
		this.recargarTabla();
		this.Procesando = false;
	}

	limpiar(): void {
		this.archivo.nombreArchivo = null;
		this.archivo.proveedorRazonSocial = null;
		this.archivo.sociedadNombreComercial = null;
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.archivo.fechaPagoDesde = null;
		this.archivo.fechaPagoHasta = null;
		this.archivo.fechaPago = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;

		this.tipoBusqueda = 0;
		this.val = 0;

		this.mostrarDatos1 = false;
		this.mostrarDatos2 = false;
		this.mostrarDatos3 = false;

		this.recargarTabla();
	}

	limpiarFiltroAutorizacion(): void {
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.recargarTabla();
	}

	limpiarFiltroPago(): void {
		this.archivo.fechaPagoDesde = null;
		this.archivo.fechaPagoHasta = null;
		this.recargarTabla();
	}

	limpiarFiltroPorAutorizar(): void {
		this.archivo.fechaPago = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;
	}

	reporteExcel(): void {
		let observable: Observable<Blob>;
		if ((this.sinPago && this.conPago) || (!this.sinPago && !this.conPago)) {
			observable = this.servicios.archivos.obtenerReporteExcel(this.archivo);
		} else if (this.sinPago) {
			observable = this.servicios.archivos.obtenerReporteExcelSinComplemento(
				this.archivo
			);
		} else if (this.conPago) {
			observable = this.servicios.archivos.obtenerReporteExcelConComplemento(
				this.archivo
			);
		}

		observable.subscribe((xls) => {
			let a = document.createElement('a');
			let blob = new Blob([xls], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			});
			let url = window.URL.createObjectURL(blob);
			a.href = url;
			a.download = 'Reporte Archivos' + '.xlsx';
			a.click();
			window.URL.revokeObjectURL(url);
		});
	}

	public recargarTabla(): void {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.ajax.reload();
		});
	}

	ngOnDestroy(): void {
		this.oyente();
	}
}
