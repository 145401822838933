import {
	Component,
	Renderer2,
	ViewChild,
	Output,
	EventEmitter,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Servicios, ServicioArchivos } from '../../servicios/indice';
import { Archivo, Proveedor, Sociedad } from '../../entidades/indice';
import { Notificacion, Confirmacion } from '../../compartida/indice';
import { DatepickerMode, PopupPlacement } from 'ng2-semantic-ui';
import { ArchivoDescargaMasiva } from '../../entidades/archivo-descarga-masiva';
import { ArticulosSapComponent } from '../../compartida/articulos-sap/articulos-sap.component';
import { DetalleArchivo } from '../../entidades/detalle-archivo';
import { MascaraMoneda } from '../../compartida/mascara-moneda';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { ExportToXlsxService } from 'ClientApp/app/servicios/exportToXls';
import { NgxSpinnerService } from 'ngx-spinner';
import { first } from 'rxjs/internal/operators/first';

{ }

@Component({
	selector: 'app-reparchivos',
	templateUrl: './reparchivos.component.html',
	styleUrls: ['./reparchivos.component.css'],
})
export class ReparchivosComponent implements OnInit, OnDestroy {
	idSeleccionado: number;
	Procesando: boolean;
	BusquedaG: boolean;
	aplicaPruebasEntregaSoc: boolean;
	proveedorMonedaCorrecto: boolean;
	importeCorrectoPedidoUSD: boolean;
	importeCorrectoPedidoMXP: boolean;
	documentoEnSap: boolean;
	Activo: boolean = false;
	@Output() xmlOprimido: EventEmitter<Archivo> = new EventEmitter<Archivo>();
	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
	@ViewChild(Confirmacion) confirmacion: Confirmacion;
	@ViewChild(ArticulosSapComponent) articuloSap: ArticulosSapComponent;

	titulo: string = 'Facturas';
	private oyente: () => void;

	dtOptions: DataTables.Settings = {};
	dtOptions2: DataTables.Settings = {};

	dtTrigger: Subject<Archivo> = new Subject();
	dtTrigger2: Subject<Archivo> = new Subject();

	modoFecha = DatepickerMode.Date;

	archivos: Archivo[];
	archivo: Archivo;
	proveedores: Proveedor[] = [];
	sociedades: Sociedad[] = [];
	tipoBusqueda = null;
	mostrarDatos1: boolean;
	mostrarDatos2: boolean;
	mostrarDatos3: boolean;
	mostrarDatos4: boolean;
	mostrarDatos5: boolean;
	archivosXml: number[] = [];
	archivosPdf: number[] = [];
	val: number;


	posicionCalendario: PopupPlacement = PopupPlacement.Bottom;
	cargaMasiva: ArchivoDescargaMasiva = new ArchivoDescargaMasiva();
	datosSap: { detalleArchivo: DetalleArchivo[], centroCostos: string, proyecto: string };
	constructor(
		private servicios: Servicios,
		private renderer: Renderer2,
		private notificacion: Notificacion,
		public datepipe: DatePipe,
		private exportToXlsxService: ExportToXlsxService,
		public spinner: NgxSpinnerService
	) { }

	ngOnInit(): void {
		this.archivo = new Archivo();

		this.tipoBusqueda = '5';
		this.val = 5;
		this.mostrarDatos1 = false;
		this.mostrarDatos2 = false;
		this.mostrarDatos3 = false;
		this.mostrarDatos4 = true;
		this.mostrarDatos5 = false;

		this.establecerFechasMes();
		this.cargarTabla();
		this.cargarProveedores();
		this.cargarSociedades();
	}

	establecerFechasMes() {
		// Obtener la fecha actual
		const fechaActual = new Date();

		// Obtener el primer día del mes actual
		const primerDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
		const inicioMes = primerDiaMes.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

		//OBTENER EL DIA ACTUAL
		const diaActual = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());
		const ultimoDia = diaActual.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })

		//OBTENER EL DIA ACTUAL MENOS 30 DIAS 
		const fechaActualmMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() - 1, fechaActual.getDate())

		// Obtener el último día del mes actual
		const ultimoDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
		const finMes = ultimoDiaMes.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

		// Imprimir los resultados
		console.log('Inicio del mes:', inicioMes);
		console.log('Fin del mes:', finMes);
		console.log('Hoy', ultimoDia);
		console.log('Fecha actual', fechaActual);


		this.archivo.fechaRegistroDesde = fechaActualmMes;
		this.archivo.fechaRegistroHasta = diaActual;
	}

	cargarProveedores() {
		this.servicios.proveedores.ObtenerProveedores().subscribe((lista) => {
			this.proveedores = lista;
		});
	}

	cargarSociedades() {
		this.servicios.sociedades.obtenerTodos().subscribe((lista) => {
			this.sociedades = lista;
		});
	}

	actdvFecha1(valor): void {
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;
		this.archivo.autorizado = null;
		this.archivo.fechaRecepcionDesde = null;
		this.archivo.fechaRecepcionHasta = null;
		//this.archivo.autorizado = null;

		if (valor == 1) {
			this.mostrarDatos1 = true;
			this.mostrarDatos2 = false;
			this.mostrarDatos3 = false;
			this.mostrarDatos4 = false;
			this.mostrarDatos5 = false;

			this.val = valor;

			this.archivo.pagado = 'Y';

		}
		if (valor == 2) {
			this.mostrarDatos1 = false;
			this.mostrarDatos2 = true;
			this.mostrarDatos3 = false;
			this.mostrarDatos4 = false;
			this.mostrarDatos5 = false;

			this.val = valor;

		}
		if (valor == 3) {
			this.mostrarDatos1 = false;
			this.mostrarDatos2 = false;
			this.mostrarDatos3 = true;
			this.mostrarDatos4 = false;
			this.mostrarDatos5 = false;

			this.val = valor;
			this.archivo.pagado = 'N';
		} if (valor == 4) {
			this.archivo.autorizado = 'N';
		} if (valor == 5) {
			this.mostrarDatos1 = false;
			this.mostrarDatos2 = false;
			this.mostrarDatos3 = false;
			this.mostrarDatos4 = true;
			this.mostrarDatos5 = false;

			this.val = valor;
		}
		if (valor == 6) {
			this.archivo.autorizado = 'Y';
			this.archivo.procesadaEnSap = -1;
		}
		//this.recargarTabla();
		console.log('archivo-->', this.archivo);
	}

	ngAfterViewInit(): void {
		this.dtTrigger.next();
		this.renderer.destroy();
		this.oyente = this.renderer.listen('body', 'click', (event) => {
			let objetivo = <HTMLElement>event.target;
			if (objetivo.hasAttribute('acc')) {
				let tipo = objetivo.getAttribute('tipo');
				let nombre = objetivo.getAttribute('data-nombre');
				let id = parseInt(objetivo.getAttribute('data-id'));
				this.idSeleccionado = id;
				if (tipo == 'xml') {
					this.descargarXml(id, nombre);
				}
				if (tipo == 'pdf') {
					this.descargarPdf(id, nombre);
				}
				if (tipo == 'pdfOC') {
					this.descargarOC(id, nombre);
				}
				if (tipo == 'rechazar') {
					this.rechazarFactura(id);
				}
				if (tipo == 'autorizo') {
					this.evaluarSolicitarArticuloSap();
				}
				if (tipo == 'programado') {
					this.validoFecha_Autorizo_Recepcion(id);
				}
				if (tipo == 'pdfPE') {
					this.descargarPE(id, nombre);
				}
				if (tipo == 'selecXml') {
					if ((<HTMLInputElement>objetivo).checked) {
						this.archivosXml.push(parseInt((<HTMLInputElement>objetivo).id));
					} else {
						this.archivosXml.forEach((element, index) => {
							if (element == parseInt((<HTMLInputElement>objetivo).id))
								this.archivosXml.splice(index, 1);
						});
					}
				}
				if (tipo == 'selecPdf') {
					if ((<HTMLInputElement>objetivo).checked) {
						this.archivosPdf.push(parseInt((<HTMLInputElement>objetivo).id));
					} else {
						this.archivosPdf.forEach((element, index) => {
							if (element == parseInt((<HTMLInputElement>objetivo).id))
								this.archivosPdf.splice(index, 1);
						});
					}
				}
			}
		});
	}

	async validoFecha_Autorizo_Recepcion(id: any) {
		let tieneRecepcion = await this.tieneFechaRecepcion(id)
		let tieneAutorizacion = await this.tieneFechaAutorizacion(id)
		if (!tieneAutorizacion) {
			this.notificacion.mostrarAdvertencia(
				'No es posible asignar fecha probable de pago, aún no ha sido autorizada la factura'
			);
			return;
		} else if (!tieneRecepcion) {
			this.notificacion.mostrarAdvertencia(
				'No es posible asignar fecha probable de pago, aún no cuenta con recepcion la factura'
			);
			return;
		} else {
			this.pagoProgramado();
		}
	}

	async tieneFechaAutorizacion(id): Promise<boolean> {
		return this.servicios.archivos.tieneAutorizacion(id).toPromise();
	}

	async tieneFechaRecepcion(id): Promise<boolean> {
		return this.servicios.archivos.tieneRecepcion(id).toPromise();
	}

	pagoProgramado() {
		if (this.archivo.fechaPago == null) {
			this.notificacion.mostrarAdvertencia(
				'Seleccione una fecha para realizar el pago programado.'
			);
			return;
		}
		this.archivo.idArchivo = this.idSeleccionado;
		this.servicios.archivos.pagoFacturaFA(this.archivo).subscribe(
			(autorizo) => {
				let auto = autorizo.fechaPago;
				if (auto != null) {
					this.notificacion.mostrarExito('Factura pagada.');
					this.recargarTabla();
				} else {
					this.notificacion.mostrarError(
						'Error! La factura no ha sido pagada.'
					);
				}
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});
			},
			(error) => {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});

				let httpError = <HttpErrorResponse>error;
				this.notificacion.mostrarError(
					'Error al pagar la factura verifique nuevamente'
				);
			}
		);
	}

	preguntarSiAutorizo(datosSap?: { detalleArchivo: DetalleArchivo[], centroCostos: string, proyecto: string }): void {
		this.confirmacion.open({
			texto: '¿Esta seguro en autorizar el documento?',
			textoCancelar: 'No',
			textoOk: 'Si',
			titulo: 'Confirmación',
		});
		console.log('pregunto si autorizo');
		this.datosSap = datosSap;
	}

	async evaluarSolicitarArticuloSap() {
		let arch = this.archivos.find((a) => a.idArchivo == this.idSeleccionado);
		
		//VALIDACION DE PROVEEDOR,MONEDA Y PEDIDO EN SAP SOLO PARA ARCHIVOS CON ORDEN DE COMPRA
		if (arch.numeroOrdenCompra) {

			let folio = arch.numeroOrdenCompra;

			//VALIDO SI EL PEDIDO ESTA EN SAP
			this.documentoEnSap = await this.servicios.archivos.PedidoEnSap(folio);

			//SI EL PEDIDO SE ENCUENTRA EN SAP --> VALIDO PROVEEDOR Y MONEDA
			if (this.documentoEnSap) {
				this.proveedorMonedaCorrecto = await this.servicios.archivos.ProveedorMoneda(folio);
				this.importeCorrectoPedidoUSD = await this.servicios.archivos.ImportePedidoUSD(folio);
				this.importeCorrectoPedidoMXP = await this.servicios.archivos.ImportePedidoMXP(folio);


				///VALIDAR SI TIENE RETENCION O NO COINCIDE AL AUTORIZAR
			}
		}

		
		/*this.servicios.sociedades.obtener(arch.idSociedad).pipe(first())
			.subscribe(async ss => {
				if (ss && ss.aplicaPruebasEntrega) {
					this.aplicaPruebasEntregaSoc = await this.servicios.proveedores.aplicaPruebasEntregaProv(arch.idProveedor);
				} else {
					this.aplicaPruebasEntregaSoc = false;
				}
			});*/

		//OBTENGO SI LA SOCIEDAD APLICA PRUEBAS DE ENTREGA
		try{
			const ss = await this.servicios.sociedades.obtener(arch.idSociedad).pipe(first()).toPromise();
			if(ss && ss.aplicaPruebasEntrega){
				this.aplicaPruebasEntregaSoc = await this.servicios.proveedores.aplicaPruebasEntregaProv(arch.idProveedor);
			}else{
				this.aplicaPruebasEntregaSoc = false;
			}
			console.log("Aplica estado: " + this.aplicaPruebasEntregaSoc)
		}catch(e){
			console.log(e);
		}

		if (arch.fechaRecepcion == null) {
			this.notificacion.mostrarError("Los archivos de la factura aún no han sido revisados");
		} else {
			if (this.aplicaPruebasEntregaSoc && !await this.pruebasEntregaAceptadas(arch)) {
				var usuarioResponsable = arch.numeroOrdenCompra ? await this.usuarioResponsable(arch) : null;
				this.notificacion.mostrarError(
					`Pruebas de entrega no han sido autorizadas ${usuarioResponsable ? "por " + usuarioResponsable : ''} es necesario rechazar la factura`
				);
			} else {
				if (!arch.numeroOrdenCompra) {
					this.articuloSap.abrir(this.idSeleccionado, arch.sociedadRFC);
				} else {

					if (this.documentoEnSap == true) {
						if (this.proveedorMonedaCorrecto == true) {
							debugger
							if (arch.moneda == 'USD' || arch.moneda == "") {
								if (this.importeCorrectoPedidoUSD == true) {
									this.preguntarSiAutorizo();
								} else {
									this.notificacion.mostrarError("Importe de OC no coincide con importe del pedido en SAP")
								}
							} else {
								if (this.importeCorrectoPedidoMXP == true) {
									this.preguntarSiAutorizo();
								} else {
									this.notificacion.mostrarError("Importe de OC no coincide con importe del pedido en SAP")
								}
							}

						} else {
							this.notificacion.mostrarError("Proveedor o moneda no coincide con la del pedido en SAP")
						}
					} else {
						this.notificacion.mostrarError("El pedido no se encuentra en SAP")
					}
				}
			}
		}
	}

	PagoAutorizacion() {
		this.archivo.idArchivo = this.idSeleccionado;

		//Los datos SAP (Proyecto, centro de costos y artículos) no siempre aplican. Depende de si es una factura con orden de compra o no. 
		//Solo las que no tienen orden de compra, llevan datosSap.
		if (this.datosSap != undefined) {
			this.archivo.detalles = this.datosSap.detalleArchivo;
			this.archivo.codigoCentroCostosSap = this.datosSap.centroCostos;
			this.archivo.codigoProyectoSap = this.datosSap.proyecto;
		}

		this.servicios.archivos.pagoFacturaCA(this.archivo).subscribe(
			(autorizo) => {
				let auto = autorizo.fechaAutorizo;
				if (auto != null) {
					this.notificacion.mostrarExito('Factura autorizada.');
					this.recargarTabla();
				} else {
					this.notificacion.mostrarError(
						'Error! La factura no ha sido autorizada.'
					);
				}
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});
			},
			(error) => {
				this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
					dtInstance.ajax.reload();
				});

				let httpError = <HttpErrorResponse>error;
				this.notificacion.mostrarError(
					'Error al autorizar la factura la factura verifique nuevamente'
				);
			}
		);
	}

	descargarOC(id: number, nombre: string): void {
		let observable = this.servicios.ordenCompra
			.descargarPdf(id)
			.subscribe((pdf) => {
				let a = document.createElement('a');
				let blob = new Blob([pdf], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = nombre + '.pdf';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	async descargaMasiva(): Promise<void> {
		this.cargaMasiva.archivosPdf = this.archivosPdf;
		this.cargaMasiva.archivosXml = this.archivosXml;

		let resultado = await this.servicios.archivos
			.descargaMasiva(this.cargaMasiva)
			.toPromise();
		const link = document.createElement('a');
		link.href = 'data:zip;base64,' + resultado.content;
		link.download = resultado.nombreArchivo;
		link.click();
	}

	async TipoDescarga(tipo): Promise<void> {
		this.Activo = true;

		this.cargaMasiva = new ArchivoDescargaMasiva();
		if (tipo == 1) {
			this.descargaMasiva();
		}

		if (tipo == 2) {
			this.archivos.forEach((element, index) => {
				this.cargaMasiva.archivosPdf.push(element.idArchivo);
			});

			let resultado = await this.servicios.archivos
				.descargaMasiva(this.cargaMasiva)
				.toPromise();
			const link = document.createElement('a');
			link.href = 'data:zip;base64,' + resultado.content;
			link.download = resultado.nombreArchivo;
			link.click();
		}

		if (tipo == 3) {
			this.archivos.forEach((element, index) => {
				this.cargaMasiva.archivosXml.push(element.idArchivo);
			});

			let resultado = await this.servicios.archivos
				.descargaMasiva(this.cargaMasiva)
				.toPromise();
			const link = document.createElement('a');
			link.href = 'data:zip;base64,' + resultado.content;
			link.download = resultado.nombreArchivo;
			link.click();
		}

		if (tipo == 4) {
			this.archivos.forEach((element, index) => {
				this.cargaMasiva.archivosPdf.push(element.idArchivo);
				this.cargaMasiva.archivosXml.push(element.idArchivo);
			});

			let resultado = await this.servicios.archivos
				.descargaMasiva(this.cargaMasiva)
				.toPromise();
			const link = document.createElement('a');
			link.href = 'data:zip;base64,' + resultado.content;
			link.download = resultado.nombreArchivo;
			link.click();
		}
		this.Activo = false;
	}

	descargarPdf(id: number, nombre: string): void {
		let observable = this.servicios.archivos
			.descargarRecepcionPdf(id)
			.subscribe((pdf) => {
				let a = document.createElement('a');
				let blob = new Blob([pdf], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = nombre + '.pdf';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarXml(id: number, nombre: string): void {
		let observable = this.servicios.archivos
			.descargarXml(id)
			.subscribe((xml) => {
				let a = document.createElement('a');
				let blob = new Blob([xml], { type: 'octet/stream' });
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = nombre + '.xml';
				document.body.appendChild(a);
				a.click();
				setTimeout(() => {
					a.remove();
					window.URL.revokeObjectURL(url);
				}, 100);
			});
	}

	descargarPE(id: number, nombre: string): void {
		let observable = this.servicios.ordenCompra
			.descargarPdfPE(id)
			.subscribe((pdf) => {
				for (const element of pdf) {
					let pdf = element;
					const byteArray = new Uint8Array(
						atob(pdf.pdfFile)
							.split('')
							.map((char) => char.charCodeAt(0))
					);
					let a = document.createElement('a');
					let blob = new Blob([byteArray], { type: 'octet/stream' });
					let url = window.URL.createObjectURL(blob);
					a.href = url;
					a.download = pdf.nombre;
					document.body.appendChild(a);
					a.click();
					setTimeout(() => {
						a.remove();
						window.URL.revokeObjectURL(url);
					}, 100);
				}
			});
	}

	cargarTabla(): void {
		this.Procesando = true;
		this.dtOptions = {
			language: { url: '/js/espanol.json' },
			searching: true,
			ajax: (dataTablesParameters: any, callback) => {
				this.servicios.archivos
					.obtenerReporte(this.archivo)
					.subscribe(async (lista) => {
						const archivosNormalizados = await this.normalizarArchivos(lista);
						this.archivos = archivosNormalizados;
						this.Procesando = false;
						callback({ data: archivosNormalizados });
					});
			},
			columns: [
				{ title: '', data: 'idArchivo', visible: false },
				{ title: '', data: 'idProveedor', visible: false },
				{ title: 'OC', data: 'numeroOrdenCompra' },
				{ title: 'Proveedor', data: 'proveedorRazonSocial' },
				{ title: 'Código identificador UUID', data: 'nombreArchivo' },
				{
					title: 'Fecha de alta ',
					data: 'fechaRegistro',
					type: 'date',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					},
				},
				{
					title: 'Fecha de recepción ',
					data: 'fechaRecepcion',
					type: 'date',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					},
				},
				{
					title: 'Fecha programación pago ',
					data: 'fechaPago',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					},
				},
				{
					title: 'Fecha de autorización ',
					data: 'fechaAutorizo',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					},
				},
				{
					title: 'Fecha Xml',
					data: 'fechaXml',
					render: (data: string, type: any, full: Archivo) => {
						if (full.fechaXml) {
							return this.datepipe.transform(new Date(full.fechaXml), 'dd/MM/yyyy');
						} else {
							return '';
						}
					},
				},
				//{
				//    title: 'Pagar',
				//    data: 'autorizado',
				//    width: "10%",
				//    render: (data: any, type: any, full: Archivo) => {
				//        if (this.val != 3) {
				//            return `<div class="ui icon buttons">
				//                            <button disabled class="ui  basic button" acc tipo="a" data-id="` + full.idArchivo + `" data-inverted="" data-tooltip="Autorizado" data-position="top center">
				//                                <i class="check square icon orange" acc tipo="a" data-id="` + full.idArchivo + `" style="font-size:22px; margin-left:20px !important;"></i>
				//                            </button>
				//                       </div>`;
				//        }

				//        if (full.autorizado == "Autorizado") {

				//            return `<div class="ui icon buttons">
				//                            <button class="ui  basic button" acc tipo="autorizar" data-id="` + full.idArchivo + `" data-inverted="" data-tooltip="Autorizado" data-position="top center">
				//                                <i class="check square icon orange" acc tipo="autorizar" data-id="` + full.idArchivo + `" style="font-size:22px; margin-left:20px !important;"></i>
				//                            </button>
				//                       </div>`;
				//        }
				//        else {
				//            return `<div class="ui icon buttons">
				//                            <button class="ui  basic button" acc tipo="autorizar" data-id="` + full.idArchivo + `"  data-inverted="" data-tooltip="Sin autorizar" data-position="top center">
				//                                <i class="check square outline icon orange" acc tipo="autorizar" data-id="` + full.idArchivo + `" style="font-size:22px; margin-left:20px !important;"></i>
				//                            </button>
				//                       </div>`;

				//        }
				//    }
				//},
				{
					title: 'Programado para pago',
					data: 'FechaPago',
					width: '5%',
					render: (data: any, type: any, full: Archivo) => {
						if (
							full.fechaPago == null &&
							this.val == 3 &&
							full.fechaAutorizo != null
						) {
							return (
								`<div class="ui icon buttons">
                                          <button class="ui orange basic button" acc tipo="programado" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Sin Programar" data-position="top center">
                                                <i class="calendar icon" acc tipo="programado" data-id="` +
								full.idArchivo +
								`" style="font-size:22px;"></i>
                                          </button>
                                   </div>`
							);
						} else {
							return (
								`<div class="ui icon buttons">
                                          <button disabled class="ui orange basic button" acc tipo="programado" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Pago programado" data-position="top center">
                                                <i class="calendar icon" acc tipo="programado" data-id="` +
								full.idArchivo +
								`" style="font-size:22px;"></i>
                                          </button>
                                   </div>`
							);
						}
					},
				},
				{
					title: 'Autorizar',
					data: 'FechaAutorizo',
					width: '5%',
					render: (data: any, type: any, full: Archivo) => {
						if (full.fechaAutorizo == null) {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui basic button" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Sin autorizar" data-position="top center">
                                              <i class="check square icon orange" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
						else if (full.fechaAutorizo != null && full.canceladoEnSap == 'Y') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui basic button" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Sin autorizar" data-position="top center">
                                              <i class="check square icon orange" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}

						else {
							return (
								`<div class="ui icon buttons">
                                            <button disabled class="ui basic button" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`"  data-inverted="" data-tooltip="Autorizado" data-position="top center">
                                                <i class="check square outline icon orange" acc tipo="autorizo" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
                                            </button>
                                       </div>`
							);
						}
					},
				},
				{
					title: 'Rechazar',
					data: 'idArchivo',
					width: '5%',
					render: (data: any, type: any, full: Archivo) => {
						if (full.fechaAutorizo == null) {
							return (
								`<div class="ui icon buttons">
										  <button class="ui basic button" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Rechazar Factura" data-position="top center">
												<i class="window close icon orange" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
										  </button>
								   </div>`
							);
						}
						else if (full.fechaAutorizo != null && full.canceladoEnSap == 'Y') {
							return (
								`<div class="ui icon buttons">
										  <button class="ui basic button" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Rechazar Factura" data-position="top center">
												<i class="window close icon orange" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
										  </button>
								   </div>`
							);
						}

						else {
							return (
								`<div class="ui icon buttons">
										  <button disabled class="ui basic button" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" data-inverted="" data-tooltip="Rechazar Factura" data-position="top center">
												<i class="window close outline icon orange" acc tipo="rechazar" data-id="` +
								full.idArchivo +
								`" style="font-size:22px; margin-left:10px !important;"></i>
										  </button>
								   </div>`
							);
						}

					},
				},
				{
					title: 'Total',
					data: 'total',
					width: '11%',
					render: (data: number) =>
						data != null ? MascaraMoneda.format(data) : '$ 0.00',
				},
				{ title: 'Moneda', data: 'moneda' },
				{
					title: 'Método de pago',
					data: 'metodoPago'
				},
				{
					title: 'Recibida SAP',
					data: 'procesadaEnSap',
					render: (data: any, type: any, full: Archivo) => {
						if (full.procesadaEnSap == 1 && full.canceladoEnSap == 'N') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui  basic button"` +
								` data-position="top center">
                                              <i class="check square icon orange" ` +
								` style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
						else if (full.procesadaEnSap == 1 && full.canceladoEnSap == '') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui  basic button"` +
								` data-position="top center">
                                              <i class="check square icon orange" ` +
								` style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
						else if (full.procesadaEnSap == 1 && full.canceladoEnSap == 'Y') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui  basic button"` +
								` data-position="top center">
                                              <i class="check square icon red" ` +
								` style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
						else {
							return (
								`<div class="ui icon buttons">
                                            <button disabled class="ui  basic button"` +
								` data-position="top center">
                                                <i class="check square outline icon orange"` +

								`" style="font-size:22px; margin-left:10px !important;"></i>
                                            </button>
                                       </div>`
							);
						}
					},
				},

				{
					title: 'Pagada SAP',
					data: 'pagadoEnSap',
					render: (data: any, type: any, full: Archivo) => {
						if (full.pagadoEnSap == 'N') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui  basic button"` +
								` data-position="top center">
                                              <i class="check square outline icon orange" ` +
								` style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
						else if (full.pagadoEnSap == 'Y') {
							return (
								`<div class="ui icon buttons">
                                           <button class="ui  basic button"` +
								` data-position="top center">
                                              <i class="check square icon orange" ` +
								` style="font-size:22px; margin-left:10px !important;"></i>
                                           </button>
                                    </div>`
							);
						}
					},
				},

				{
					title:
						'XML <div class="ui checkbox"> <input type="checkbox" name="example"></div> ',
					data: 'idArchivo',
					width: '4%',
					render: (data: any, type: any, full: Archivo) => {
						return (
							`<div class="ui checkbox">
                      <input type="checkbox"  id="` +
							data +
							`"  acc tipo="selecXml" data-id="aXml-` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`">
                      <label> </label>					 
                    </div>`
						);
					},
				},
				{
					title:
						'PDF <div class="ui checkbox"> <input type="checkbox" name="example"></div> ',
					data: 'idArchivo',
					width: '4%',
					render: (data: any, type: any, full: Archivo) => {
						return (
							`<div class="ui checkbox">
                      <input type="checkbox"    id="` +
							data +
							`"  acc tipo="selecPdf" data-id="` +
							data +
							`" data-nombre="` +
							full.nombreArchivo +
							`">
                      <label> </label>
                    </div>`
						);
					},
				},
				{
					title: 'Opciones',
					data: 'idArchivo',
					width: '8%',
					render: (data: any, type: any, full: Archivo) => {
						if (full.pruebasEntregaAceptadas) {
							return (
								`<div class="ui icon buttons">
										<button class="ui orange basic button" acc tipo="xml" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
											<i class="large file excel outline icon" acc tipo="xml" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`"></i>
										</button>
										<button class="ui orange basic button" acc tipo="pdf" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`" data-inverted="" data-tooltip="Descargar PDF Factura" data-position="top center">
										   <i class="large file pdf outline icon" acc tipo="pdf" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`"></i>
										</button>
										<button class="ui orange basic button" acc tipo="pdfPE" data-id="` +
								full.idArchivo +
								`" data-nombre="` +
								full.numeroOrdenCompra +
								`" data-inverted="" data-tooltip="Descargar PDF Pruebas entrega" data-position="top center">
										   <i class="large file pdf icon orange" acc tipo="pdfPE" data-id="` +
								full.idArchivo +
								`" data-nombre="` +
								full.numeroOrdenCompra +
								`"></i>
										</button>
									</div>`
							);
						} else {
							return (
								`<div class="ui icon buttons">
										<button class="ui orange basic button" acc tipo="xml" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`" data-inverted="" data-tooltip="Descargar XML" data-position="top center">
											<i class="large file excel outline icon" acc tipo="xml" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`"></i>
										</button>
										<button class="ui orange basic button" acc tipo="pdf" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`" data-inverted="" data-tooltip="Descargar PDF Factura" data-position="top center">
										   <i class="large file pdf outline icon" acc tipo="pdf" data-id="` +
								data +
								`" data-nombre="` +
								full.nombreArchivo +
								`"></i>
										</button>
										
										<button class="ui orange basic button" acc tipo="pdfPE" data-id="` +
								full.idArchivo +
								`" data-nombre="` +
								full.numeroOrdenCompra +
								`" data-inverted="" data-tooltip="Descargar PDF Pruebas entrega" data-position="top center">
										   <i class="large file pdf outline icon" acc tipo="pdfPE" data-id="` +
								full.idArchivo +
								`" data-nombre="` +
								full.numeroOrdenCompra +
								`"></i>
										</button>
									</div>`
							);
						}
					},
				},
			],
		};

		this.Procesando = false;
	}

	cargarEncabezados(): void {
		this.Procesando = true;
		this.dtOptions = {
			language: { url: '/js/espanol.json' },
			searching: true,
			columns: [
				{ title: '', visible: false },
				{ title: '', visible: false },
				{ title: 'OC' },
				{ title: 'Proveedor' },
				{ title: 'Código identificador UUID' },
				{ title: 'Fecha de recepción ' },
				{ title: 'Fecha de alta ' },
				{ title: 'Fecha programación pago ' },
				{ title: 'Fecha de autorización ' },
				{
					title: 'Fecha Xml'

				},

				{
					title: 'Pagar'

				},
				{
					title: 'Autorizar'

				},
				{
					title: 'Rechazar'

				},
				{
					title: 'Total'

				},
				{ title: 'Moneda' },
				{
					title: 'Método de pago'
				},
				{
					title: 'Enviada SAP'

				},
				{
					title:
						'Descargas'

				},
				{
					title:
						'Descargas'
				},
				{
					title: 'Opciones'
				},
			],
		};
	}

	public normalizarArchivos(archivos: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			const promiseNormalizarArchivos = [];
			for (const archivo of archivos) {
				promiseNormalizarArchivos.push(this.normalizarArchivo(archivo));
			}
			Promise.all(promiseNormalizarArchivos)
				.then(archivosCompletos => {
					resolve(archivosCompletos);
				})
				.catch(error => {
					reject(error);
				});
		});
	}



	public normalizarArchivo(archivo: any): Promise<any> {
		return new Promise(async (resolve, reject) => {
			try {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(archivo.xml, 'text/xml');
				const tagElements = xmlDoc.getElementsByTagName('cfdi:Comprobante');
				if (tagElements && tagElements[0] && tagElements[0].attributes) {
					const attributes = tagElements[0].attributes;
					archivo.fechaXml = attributes['Fecha'] ? attributes['Fecha'].textContent : '';
					archivo.metodoPago = attributes['MetodoPago'] ? attributes['MetodoPago'].textContent : '';
					archivo.moneda = attributes['Moneda'] ? attributes['Moneda'].textContent : '';
				} else {
					archivo.fechaXml = '';
					archivo.metodoPago = '';
					archivo.moneda = '';
				}
				archivo.pruebasEntregaAceptadas = archivo.numeroOrdenCompra ? await this.pruebasEntregaAceptadas(archivo) : false;
				resolve(archivo);
			}
			catch (error) {
				console.log('error-->', error);
				archivo.fechaXml = '';
				archivo.metodoPago = '';
				archivo.moneda = '';
				archivo.pruebasEntregaAceptadas = false;
				resolve(archivo);
			}
		});
	}

	buscar(): void {
		this.busquedaGrande();
	}

	buscarOK(): void {
		this.Procesando = true;
		this.recargarTabla();
	}


	public preguntarSiBuscar() {

		const swalWithBootstrapButtons = Swal.mixin({
			confirmButtonClass: 'btn btn-success mr-1',
			cancelButtonClass: 'btn btn-danger mr-1',
			buttonsStyling: false,
		});
		swalWithBootstrapButtons.fire({
			title: 'La consulta puede tardar varios minutos',
			text: `¿Desea continuar?`,
			type: 'question',
			showCancelButton: true,
			confirmButtonText: 'Sí, continuar',
			cancelButtonText: 'No, cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.aceptarBusquedaGrande();
			}
		});

	}

	aceptarBusquedaGrande(): void {
		this.Procesando = true;
		this.recargarTabla();
	}

	busquedaGrande(): void {
		if (!this.archivo.nombreArchivo &&
			!this.archivo.proveedorRazonSocial &&
			!this.archivo.sociedadNombreComercial &&
			!this.archivo.fechaAutorizoDesde &&
			!this.archivo.fechaAutorizoHasta &&
			!this.archivo.fechaPagoDesde &&
			!this.archivo.fechaPagoHasta &&
			!this.archivo.fechaPago &&
			!this.archivo.fechaPPD &&
			!this.archivo.fechaPPH &&
			//!this.archivo.fechaRecepcionDesde &&
			//!this.archivo.fechaRecepcionHasta &&
			!this.tipoBusqueda) {
			this.preguntarSiBuscar();
		}
		else if (
			this.archivo.sociedadNombreComercial &&
			!this.archivo.fechaRecepcionHasta &&
			!this.archivo.fechaRecepcionDesde
		) {
			this.preguntarSiBuscar();
		}
		else if (
			!this.archivo.fechaRecepcionHasta &&
			!this.archivo.fechaRecepcionDesde
		) {
			this.preguntarSiBuscar();
		}
		else if (
			this.archivo.sociedadNombreComercial &&
			this.archivo.fechaRecepcionHasta &&
			this.archivo.fechaRecepcionDesde
		) {
			let fechaHasta = this.archivo.fechaRegistroDesde.getTime();
			let fechaDesde = this.archivo.fechaRegistroHasta.getTime();
			var diferencia = fechaHasta - fechaDesde;
			var diasBusqueda = (diferencia / (1000 * 60 * 60 * 24))
			if (diasBusqueda > 60) {
				this.preguntarSiBuscar();
			}
			else {
				this.buscarOK();
			}
		}
		else {
			if (this.archivo.fechaRecepcionHasta && this.archivo.fechaRecepcionDesde) {
				let fechaHasta = this.archivo.fechaRecepcionHasta.getTime();
				let fechaDesde = this.archivo.fechaRecepcionDesde.getTime();
				var diferencia = fechaHasta - fechaDesde;
				var diasBusqueda = (diferencia / (1000 * 60 * 60 * 24))
				if (diasBusqueda > 60) {
					this.preguntarSiBuscar();
				}
				else {
					this.buscarOK();
				}
			} else {
				this.buscarOK();
			}
		}
	}

	limpiar(): void {
		this.archivo.nombreArchivo = null;
		this.archivo.proveedorRazonSocial = null;
		this.archivo.sociedadNombreComercial = null;
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.archivo.fechaPagoDesde = null;
		this.archivo.fechaPagoHasta = null;
		this.archivo.fechaPago = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;
		this.archivo.fechaRecepcionDesde = null;
		this.archivo.fechaRecepcionHasta = null;
		this.archivo.fechaRegistroDesde = null;
		this.archivo.fechaRegistroHasta = null;


		this.tipoBusqueda = null;
		this.val = 0;

		this.mostrarDatos1 = false;
		this.mostrarDatos2 = false;
		this.mostrarDatos3 = false;
		this.mostrarDatos4 = false;
		this.mostrarDatos5 = false;
	}

	limpiarFiltroAutorizacion(): void {
		this.archivo.fechaAutorizoDesde = null;
		this.archivo.fechaAutorizoHasta = null;
		this.recargarTabla();
	}

	limpiarFiltroPago(): void {
		this.archivo.fechaPagoDesde = null;
		this.archivo.fechaPagoHasta = null;
		this.recargarTabla();
	}

	limpiarFiltroPorAutorizar(): void {
		this.archivo.fechaPago = null;
		this.archivo.fechaPPD = null;
		this.archivo.fechaPPH = null;
	}

	reporteExcel(): void {
		this.exportToXlsxService.reporteRecepcionFacturas('Reporte Recepción de Facturas', this.archivos);
		/*
		let observable = this.servicios.archivos
			.obtenerReporteExcel(this.archivo)
			.subscribe((xls) => {
				let a = document.createElement('a');
				let blob = new Blob([xls], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				let url = window.URL.createObjectURL(blob);
				a.href = url;
				a.download = 'Reporte Archivos' + '.xlsx';
				a.click();
				window.URL.revokeObjectURL(url);
			});
		*/
	}

	public recargarTabla(): void {
		this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
			dtInstance.ajax.reload();
		});
	}

	public rechazarFactura(id: any) {

		const swalWithBootstrapButtons = Swal.mixin({
			confirmButtonClass: 'btn btn-success mr-1',
			cancelButtonClass: 'btn btn-danger mr-1',
			buttonsStyling: false,
		});
		swalWithBootstrapButtons.fire({
			title: '¿Desea rechazar el documento?',
			text: `Se rechazará la Factura`,
			type: 'question',
			showCancelButton: true,
			confirmButtonText: 'Sí, rechazar',
			cancelButtonText: 'No, cancelar',
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.servicios.archivos.rechazarCompra(id)
					.then(eliminado => {
						if (eliminado) {
							this.notificacion.mostrarExito('Factura rechazada.');
							this.recargarTabla();
						} else {
							this.notificacion.mostrarError(
								'Error! La factura no fue rechazada.'
							);
						}
					});
			}
		});

	}

	pruebasEntregaAceptadas(archivo: any): Promise<any> {
		return new Promise((resolve, reject) => {
			this.servicios.archivos.pruebasEntregaAceptadas(archivo.idArchivo)
				.then(respuesta => {
					resolve(respuesta);
				})
				.catch(error => {
					console.log('error ==>', error);
					resolve(false);
				})
		});
	}

	// pruebasEntregaAceptadas(archivo: any): Promise<any> {
	// 	return new Promise((resolve, reject) => {
	// 		if (archivo.numeroOrdenCompra) {
	// 			this.servicios.archivos.pruebasEntregaAceptadas(archivo.idArchivo)
	// 				.then(respuesta => {
	// 					resolve(respuesta);
	// 				})
	// 				.catch(error => {
	// 					console.log('error ==>', error);
	// 					resolve(false);
	// 				})
	// 		} else {
	// 			resolve(false);
	// 		}
	// 	});


	// }

	usuarioResponsable(archivo: any): Promise<any> {
		return new Promise((resolve, reject) => {
			if (archivo.numeroOrdenCompra) {
				this.servicios.archivos.usuarioResponsable(archivo.numeroOrdenCompra)
					.then(respuesta => {
						resolve(respuesta);
					})
					.catch(error => {
						console.log('error ==>', error);
						resolve(false);
					})
			} else {
				resolve(false);
			}
		});


	}

	ngOnDestroy(): void {
		this.oyente();
	}
}
