﻿import { Servicio } from "./servicio";
import { OrdenCompra } from "../entidades/indice";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ArchivoPruebaEntrega } from "../entidades/archivos-prueba-entrega";
import { ErrorEnvioSap } from "../entidades/error-envio-sap";
import { CorreoPruebasEntrega } from "../entidades/correo-prueba-entrega";

export class ServicioOrdenCompra extends Servicio<OrdenCompra> {
  constructor(http: HttpClient) {
    super(http, "ordencompra");
  }

  guardarCompleto(datos: FormData): Observable<OrdenCompra> {
    let ruta = this.Ruta;

    return this.ClienteHttp.post(ruta, datos).pipe(
      map((archivo: OrdenCompra) => archivo)
    );
  }

  leerPdf(datos: FormData): Observable<OrdenCompra> {
    let ruta = `${this.Ruta}/LeerPDF`;
    return this.ClienteHttp.post(ruta, datos).pipe(
      map((archivo: OrdenCompra) => archivo)
    );
  }

  obtenerReporte(entidad: OrdenCompra): Observable<OrdenCompra[]> {
    let ruta = this.Ruta + "/Buscar";    
    return this.ClienteHttp.post(ruta, entidad).pipe(
      map((data: OrdenCompra[]) => {        
        return data;
      })
    );
  }

  obtenerErroresEnviosSap(): Observable<ErrorEnvioSap[]> {
    let ruta = this.Ruta + "/ErroresEnviosSap";
    return this.ClienteHttp.get<ErrorEnvioSap[]>(ruta);
  }

  obtenerPruebasEntregaSinEnviar(): Observable<CorreoPruebasEntrega[]> {
    let ruta = this.Ruta + "/PruebasEntregaSinEnviar";
    return this.ClienteHttp.get<CorreoPruebasEntrega[]>(ruta);
  }

  obtenerReporteExcel(entidad: OrdenCompra): Observable<Blob> {
    let ruta = this.Ruta + "/BuscarExcel";
    return this.ClienteHttp.post(ruta, entidad, {
      responseType: "blob",
    }).pipe(map((xls: Blob) => xls));
  }

  obtenerReporteExcelC(entidad: OrdenCompra): Observable<Blob> {
    let ruta = this.Ruta + "/ExcelCompra";
    return this.ClienteHttp.post(ruta, entidad, {
      responseType: "blob",
    }).pipe(map((xls: Blob) => xls));
  }

  descargarPdf(idArchivo: number): Observable<Blob> {
    let ruta = this.Ruta + "/" + idArchivo + "/DescargarPdf";
    return this.ClienteHttp.get(ruta, { responseType: "blob" }).pipe(
      map((pdf: Blob) => pdf)
    );
  }

  CerrarManualmente(idOrden: number): Observable<string> {
    let ruta = this.Ruta + "/" + idOrden + "/CerrarManualmente";
    return this.ClienteHttp.get(ruta, { responseType: "text" }).pipe(
      map((oc: string) => oc)
    );
  }

  OrdenesCompraProveedorSociedad(proveedorId: number, sociedadId: number): Observable<OrdenCompra[]> {
    let ruta = this.Ruta + "/OrdenesCompraProveedorSociedad/" + proveedorId + "/" + sociedadId;
    return this.ClienteHttp.get(ruta).pipe(
      map((compras: OrdenCompra[]) => compras)
    );
  }

  descargarPdfPE(idArchivo: number): Observable<ArchivoPruebaEntrega[]> {
    let ruta = this.Ruta + "/" + idArchivo + "/DescargarPdfPE";
    return this.ClienteHttp.get(ruta).pipe(
      map((pdf: ArchivoPruebaEntrega[]) => pdf)
    );
  }

  cancelar(idOrden: number): Observable<string> {
    let ruta = this.Ruta + "/" + idOrden + "/Cancelar";
    return this.ClienteHttp.get(ruta, { responseType: "text" }).pipe(
      map((oc: string) => oc)
    );
  }

  reenviarFactura(idOrden: number): Observable<string> {
    let ruta = this.Ruta + "/" + idOrden + "/ReenviarFactura";
    return this.ClienteHttp.get(ruta, { responseType: "text" }).pipe(
      map((oc: string) => oc)
    );
  }

  reenviarCorreoApe(idOrden: number): Observable<string> {
    let ruta = this.Ruta + "/" + idOrden + "/ReenviarApe";
    return this.ClienteHttp.get(ruta, { responseType: "text" }).pipe(
      map((oc: string) => oc)
    );
  }

  sePuedeCancelar(idOrden: number): Observable<boolean> {
    let ruta = this.Ruta + "/" + idOrden + "/SePuedeCancelar";
    return this.ClienteHttp.get<boolean>(ruta)
  }

 

  

}
