﻿import { DetallesArchivo } from './../../entidades/detallesArchivo';
import { Component, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, SuiModalService } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Configuracion, Pago } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { a } from '@angular/core/src/render3';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'forma-pagos',
    templateUrl: 'forma.component.html'
})
export class FormaPagos extends FormaModal {

    @ViewChild('modalTemplatePago') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('flXml', { read: ElementRef }) flXml: ElementRef;
    @ViewChild('flPdf', { read: ElementRef }) flPdf: ElementRef;

    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();
    pago = new Pago();
    facturas: DetallesArchivo[];
    totalFacturas: number;
    permitirIngresoFacturasSinComplemento = false;
    xmlInvalido = false;
    errorComplemento = '';
    configuracion: Configuracion;


    get totalesCoinciden(): boolean {

        let coincide = false;

        if ((this.flXml.nativeElement).files.length > 0 && this.totalFacturas && this.pago.total) {
            if (this.totalFacturas.toFixed(2) == this.pago.total.toFixed(2)) {
                coincide = true;
                return coincide;
            } else {

                //OBTENGO DIFERENCIA
                let diferencia = Number((this.pago.total - this.totalFacturas).toFixed(2));

                let maximo = Number(this.configuracion.importeMaximo);
                let minimo = this.configuracion.importeMinimo;

                //DIFERENCIA
                if (diferencia <= maximo && diferencia >= minimo) {
                    coincide = true;
                } else {
                    coincide = false;
                }

            }
        }

        return coincide;

        // return (<HTMLInputElement>this.flXml.nativeElement).files.length > 0 && this.totalFacturas && this.pago.total &&
        //     this.totalFacturas.toFixed(2) == this.pago.total.toFixed(2)
    }

    get esPue(): boolean {
        return this.pago.pue;
    }
    // @ViewChild(TablaCatalogoPagos) tablaCatArchivos: TablaCatalogoPagos;
    // @ViewChild(CatalogoPagos) CatArchivos: CatalogoPagos;

    constructor(public modalService: SuiModalService, private snotifyService: SnotifyService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
        this.obtenerFacturas();
        this.obtenerTolerancia();
        let idCoorporativo = 1;
        this.permitirIngresoFacturasSinComplemento = false;
        let credenciales = JSON.parse(localStorage.getItem('credencial'));
        if (credenciales && credenciales.idTipoUsuario) {

            idCoorporativo = credenciales.idCorporativo ? credenciales.idCorporativo : 1;
        }
        let observable = this.servicios.configuracion.obtener(idCoorporativo);
        observable
            .subscribe(
                confs => {
                    this.permitirIngresoFacturasSinComplemento = confs.permitirIngresoFacturasSinComplemento;
                },
                error => {
                }
            );

    }

    obtenerTolerancia() {
        this.servicios.configuracion.obtenerTodos().subscribe(async (conf) => {
            this.configuracion = conf.find((c) => c.id == 1);
        });
    }

    obtenerFacturas() {
        this.servicios
            .archivos
            .detallesArchivoSinComplementoPago()
            .subscribe(
                facturas => {
                    this.facturas = facturas;
                }
            );
    }

    async crearNuevo(): Promise<void> {
        this.pago = new Pago();
    }

    async XML(): Promise<void> {
        let datos = new FormData();
        let iva;
        let total;

        let xml = (<HTMLInputElement>this.flXml.nativeElement).files[0];
        datos.append("xml", xml);
        let nombre = xml.name.substring(0, xml.name.indexOf('.'));
        let archivoresult = await this.servicios.archivos
            .leerXML(datos, 1)
            .toPromise();
        if (!this.validarComplemento(archivoresult)) {
            this.xmlInvalido = true;
            return;
        } else {
            const idsArchivo = this.pago.archivosId ? this.pago.archivosId.toString() : '';
            datos.append("idsFactura", idsArchivo);
            this.servicios.pagos.leerXML(datos).toPromise()
                .then(pago => {
                    this.pago.total = pago.total;
                    this.pago.uuids = pago.uuids;
                    this.totalFacturas = pago.archivoTotal;
                    this.pago.pue = pago.pue;
                    this.pago.facPue = pago.facPue;
                })
                .catch(error => {
                    console.log(error, error);
                    this.snotifyService.error("Error al cargar el archivo XML, verifique nuevamente", {
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerTop
                    });
                    datos = null;
                })
            let pago = await this.servicios.pagos.leerXML(datos).toPromise()
                .catch(error => {

                });
        }
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.abrir("Nuevo", true);
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void { }

    limpiar(): void {
        this.flPdf.nativeElement.value = "";
        this.flXml.nativeElement.value = "";
        this.pago = new Pago();
        this.totalFacturas = 0;
    }

    // calcularTotalFacturas()
    // {
    //     const ids = this.pago.archivosId;
    //     const selecciondas = this.facturas.filter(f => ids.includes(f.idArchivo)).map(f => f.total);
    //     this.totalFacturas = selecciondas.reduce((a,b) => (a + b), 0);
    // }

    guardar(): void {
        this.Procesando = true;

        let datos = new FormData();
        let xml = (<HTMLInputElement>this.flXml.nativeElement).files[0];
        let nombre = xml.name.substring(0, xml.name.indexOf('.'))
        this.pago.nombrePago = nombre;
        datos.append("xml", xml);

        let pdf = (<HTMLInputElement>this.flPdf.nativeElement).files[0];
        datos.append("pdf", pdf);

        datos.append("pagoJson", JSON.stringify(this.pago));

        let mensaje: string;
        mensaje = 'Pago guardado.';
        let observable = this.servicios.pagos.guardarCompleto(datos);

        observable
            .subscribe(
                archivo => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.limpiar();
                    this.procesoTerminado.emit(true);
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                }
            );
    }

    public validarComplemento(archivoXml: any): boolean {

        if (this.permitirIngresoFacturasSinComplemento) {
            if (String(archivoXml.metodoPago) === 'PPD') {
                if (String(archivoXml.formaPago) === '99') {
                    this.errorComplemento = 'El método de pago es PPD la forma de pago debe ser 99 por definir';
                    this.xmlInvalido = true;
                    return false;
                }
            } else if (String(archivoXml.metodoPago) === 'PUE') {
                if (String(archivoXml.formaPago) !== '99') {
                    this.errorComplemento = 'El método de pago es PUE la forma de pago deberá ser diferente a 99r';
                    this.xmlInvalido = true;
                    return false;
                }
            }
            this.xmlInvalido = false;
            return true;
        } else {
            this.xmlInvalido = false;
            return true;
        }

    }

}