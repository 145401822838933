﻿export class Proveedor {
    idProveedor: number;
    codigo: string;
    rfc: string;
    razonSocial: string;
    activo: string;
    fechaRegistro: Date;
    fechaAutorizacion: Date;
    nombreUsuario: string;
    autorizado: string;
    correo: string;
    contrasena: string;
    confirmarContrasena: string;
    captcha: string;
    aplicaOrdenCompra: string;
    aplicaConOSinOrdenCompra: string;
    descripcion:string;
    permitirFacturasMesAnterior: boolean;
    aplicaPruebasEntrega: boolean;
}