﻿import { Injectable } from "@angular/core";
import { SnotifyService, SnotifyPosition } from "ng-snotify";
import { SafeMethodCall } from "@angular/compiler";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class Notificacion {

    duracion = 3500;

    constructor(private snotifyService: SnotifyService, private sanitizer: DomSanitizer) {
    }

    mostrarExito(mensaje: string): void {
        this.snotifyService.success(mensaje, {
            timeout: this.duracion,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerTop,
        });
    }

    mostrarError(mensaje: string): void {
        this.snotifyService.error(mensaje, {
            timeout: this.duracion,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerTop,
        });
    }

    mostrarAdvertencia(mensaje: string): void {
        this.snotifyService.warning(mensaje, {
            timeout: this.duracion,
            closeOnClick: true,
            pauseOnHover: true,
            position: SnotifyPosition.centerTop
        });
    }
}