﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormaCorreo } from './forma.component';
import { Correo } from '../../entidades/indice';
import { TablaCatalogoCorreo } from './tabla.component';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: "catalogo-correo",
    templateUrl: "catalogo.component.html"
})
export class CatalogoCorreo implements OnDestroy {
    @ViewChild(FormaCorreo) formaCorreo: FormaCorreo;
    @ViewChild(TablaCatalogoCorreo) tablaCatalogo: TablaCatalogoCorreo;
    titulo: string = "Correos";
    habilitarNuevo: boolean = false;

    constructor(private servicios: Servicios) {}

    nuevo(): void {
        this.formaCorreo.abrirNuevo();
    }

    editar(correoEvento: Correo): void {
        this.formaCorreo.correo = correoEvento;
        this.formaCorreo.abrirEditar();
    }

    recargarDatos(): void {
        this.tablaCatalogo.recargarTabla();
        this.habilitarNuevo = false;
    }

    ngOnDestroy(): void {
        this.formaCorreo.cancelar();
    }
}