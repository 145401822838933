import {
	Component,
	EventEmitter,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { ModalTemplate, SuiModalService } from 'ng2-semantic-ui';
import { first } from 'rxjs/operators';
import { OCRD } from '../../entidades/ocrd';
import { Sociedad } from '../../entidades/sociedad';
import { Servicios } from '../../servicios/servicios';
import { FormaModal } from '../formaModal';

interface ProveedoresSociedad {
	sociedad: Sociedad;
	proveedores: { codigo: string; descripcion: string }[];
	predeterminado: boolean;
}

@Component({
	selector: 'app-proveedores-sap',
	templateUrl: './proveedores-sap.component.html',
	styleUrls: ['./proveedores-sap.component.css'],
})
export class ProveedoresSapComponent extends FormaModal implements OnInit {
	@ViewChild('modalTemplateProveedorSap') modalTemplate: ModalTemplate<
		void,
		void,
		void
	>;

	sociedades: Sociedad[];
	codigoProveedor: string;
	proveedoresSap: ProveedoresSociedad[];

	@Output() proveedorSeleccionado: EventEmitter<void> =
		new EventEmitter<void>();

	constructor(
		public modalService: SuiModalService,
		private servicios: Servicios
	) {
		super(modalService);
		this.Tamano = 'small';
	}

	ngOnInit() {
		this.cargarSociedades();
	}

	cargarSociedades() {
		this.servicios.sociedades
			.obtenerTodos()
			.pipe(first())
			.subscribe(async (sociedades) => {
				this.proveedoresSap = await Promise.all(sociedades.map(s => this.cargarProveedoresSap(s)));
			});
	}

	async cargarProveedoresSap(sociedad: Sociedad): Promise<ProveedoresSociedad> {
		const proveedores = await this.servicios.servicioSap
			.obtenerProveedores(sociedad.rfc)
			.toPromise();

		return {
			sociedad: sociedad,
			proveedores: proveedores.map((p) => ({
				codigo: p.cardCode,
				descripcion: `${p.cardCode} ${p.cardName}`,
			})),
			predeterminado: false,
		};
	}
}
