﻿import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Usuario } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'forma-usuario',
    templateUrl: 'forma.component.html'
})
export class FormaUsuario extends FormaModal {
    @ViewChild('modalTemplateUsuario') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('txtUsuario', { read: ElementRef }) txtusuario: ElementRef;
    usuario: Usuario;
    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
    }

    crearNuevo(): void {
        this.usuario = new Usuario();        
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.abrir("Nuevo", true);
    }

    abrirEditar(): void {
        this.abrir("Editar", false);
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void {
        this.txtusuario.nativeElement.focus()
    }

    limpiar(): void {
        this.forma.resetForm();
        this.usuario = new Usuario();
        if (!this.MantenerAbierto) {
            this.cerrar();
        }
        this.enfocar();
    }

    guardar(): void {
        this.Procesando = true;

        let observable: Observable<Usuario>;
        let mensaje: string;
        if (this.usuario.idUsuario > 0) {
            mensaje = 'Usuario actualizado.';
            observable = this.servicios.usuarios.actualizar(this.usuario.idUsuario.toString(), this.usuario) 
        } else {
            mensaje = 'Usuario guardado.';
            observable = this.servicios.usuarios.guardar(this.usuario);
        }

        observable
            .subscribe(
                usuarios => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.limpiar();
                    this.procesoTerminado.emit(true);
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                }
            );
    }
}