﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormaProveedor } from './forma.component';
import { Proveedor } from '../../entidades/indice';
import { TablaCatalogoProveedor } from './tabla.component';
import { SuiModalService } from 'ng2-semantic-ui';
import { Servicios } from '../../servicios/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Notificacion } from '../../compartida/indice';

@Component({
    selector: "catalogo-proveedor",
    templateUrl: "catalogo.component.html"
})
export class CatalogoProveedor implements OnDestroy {
    titulo: string = "Proveedor";
    procesando: boolean;
    proveedor: Proveedor;

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
    }

    ngOnInit() {
        this.proveedor = new Proveedor;
        this.cargarProveedor();
    }

    cargarProveedor(): void {
        let idProveedor = this.servicios.autenticacion.credencial.idUsuario;

        let observable = this.servicios.proveedores.obtener(idProveedor);
        observable
            .subscribe(
                proveedor => {
                    this.proveedor = proveedor;
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarExito(httpError.error);
                    this.procesando = false;
                }
            );
    }

    guardar(): void {
        this.procesando = true;
        let mensaje = "Datos actualizados.";
        let contrasenaFicticia = "-";
        this.proveedor.confirmarContrasena = contrasenaFicticia;
        this.proveedor.contrasena = contrasenaFicticia;
        let observable = this.servicios.proveedores.actualizar(this.proveedor.idProveedor.toString(), this.proveedor);
        observable
            .subscribe(
                proveedores => {
                    this.notificacion.mostrarExito(mensaje);
                    this.procesando = false;
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );
    }

    ngOnDestroy(): void {
    }
}
