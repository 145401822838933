﻿import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Proveedor } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
    selector: 'forma-proveedor',
    templateUrl: 'forma.component.html'
})
export class FormaProveedor extends FormaModal {
    @ViewChild('modalTemplateProveedor') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('txtProveedor', { read: ElementRef }) txtproveedor: ElementRef;
    @ViewChild('captcha', { read: RecaptchaComponent }) reCaptcha: RecaptchaComponent;

    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();
    proveedor: Proveedor;
    tipoProceso: "editar"|"nuevo"|"registro";

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
    }

    crearNuevo(): void {
        this.proveedor = new Proveedor();
    }

    abrirRegistrar(): void {
        this.crearNuevo();
        this.tipoProceso = "registro"
        this.abrir("Registro de proveedor", false);
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.tipoProceso = "nuevo"
        this.abrir("Nuevo", true);
    }

    abrirEditar(): void {
        this.tipoProceso = "editar"
        this.abrir("Editar", false);
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void {
        this.txtproveedor.nativeElement.focus()
    }

    limpiar(): void {
        this.forma.resetForm();
        this.proveedor = new Proveedor();
        if (!this.MantenerAbierto) {
            this.cerrar();
        }
        this.enfocar();
    }

    captchaResuelto(respuestaCaptcha: string): void {
        console.log(`Resolved captcha with response ${respuestaCaptcha}:`);
    }
    
    guardar(): void {
        this.Procesando = true;

        let observable: Observable<Proveedor>;
        let mensaje: string;
        if (this.tipoProceso == "editar") {
            mensaje = 'Proveedor actualizada.';
            observable = this.servicios.proveedores.actualizar(this.proveedor.idProveedor.toString(), this.proveedor) 
        } else {
            mensaje = 'Proveedor guardada.';
            observable = this.servicios.proveedores.guardar(this.proveedor);
        }

        if (this.tipoProceso == "registro") {
            mensaje = 'Proveedor registrado. Se le enviará un correo electrónico cuando se termine la validación de la informacion';
        }

        observable
            .subscribe(
                proveedores => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.limpiar();
                    this.procesoTerminado.emit(true);
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                    this.reCaptcha.reset();
                }
            );
    }
}