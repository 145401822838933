﻿import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,

    HttpHeaders
} from '@angular/common/http';
import { Observable, BehaviorSubject, EMPTY, EmptyError } from 'rxjs';
import { map, switchMap, catchError, take, filter, finalize, flatMap, } from 'rxjs/operators';
import { Servicios, ServicioAutenticacion } from './indice';

@Injectable()
export class Interceptor implements HttpInterceptor {
    private autenticacion: ServicioAutenticacion;
    refrescandoToken = false;
    peticionRefrescoEnviada = false;

    constructor(public servicios: Servicios) {
        this.autenticacion = servicios.autenticacion;
    }

    agregarToken(solicitud: HttpRequest<any>): HttpRequest<any> {
        let tokenBearer = `Bearer ${this.autenticacion.obtenerToken()}`;
        let encabezados = new HttpHeaders({
            "Authorization": tokenBearer,
        });

        let solicitudToken = solicitud.clone({ headers: encabezados });
        return solicitudToken;
    }

    intercept(solicitud: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.refrescandoToken && !this.peticionRefrescoEnviada) {            
            return this.autenticacion.tokenActualizado.asObservable()
                .pipe(
                    flatMap(async (listo, idx) => {
                    if (listo) {
                        return await this.enviarSolicitud(solicitud, next).toPromise();
                    }
                    else {
                        return EMPTY.toPromise();
                    }
                }));
        }
        else {
            this.peticionRefrescoEnviada = false;
            return this.enviarSolicitud(solicitud, next);
        }
    }

    enviarSolicitud(solicitud: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        solicitud = this.agregarToken(solicitud);
        return next.handle(solicitud).pipe(
            catchError((err: any, caught: Observable<HttpEvent<any>>) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        return this.intentarRefresarToken(solicitud, next);
                    }
                }
                throw err;
            }));
    }

    intentarRefresarToken(solicitud: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
            this.refrescandoToken = true;
            this.peticionRefrescoEnviada = true;
            
            return this.autenticacion.refrescarToken()
                .pipe(flatMap(
                    (res: any) => {
                        this.refrescandoToken = false;
                        this.autenticacion.tokenActualizado.next(true);
                        return next.handle(this.agregarToken(solicitud));
                    }
                ),
                catchError((err: any) => {
                    this.refrescandoToken = false;
                    this.autenticacion.salir();
                    return EMPTY;
                })
            );
    }
}