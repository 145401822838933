import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'sheetjs-style';
import * as _ from 'lodash';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
  })
export class ExportToXlsxService {
      public async reporteFlujoCompas(encabezado: string, cvsArchivos: any, origen: string): Promise<void> {             
        const renglones = [];
        const columnas = origen === 'xmonitor' ? ['A','B','C','D','E','F','G','H','I','J','K','L','M']:['A','B','C','D','E','F','G','H','I','J','K','L'];
        if(origen === 'xmonitor' || origen === 'sap') {
            for(const cv of cvsArchivos){
                renglones.push(
                    {
                        "CV": cv.cv,
                        "Proveedor": cv.proveedor,
                        "F. OC. Referi": cv.fechaOCReferi ? await this.fecha(cv.fechaOCReferi): '',
                        "F. OC. SAP": cv.fechaOCSAP ? await this.fecha(cv.fechaOCSAP) : '',
                        "F. Aut. Referi": cv.fechaAuthReferi ? await this.fecha(cv.fechaAuthReferi) : '',
                        "Num Fact. SAP":  cv.numFactSAP,
                        "U. Reg. Fact,":  cv.userRegistroFactura,
                        "F. Registro Fact.": cv.fechaRegistroFactura ? await this.fecha(cv.fechaRegistroFactura): '',
                        "F. Pago SAP": cv.fechaPagoSAP ? await this.fecha(cv.fechaPagoSAP) : '',
                        "Importe Pagado SAP": cv.fechaPagoSAP ? cv.currency === 'USD' ? await this.formatMoneda(cv.importePagadoSAPCurrency) : await this.formatMoneda(cv.importePagadoSAPPesos): '',
                        "Moneda":  cv.currency,
                        "Importe Fact. Referi": cv.importeFacturaReferi ? await this.formatMoneda(cv.importeFacturaReferi) : '',
                        "Importe Fact. SAP":  cv.currency === 'USD' ? await this.formatMoneda(cv.importeFacturaSAPCurrency) : await this.formatMoneda(cv.importeFacturaSAPPesos)
                    }
                );
            }
        } else {
            for(const archivo of cvsArchivos){
                renglones.push(
                    {
                        "Folio Fiscal": archivo.folioFiscal,
                        "Proveedor": archivo.proveedor,
                        "F. Aut. Referi": archivo.fechaAuthReferi ? await this.fecha(archivo.fechaAuthReferi) : '',
                        "F. OC. SAP": archivo.fechaOCSAP ? await this.fecha(archivo.fechaOCSAP) : '',                       
                        "Num Fact. SAP":  archivo.numFactSAP ? archivo.numFactSAP : '',
                        "U. Reg. Fact,":  archivo.userRegistroFactura ? archivo.userRegistroFactura: '',
                        "F. Registro Fact.": archivo.fechaRegistroFactura ? await this.fecha(archivo.fechaRegistroFactura): '',
                        "F. Pago SAP": archivo.fechaPagoSAP ? await this.fecha(archivo.fechaPagoSAP) : '',
                        "Importe Pagado SAP": archivo.fechaPagoSAP ? archivo.currency === 'USD' ? await this.formatMoneda(archivo.importePagadoSAPCurrency) : await this.formatMoneda(archivo.importePagadoSAPPesos): '',
                        "Moneda":  archivo.currency ? archivo.currency : '',
                        "Importe Fact. Referi": archivo.importeFacturaReferi ? await this.formatMoneda(archivo.importeFacturaReferi) : '',
                        "Importe Fact. SAP": archivo.currency ? archivo.currency === 'USD' ? await this.formatMoneda(archivo.importeFacturaSAPCurrency) : await this.formatMoneda(archivo.importeFacturaSAPPesos) : ''
                    }
                );
            }
        }                

        const worksheet = XLSX.utils.aoa_to_sheet([
            [encabezado]
        ]);        
        
        if (renglones.length > 0) {
          XLSX.utils.sheet_add_aoa(worksheet, [
            Object.getOwnPropertyNames(renglones[0])
          ], {origin: -1});
          
          let index = 0 ;
          for(const  renglon of renglones) {
            
            XLSX.utils.sheet_add_aoa(worksheet, [
              Object.values(renglon)
            ], {origin: -1});  


            
            if (await this.marcar(renglon, origen)) {                          
              for(const columna of columnas) {  
                worksheet[`${columna}${2}`].s = {
                  font: {                      
                    bold: false,
                    color: {
                      rgb: "FAFAFA"
                    }
                  },
                  fill : {
                    fgColor : {
                        theme : 8,
                        tint : 0.3999755851924192,
                        rgb : '9E0000'
                    }
                  }
                };              
                worksheet[`${columna}${index+3}`].s = {
                  font: {                      
                    bold: false,
                    color: {
                      rgb: "FAFAFA"
                    }
                  },
                  fill : {
                    fgColor : {
                        theme : 8,
                        tint : 0.3999755851924192,
                        rgb : '9E0000'
                    }
                  }
                };
                
              }     
            }      
            index++; 
          }                          
      }        
       
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };       
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, encabezado);
       
       
      }

      public async reporteRecepcionFacturas(encabezado: string, archivos: any): Promise<void> {
        const renglones = [];
        for(const archivo of archivos) {
          renglones.push(
            {
              "OC": archivo.numeroOrdenCompra,
              "Proveedor": archivo.proveedorRazonSocial,
              "Código identificador UUID": archivo.nombreArchivo,
              "Fecha de alta": await this.fecha(archivo.fechaRegistro),
              "Fecha programación pago": await this.fecha(archivo.fechaPago),
              "Fecha de autorización": archivo.fechaAutorizo ? await this.fecha(archivo.fechaAutorizo) : '',
              "Fecha Xml": archivo.fechaXml ? await this.fecha(archivo.fechaXml) : '',
              "Autorizado": archivo.fechaAutorizo ? "Si" : 'No',
              "Cancelado": archivo.fechaCancelacion ? "Si" : 'No',
              "Sociedad Comercial": archivo.sociedadNombreComercial,
              "Total": archivo.total,
              "Moneda": archivo.moneda,
              "Método de pago": archivo.metodoPago,
              "Orden Cerrada": archivo.ordenCerrada ? "Si": "No",
              "Orden Cerrada Manualmente": archivo.ordenCerradaManualmente ? "Si": "No",
              "Enviada a SAP": archivo.procesadaEnSap === 1 ? "Si": "No"
            }
          );
          
        }
        const columnas = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P'];
        if (renglones.length > 0) {
          const worksheet = XLSX.utils.aoa_to_sheet([
            [encabezado]
          ]);   
          XLSX.utils.sheet_add_aoa(worksheet, [
            Object.getOwnPropertyNames(renglones[0])
          ], {origin: -1});
          for(const  renglon of renglones) {
            
            XLSX.utils.sheet_add_aoa(worksheet, [
              Object.values(renglon)
            ], {origin: -1});  
          }
          for(const columna of columnas) {
            worksheet[`${columna}${2}`].s = {
              font: {                      
                bold: false,
                color: {
                  rgb: "FAFAFA"
                }
              },
              fill : {
                fgColor : {
                    theme : 8,
                    tint : 0.3999755851924192,
                    rgb : '9E0000'
                }
              }
            };
          }
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };       
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, encabezado);
        }
        
      }

      public async reporteXMLFacturas(encabezado: string, facturas: any): Promise<void> {
        const worksheet = XLSX.utils.aoa_to_sheet([
          [encabezado]
        ]); 
        if(facturas.length > 0) {
          XLSX.utils.sheet_add_aoa(worksheet, [
            Object.getOwnPropertyNames(facturas[0])
          ], {origin: -1});
          for(const  renglon of facturas) {            
            XLSX.utils.sheet_add_aoa(worksheet, [
              Object.values(renglon)
            ], {origin: -1});  
          }
          const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };       
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, encabezado);
        }
       
      }

      public marcar(renglon: any, origen: String): Promise<Boolean> {
        return new Promise((resolve) => {
          if(origen === 'xmonitor') {
            if (renglon['Moneda'] && renglon['Importe Fact. SAP'] && (renglon['Importe Fact. Referi'] || renglon['Importe Pagado SAP'])) {
              if (renglon['Importe Fact. SAP'] && renglon['Importe Fact. Referi'] && renglon['Importe Pagado SAP']) {
                resolve(!(String(renglon['Importe Fact. SAP']) === String(renglon['Importe Pagado SAP']) && String(renglon['Importe Fact. SAP']) === String(renglon['Importe Fact. Referi'])));
              } else {
                if(renglon['Importe Pagado SAP']) {
                  resolve(String(renglon['Importe Fact. SAP']) !== String(renglon['Importe Pagado SAP']));
                } else {
                  resolve(String(renglon['Importe Fact. SAP']) !== String(renglon['Importe Fact. Referi']));
                }
              }              
            } else {
              resolve(false);
            }
          } else {
            if (renglon['Importe Fact. SAP'] && (renglon['Importe Fact. Referi'] || renglon['Importe Pagado SAP'])) {
              if (renglon['Importe Fact. SAP'] && renglon['Importe Fact. Referi'] && renglon['Importe Pagado SAP']) {
                resolve(!(String(renglon['Importe Fact. SAP']) === String(renglon['Importe Pagado SAP']) && String(renglon['Importe Fact. SAP']) === String(renglon['Importe Fact. Referi'])));
              } else {
                if(renglon['Importe Pagado SAP']) {
                  resolve(String(renglon['Importe Fact. SAP']) !== String(renglon['Importe Pagado SAP']));
                } else {
                  resolve(String(renglon['Importe Fact. SAP']) !== String(renglon['Importe Fact. Referi']));
                }
              }              
            } else {
              resolve(false);
            }
          }                        
        });
      }
    
      private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
      }

      

      public fecha(value: string): Promise<any> {
        return new Promise((resolve) => {
            const fecha = new Date(value);
            const dia = fecha.getDate();
            const mes = fecha.getMonth()+ 1;
            const anio = fecha.getFullYear();
            resolve(`${dia < 10 ? '0'+dia : dia}/${mes < 10 ? '0'+mes: mes}/${anio}`);
        });
      }

      public formatMoneda(value: any): Promise<any> {
        return new Promise((resolve) => {
          if (isNaN(value)) {
            resolve(value);
          }
          const formatted = new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2
          });
          resolve(formatted.format(Number(value)));
        });
      }
}

