import { Component, OnInit, ViewChild } from '@angular/core';
import { RestablecerContrasena } from '../../entidades/indice';
import { ServicioAutenticacion, Servicios } from '../../servicios/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-restablecercontrasena',
    templateUrl: './restablecercontrasena.component.html',
    styleUrls: ['./restablecercontrasena.component.css']
})
export class RestablecercontrasenaComponent implements OnInit {
    usuario: RestablecerContrasena;
    procesando: boolean;
    token : string
 
    constructor(private router: Router, private autenticacion: ServicioAutenticacion, private servicios: Servicios, private snotifyService: SnotifyService, private route: ActivatedRoute) {
        console.log('Called Constructor');
        this.route.queryParams.subscribe(params => {
           this.token = params['usuario'];
        });
     
    }

    ngOnInit() {
        this.usuario = new RestablecerContrasena();
    }

    usuarioRes(usu: string): void {
        let observable = this.servicios
            .restablecerContrasena
            .reesContrasena(usu)
            .subscribe(
                pdf => {


                }
            );
    }

    IrLogin(): void {
        this.router.navigateByUrl('/');
    }

    restablecer(): void {
        this.usuario.token = this.token;
        let observable = this.autenticacion
            .actualizarToken(this.usuario.usuario, this.usuario.contrasena, this.usuario.token)
            .subscribe(
                result => {
                    if (result == "exito") {
                        this.snotifyService.success("Contrase&ntilde;a reestablecida con exito", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });
                        this.router.navigateByUrl('/');
                    }
                    else if (result == "errorLetraMayuscula") {
                        this.snotifyService.warning("La contrase&ntilde;a debe contener por lo menos una letra mayuscula y/o minuscula", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });

                    }
                    else if (result == "errorCambio") {
                        this.snotifyService.warning("El usuario ya no cuenta con un token de seguridad", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });

                    }
                    else if (result == "erroNoFound") {
                        this.snotifyService.warning("Usuario no identificado", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });

                    }
                    else if (result == "errorArgInvalidos") {
                        this.snotifyService.warning("Datos invalidos, verifique nuevamente", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });

                    }
                    else {
                        this.snotifyService.error("Error al restablecer la contrase&ntilde;a, verifique nuevamente...", {
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerTop
                        });
                   
                    }
                }
                , error => {
                    let httpError = <HttpErrorResponse>error;
                    this.snotifyService.error("Error contacte a su administrador!", {
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerTop
                    });
                    
                });
    }

}
