﻿import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup, DatepickerMode, SuiDatepicker } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse, HttpEventType, HttpEvent } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Archivo, Sociedad, Proveedor, OrdenCompra } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
import { getLocaleDateFormat } from '@angular/common';
import { toDate } from '@angular/common/src/i18n/format_date';
import { OrdencompraComponent } from './ordencompra.component';

@Component({
    selector: 'forma-ordenes',
    templateUrl: 'forma.component.html'
})
export class FormaOrdenCompra extends FormaModal {

    fechaActual: Date = new Date();
    @ViewChild('modalTemplateOrdenCompra') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('txtArchivo', { read: ElementRef }) txtarchivo: ElementRef;
    @ViewChild('flPdf', { read: ElementRef }) flPdf: ElementRef;
    // @ViewChild(OrdencompraComponent) frmOrdenCompra: OrdencompraComponent;
    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();

    ordencompra: OrdenCompra;
    proveedores: Proveedor[];
    modoFecha = DatepickerMode.Date;
    constructor(public modalService: SuiModalService, private snotifyService: SnotifyService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
        this.cargarProveedores();
    }

    cargarProveedores() {
        this.servicios
            .proveedores
            .obtenerTodos()
            .subscribe(
                lista => {
                    this.proveedores = lista;
                }
            );
    }

    crearNuevo(): void {
        this.ordencompra = new OrdenCompra();
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.abrir("Nuevo", true);
        this.ordencompra.fechaRegistro = new Date;
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void {
         
    }


    opRetencion(): void {
        let suma = this.ordencompra.subTotal + this.ordencompra.iva - this.ordencompra.retencion;
        this.ordencompra.total = suma;
    }


    opIva(): void {
        let suma = this.ordencompra.subTotal + this.ordencompra.iva - this.ordencompra.retencion;
        this.ordencompra.total = suma;
    }

    opSubTotal(): void {
        let suma = this.ordencompra.subTotal + this.ordencompra.iva - this.ordencompra.retencion;
        this.ordencompra.total = suma;
    }



    limpiar(): void {
     
        this.ordencompra.numero = null;
        this.ordencompra.iva = null
        this.ordencompra.subTotal = null;
        this.ordencompra.total = null;

        this.flPdf.nativeElement.value = "";
        this.ordencompra.fechaRegistro = new Date;
    }

    OC(): void {

        let datos = new FormData();
        let iva;
        let total;
        let pdf = (<HTMLInputElement>this.flPdf.nativeElement).files[0].name;

        this.ordencompra.numero = pdf.split(".")[0];
        this.ordencompra.subTotal = 0;
        this.ordencompra.total = 0;
        this.ordencompra.iva = 0;
        this.ordencompra.retencion = 0;


        //let datos = new FormData();
        //let iva;
        //let total;
        //let pdf = (<HTMLInputElement>this.flPdf.nativeElement).files[0];
        //datos.append("pdf", pdf);


        //let observable = this.servicios.ordenCompra.leerPdf(datos);
        //observable
        //    .subscribe(
        //        archivo => {
        //                if (archivo.numero != null) {
        //                    this.ordencompra.numero = archivo.numero;
        //                }
        //                else {
        //                    this.snotifyService.warning("No fue posible leer el campo No. Orden de compra", {
        //                        closeOnClick: true,
        //                        pauseOnHover: true,
        //                        position: SnotifyPosition.centerTop
        //                    });
        //                    datos = null;
        //                }

        //                if (archivo.subTotal != null) {
        //                    this.ordencompra.subTotal = archivo.subTotal;
        //                }
        //                else {
        //                    this.snotifyService.warning("No fue posible leer el campo SubTotal", {
        //                        closeOnClick: true,
        //                        pauseOnHover: true,
        //                        position: SnotifyPosition.centerTop
        //                    });
        //                    datos = null;
        //                }
        //                if (archivo.iva != null) {
        //                    this.ordencompra.iva = archivo.iva;
        //                } else {
        //                    this.snotifyService.warning("No fue posible leer el campo IVA", {
        //                        closeOnClick: true,
        //                        pauseOnHover: true,
        //                        position: SnotifyPosition.centerTop
        //                    });
        //                    datos = null;
        //                }
        //                if (archivo.total != null) {
        //                    this.ordencompra.total = archivo.total;
        //                } else {
        //                    this.snotifyService.warning("No fue posible leer el campo Total", {
        //                        closeOnClick: true,
        //                        pauseOnHover: true,
        //                        position: SnotifyPosition.centerTop
        //                    });
        //                    datos = null;
        //                }
        //        },
        //        error => {
        //            this.snotifyService.error("La orden de compra no contiene las caracteristicas solicitadas", {
        //                closeOnClick: true,
        //                pauseOnHover: true,
        //                position: SnotifyPosition.centerTop
        //            });
        //            datos = null;
        //        }
        //    );
    }

    guardar(): void {     
        this.Procesando = true;
        let iva = this.ordencompra.iva;
        let subt = this.ordencompra.subTotal;
        if (iva <= 0) {
            this.Procesando = false;
            this.procesoTerminado.emit(true);
            this.notificacion.mostrarAdvertencia("El monto de IVA debe ser mayor a cero");
            return;
        }
        if (subt <= 0) {
            this.Procesando = false;
            this.procesoTerminado.emit(true);
            this.notificacion.mostrarAdvertencia("El monto de Sub-Total debe ser mayor a cero");
            return;
        }

        let proveedor = this.proveedores.find(s => s.idProveedor == this.ordencompra.idProveedor);
        let datos = new FormData();

        let pdf = (<HTMLInputElement>this.flPdf.nativeElement).files[0];
        datos.append("pdf", pdf);

        datos.append("archivoJson", JSON.stringify(this.ordencompra));

        let mensaje: string;
        mensaje = 'Orden de compra guardada con exito.';
        let observable = this.servicios.ordenCompra.guardarCompleto(datos);

        observable
            .subscribe(
                archivo => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.procesoTerminado.emit(true);
                    this.limpiar();
                     
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                }
        );
    }
  

    recargarComponentes(): void {
        // this.frmOrdenCompra.recargarTabla();
    }



}