﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Correo } from '../../entidades/indice';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';
import { SuiModalService } from 'ng2-semantic-ui';
import { Notificacion } from '../../compartida/indice';
import { SnotifyService, SnotifyPosition } from 'ng-snotify';
@Component({
    selector: 'app-configuracioncorreo',
    templateUrl: './configuracioncorreo.component.html',
    styleUrls: ['./configuracioncorreo.component.css']
})
export class ConfiguracioncorreoComponent implements OnInit {

    titulo: string = "Configuraci&oacute;n de servidor de correo";
    procesando: boolean;
    correo: Correo;

    idConfiguracion: number;
    pageDimmed: boolean = false;
    constructor(public modalService: SuiModalService, private snotifyService: SnotifyService, private servicios: Servicios, private notificacion: Notificacion) { }
    ngOnInit() {
        
        this.correo = new Correo;
        this.cargarConfiguracion();
    }


    cargarConfiguracion(): void {
        this.idConfiguracion = this.servicios.autenticacion.credencial.idCorporativo;
        let observable = this.servicios.correos.obtener(this.idConfiguracion);
        observable
            .subscribe(
                confs => {
                    this.correo = confs;
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError("Lo sentimos aún no se han creado las configuraciones necesarias");
                    this.procesando = false;
                }
            );
    }

    guardar(): void {

        this.procesando = true;
        let mensaje = "Configuración de servidor de correo actualizado";
        let observable = this.servicios.correos.actualizar(this.idConfiguracion.toString(), this.correo);
        observable
            .subscribe(
                proveedores => {
                    this.notificacion.mostrarExito(mensaje);
                    this.procesando = false;
                    this.cargarConfiguracion();
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );

    }
 

    ngOnDestroy(): void {
    }
}
