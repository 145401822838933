import { Component, Renderer2, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';
import { Proveedor } from '../../entidades/proveedor';
import { Notificacion } from '../../compartida/indice';
import { Configuracion } from '../../entidades/indice';
import { ProveedoresSapComponent } from '../../compartida/proveedores-sap/proveedores-sap.component';
import * as _ from 'lodash';

@Component({
    selector: 'app-proveedor',
    templateUrl: './proveedor.component.html',
    styleUrls: ['./proveedor.component.css']
})
export class ProveedorComponent implements OnInit, AfterViewInit {

    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    titulo: string = "Proveedores";
    @ViewChild(ProveedoresSapComponent) proveedoresSap: ProveedoresSapComponent;

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Proveedor> = new Subject();
    private oyente: () => void;
    idConfiguracion: number;
    proveedoresBase: Proveedor[];
    proveedores: Proveedor[];
    sociedades: any[] = [];
    sociedad: any;
    proveedor: Proveedor;
    configuracion: Configuracion;
    tipoOrdenCompraId: number;
    constructor(
        private servicios: Servicios, 
        private renderer: Renderer2, 
        private notificacion: Notificacion) {
        this.configuracion = new Configuracion();
        this.proveedor = new Proveedor();

    }

    async ngOnInit() {
        this.cargarSociedades();
        this.cargarTabla();
        await this.cargarConfiguracion();
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            if (this.tipoOrdenCompraId === 1) {
                dtInstance.column(8).visible(true);
            } else {
                dtInstance.column(8).visible(false);
            }
        });
    }

    cargarSociedades(){
        this.servicios.sociedades
        .obtenerTodos()
        .pipe(first())
        .subscribe(async (sociedades) => {            
            this.sociedades = sociedades;            
        });
    }

    seleccionarSociedad(sociedad: any) {                
        this.sociedad = sociedad;        
        this.cargarTabla();
        this.recargarTabla();
    }

    limpiar() {
        this.sociedad = null;        
        this.cargarTabla();
        this.recargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'a') {
                    this.servicios
                        .proveedores
                        .autorizarProveedor(id)
                        .subscribe(
                            autorizo => {
                                this.proveedor = autorizo;
                                var auto = autorizo.autorizado.toString();
                                if (auto == "true") {
                                    this.notificacion.mostrarExito("Proveedor autorizado.");
                                    this.notificacion.mostrarExito("Correo enviado al proveedor");

                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();

                                    });

                                } else {
                                    this.notificacion.mostrarExito("Proveedor no autorizado");

                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                    });

                                }

                            }, error => {
                                let httpError = <HttpErrorResponse>error;
                                this.notificacion.mostrarError(httpError.error);
                            });
                }

                if (tipo == 'b') {
                    this.servicios
                        .proveedores
                        .aplicaProveedorOrdenCompra(id)
                        .subscribe(
                            proveedor => {

                                if (proveedor.aplicaOrdenCompra.toString() == "true") {
                                    this.notificacion.mostrarExito("El proveedor aplica orden de compra.");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                    });

                                } else {
                                    this.notificacion.mostrarExito("El proveedor no aplica orden de compra.");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                       
                                    });
                                }

                            }, error => {
                                let httpError = <HttpErrorResponse>error;
                                this.notificacion.mostrarError(httpError.error);
                            });
                }

                if (tipo == 'c') {
                    this.servicios
                        .proveedores
                        .aplicaProveedorConOSinOrdenCompra(id)
                        .subscribe(
                            proveedor => {

                                if (proveedor.aplicaConOSinOrdenCompra.toString() == "true") {
                                    this.notificacion.mostrarExito("El proveedor aplica con orden de compra.");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                    });

                                } else {
                                    this.notificacion.mostrarExito("El proveedor aplica sin orden de compra.");
                                    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                        dtInstance.ajax.reload();
                                       
                                    });
                                }

                            }, error => {
                                let httpError = <HttpErrorResponse>error;
                                this.notificacion.mostrarError(httpError.error);
                            });
                }

                if(tipo == 'd'){
                    this.servicios
                    .proveedores
                    .permitirFacturasMesAnterior(id)
                    .subscribe(
                        proveedor => {

                            if (proveedor.permitirFacturasMesAnterior) {
                                this.notificacion.mostrarExito("El proveedor puede subir facturas del mes anterior.");
                                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                    dtInstance.ajax.reload();
                                });

                            } else {
                                this.notificacion.mostrarExito("El proveedor no puede subir facturas del mes anterior.");
                                this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                    dtInstance.ajax.reload();
                                });
                            }

                        }, error => {
                            let httpError = <HttpErrorResponse>error;
                            this.notificacion.mostrarError(httpError.error);
                        });
                }

                if(tipo == 'e'){
                    //this.proveedoresSap.recargarTabla();
                    this.proveedoresSap.abrir('Seleccionar', false);
                }

                if(tipo == 'f'){
                    this.servicios
                    .proveedores
                    .aplicaPruebasEntrega(id)
                    .then(aplicaPruebas => {
                        if (aplicaPruebas) {
                            this.notificacion.mostrarExito("El proveedor aplica pruebas entrega.");
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                dtInstance.ajax.reload();
                            });

                        } else {
                            this.notificacion.mostrarExito("El proveedor no aplica pruebas entrega.");
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                dtInstance.ajax.reload();
                               
                            });
                        }
                    })
                    .catch(error => {
                        let httpError = <HttpErrorResponse>error;
                        this.notificacion.mostrarError(httpError.error);
                    });                                        
                }
            }
        });
    }
    cargarTabla(): void {

        var n = 0;
        this.dtOptions = {
            language: { url: '/js/espanol.json' },
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .proveedores
                    .listaProveedores(this.proveedor)
                    .subscribe(                        
                        lista => {                                                  
                            this.proveedoresBase = lista;                            
                            if (this.sociedad) {
                                if (String(this.sociedad) === '1') {
                                    this.proveedores = _.cloneDeep(this.proveedoresBase).filter(mProveedor => mProveedor.codigo);                            
                                } else {
                                    this.proveedores = _.cloneDeep(this.proveedoresBase).filter(mProveedor => mProveedor.codigoVexan);                            
                                }   
                            } else {
                                this.proveedores = this.proveedoresBase;
                            }                                                        
                            callback({ data: this.proveedores });
                        }
                    );
            },
            columnDefs: [
                { className: "dt-center", targets: [0] },
            ],
            columns: [
                { title: '', data: 'idProveedor', visible: false },
                { title: 'C&oacute;digo Motion', data: 'codigo'},
                { title: 'C&oacute;digo Vexan', data: 'codigoVexan'},
                // { title: 'C&oacute;digo', data: 'codigo',
                //     render: function (data: any, type: any, full: any) {
                //         return `<div class="ui icon buttons">
                //                     <button class="ui orange basic button" acc tipo="e" data-id="` + data + `" data-inverted="" data-tooltip="Clic para modifical el código de proveedor" data-position="top center">
                //                         ` + data + `
                //                     </button>
                //                 </div>`;
                //     } 
                // },
                { title: 'Raz&oacute;n Social', data: 'razonSocial' },
                { title: 'RFC', data: 'rfc' },
                {
                    title: 'Fecha de registro', data: 'fechaRegistro', render: function (data) {
                        if (data === null) return "";
                        var fecha = null;
                        if (data != null && data != '') {
                            var date = data.split('T');
                            fecha = date[0];
                            return fecha;
                        }
                    }
                },
                { title: 'Activo', data: 'activo' },
                {
                    title: 'Fecha de autorizaci&oacute;n', data: 'fechaAutorizacion', render: function (data) {
                        if (data === null) return "";
                        var fecha = null;
                        if (data != null && data != '') {
                            var date = data.split('T');
                            fecha = date[0];
                            return fecha;
                        }
                    }
                },
                {
                    title: 'Autorizaci&oacute;n',
                    data: 'autorizado',
                    width: "10%",
                    render: (data: any, type: any, full: Proveedor) => {
                        if (full.autorizado != null) {
                            //var resp = `<input type="checkbox" acc tipo="a" data-id="${full.idProveedor}"  name="auto" id="auto${n}" class="filled-in" value="true" ` + (data === "Autorizado" ? `checked="checked"` : "") + '/><label for="auto' + n + '"></label>';
                            //n++;
                            //return resp;


                            if (full.autorizado == "Autorizado") {

                                return `<div class="ui icon buttons">
                                            <button class="ui  basic button" acc tipo="a" data-id="` + full.idProveedor + `" data-inverted="" data-tooltip="Autorizado" data-position="top center">
                                                <i class="check square icon orange" acc tipo="a" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                            </button>
                                       </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                            <button class="ui  basic button" acc tipo="a" data-id="` + full.idProveedor + `"  data-inverted="" data-tooltip="Sin autorizar" data-position="top center">
                                                <i class="check square outline icon orange" acc tipo="a" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                            </button>
                                       </div>`;

                            }
                        }
                    }
                },
                {
                    title: 'Aplica Orden de Compra',
                    data: 'aplicaOrdenCompra',
                    width: "15%",
                    render: (data: any, type: any, full: Proveedor) => {
                        if (full.aplicaOrdenCompra != null) {
                            if (full.aplicaOrdenCompra == "Si") {

                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="b" data-id="` + full.idProveedor + `" data-inverted="" data-tooltip="Aplica" data-position="top center">
                                                    <i class="check square icon orange" acc tipo="b" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="b" data-id="` + full.idProveedor + `"  data-inverted="" data-tooltip="No Aplica" data-position="top center">
                                                    <i class="check square outline icon orange" acc tipo="b" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;

                            }
                        }
                    }
                },
                {
                    title: 'Aplica con o sin orden de Compra',
                    data: 'aplicaOrdenCompra',
                    width: "15%",
                    render: (data: any, type: any, full: Proveedor) => {
                        if (full.aplicaConOSinOrdenCompra != null) {
                            if (full.aplicaConOSinOrdenCompra == "Si") {

                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="c" data-id="` + full.idProveedor + `" data-inverted="" data-tooltip="Aplica con orden de compra" data-position="top center">
                                                    <i class="check square icon orange" acc tipo="c" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="c" data-id="` + full.idProveedor + `"  data-inverted="" data-tooltip="No Aplica orden de compra" data-position="top center">
                                                    <i class="check square outline icon orange" acc tipo="c" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;

                            }
                        }
                    }
                },
                {
                    title: 'Permitir facturas del mes anterior',
                    data: 'permitirFacturasMesAnterior',
                    width: "15%",
                    render: (data: any, type: any, full: Proveedor) => {
                        
                            if (full.permitirFacturasMesAnterior) {

                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.idProveedor + `" data-inverted="" data-tooltip="Permitir facturas del mes anterior" data-position="top center">
                                                    <i class="check square icon orange" acc tipo="d" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.idProveedor + `"  data-inverted="" data-tooltip="Permitir facturas del mes anterior" data-position="top center">
                                                    <i class="check square outline icon orange" acc tipo="d" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;

                            }
                    }
                },
                {
                    title: 'Aplica pruebas entrega',
                    data: 'aplicaPruebasEntrega',
                    width: "15%",
                    render: (data: any, type: any, full: Proveedor) => {                            
                            if (full.aplicaPruebasEntrega) {

                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.idProveedor + `" data-inverted="" data-tooltip="Aplica prueba de entrega" data-position="top center">
                                                    <i class="check square icon orange" acc tipo="f" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.idProveedor + `"  data-inverted="" data-tooltip="Aplica prueba de entrega" data-position="top center">
                                                    <i class="check square outline icon orange" acc tipo="f" data-id="` + full.idProveedor + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;

                            }
                    }
                }
            ]
        };
       
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
        });
    }

    public async cargarConfiguracion(): Promise<void> {
        this.idConfiguracion = this.servicios.autenticacion.credencial.idCorporativo;
        let configuracion = await this.servicios.configuracion.obtener(this.idConfiguracion).toPromise();
        if (configuracion != null) {
            this.tipoOrdenCompraId = configuracion.tipoOrdenCompraId;
        }
    }

    ngOnDestroy(): void {
        this.oyente();
    }

    asignarCodigoProveedor(event: any){
    }
}

