﻿import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Correo, Sociedad } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'forma-correo',
    templateUrl: 'forma.component.html'
})
export class FormaCorreo extends FormaModal {
    @ViewChild('modalTemplateCorreo') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('txtCorreo', { read: ElementRef }) txtcorreo: ElementRef;
    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();
    correo: Correo;
    sociedadesSinCorreo: Sociedad[];
    editando: boolean;

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
    }
    
    crearNuevo(): void {
        this.correo = new Correo();
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.editando = false;
        this.abrir("Nuevo", true);
    }

    abrirEditar(): void {
        this.editando = true;
        this.abrir("Editar", false);
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void {
        this.txtcorreo.nativeElement.focus()
    }

    limpiar(): void {
        this.forma.resetForm();
        this.correo = new Correo();
        if (!this.MantenerAbierto) {
            this.cerrar();
        }
        this.enfocar();
    }

    guardar(): void {
        this.Procesando = true;

        let observable: Observable<Correo>;
        let mensaje: string;
        if (this.editando) {
            mensaje = 'Correo actualizado.';
            observable = this.servicios.correos.actualizar(this.correo.idCorreo.toString(), this.correo)
        } else {
            mensaje = 'Correo guardado.';
            observable = this.servicios.correos.guardar(this.correo);
        }

        observable
            .subscribe(
                correos => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.limpiar();
                    this.procesoTerminado.emit(true);
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                }
            );
    }
}