import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion, Notificacion } from '../../../compartida/indice';
import { Aviso } from '../../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../../servicios/indice';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import * as _ from 'lodash';

@Component({
  selector: 'app-tabla-avisos',
  templateUrl: './tabla-avisos.component.html',
  styleUrls: ['./tabla-avisos.component.css']
})
export class TablaAvisosComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  @ViewChild(Confirmacion) confirmacion: Confirmacion;
  @Output() editarOprimido: EventEmitter<number> = new EventEmitter<number>();
  @Output() eliminarOprimido: EventEmitter<number> = new EventEmitter<number>();
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<Aviso> = new Subject();
  private oyente: () => void;
  avisos: Aviso[];
  idSeleccionado: number;

  constructor(
    private servicios: Servicios, 
    private renderer: Renderer2, 
    private notificacion: Notificacion
  ) { }

  ngOnInit() {        
    this.cargarTabla();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

    this.oyente = this.renderer.listen('body', 'click', (event) => {
        let objetivo = <HTMLElement>event.target;   
        if (objetivo.hasAttribute('acc')) {
          let tipo = objetivo.getAttribute('tipo');
          let id = parseInt(objetivo.getAttribute('data-id'));
          if (tipo == 'a') {
              this.editar(id);
          } else {
            this.idSeleccionado = id;
            this.preguntarSieliminar();
          }
      }        
    });
  }  

  async editar(id:number): Promise<void> {        
    this.editarOprimido.emit(id);
  }

  obtenerTextoOriginal(contenidoTransformado: string):Promise<string> {        
    return new Promise((resolve) => {
        let textoOriginal = contenidoTransformado.replace(/<p>/g, '');
        textoOriginal = textoOriginal.replace(/<\/p>/g, '\n');
        textoOriginal = textoOriginal.replace(/<br>/g, '\n');        
        resolve(textoOriginal) ;
  });
    
}

  preguntarSieliminar(): void {
    const options: SweetAlertOptions = {
      title: '¿Desea eliminar el aviso?',
      text: 'Se eliminará el aviso de forma permanente',      
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    };
  
    Swal.fire(options).then((result) => {
      if (result.value) {
        // Handle confirmed action
        this.eliminar();
      }
    });
  }

  eliminar() {
    this.servicios
    .avisos
    .eliminar(this.idSeleccionado.toString())
    .subscribe(
        eliminado => {
            this.notificacion.mostrarExito("Aviso eliminado.");
            this.recargarTabla();
        },
        error => {
            let httpError = <HttpErrorResponse>error;
            this.notificacion.mostrarError(httpError.error);
        }
    );
  }

  cargarTabla() {    
    this.dtOptions = {
      language: { url: '/js/espanol.json' },
      ajax: (dataTablesParameters: any, callback) => {
          this.servicios
              .avisos
              .obtenerTodos()
              .subscribe(avisos => {

                  this.avisos = avisos;                  
                  console.log('avisos-->', this.avisos);
                  callback({ data: avisos });
              });
      },
      columns: [
          { title: 'Id', data: 'idAviso', visible: false },
          { title: 'Aviso', data: 'textoAviso' },          
          { title: 'Fecha de Inicio', data: 'fechaInicio', render: (data: string) => (new Date(data)).toLocaleDateString() },
          { title: 'Fecha de Fin', data: 'fechaFin', render: (data: string) => (new Date(data)).toLocaleDateString() },    
          {
            title: 'Archivos',
            data: 'archivos',
            render: function (data: any, type: any, full: any) {
              const archivos = Array.isArray(data) ? data : []; // Ensure data is an array
              const archivosHtml = archivos.map(archivo => `<li>${archivo.nombre}</li>`).join('');
              return `<ul>${archivosHtml}</ul>`;
            }
          },
          {
            title: 'Opciones',
            data: 'idAviso',
            width: "15%",
            render: function (data: any, type: any, full: any) {
              return `<div class="ui icon buttons">
                          <button class="ui orange basic button" acc tipo="a" data-id="` + data + `" data-inverted="" data-tooltip="Editar" data-position="top center">
                              <i class="large edit icon" acc tipo="a" data-id="` + data + `"></i>
                          </button>
                          <button class="ui orange basic button" acc tipo="e" data-id="` + data + `" data-inverted="" data-tooltip="Eliminar" data-position="top center">
                              <i class="large trash alternate icon" acc tipo="e" data-id="` + data + `"></i>
                          </button>
                      </div>`;
            }
          }     
      ]
    };
  }

  public recargarTabla(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
    });   
  }

  ngOnDestroy(): void {
    this.oyente();
  }

}
