﻿import { Component, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormaUsuario } from './forma.component';
import { Usuario } from '../../entidades/indice';
import { TablaCatalogoUsuario } from './tabla.component';

@Component({
    selector: "catalogo-usuario",
    templateUrl: "catalogo.component.html"
})
export class CatalogoUsuario implements OnDestroy {
    @ViewChild(FormaUsuario) formaUsuario: FormaUsuario;
    @ViewChild(TablaCatalogoUsuario) tablaCatalogo: TablaCatalogoUsuario;
    titulo: string = "Usuarios";

    nuevo(): void {        
        this.formaUsuario.abrirNuevo();
    }

    editar(usuarioEvento: Usuario): void {
        this.formaUsuario.usuario = usuarioEvento;
        this.formaUsuario.abrirEditar();
    }

    recargarTabla(): void {
        this.tablaCatalogo.recargarTabla();
    }

    ngOnDestroy(): void {
        this.formaUsuario.cancelar();
    }

}