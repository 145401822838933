﻿import { Component, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Proveedor, Corporativo } from '../../entidades/indice';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';
import { SuiModalService } from 'ng2-semantic-ui';
import { Notificacion } from '../../compartida/indice';

@Component({
    selector: "catalogo-corporativo",
    templateUrl: "catalogo.component.html"
})
export class CatalogoCorporativo implements OnDestroy {
    titulo: string = "Corporativo";
    procesando: boolean;
    corporativo: Corporativo;

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
    }

    ngOnInit() {
        this.corporativo = new Corporativo;
        this.cargarCorporativo();
    }

    cargarCorporativo(): void {
        let idCorporativo = this.servicios.autenticacion.credencial.idCorporativo;
        let observable = this.servicios.corporativos.obtener(idCorporativo);
        observable
            .subscribe(
            corporativo => {
                this.corporativo = corporativo;


                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );
    }

    guardar(): void {
        this.procesando = true;
        let mensaje = "Corporativo actualizado.";
        let observable = this.servicios.corporativos.actualizar(this.corporativo.idCorporativo.toString(), this.corporativo);
        observable
            .subscribe(
                proveedores => {
                    this.notificacion.mostrarExito(mensaje);
                    this.procesando = false;
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );
    }
    
    ngOnDestroy(): void {
    }
}