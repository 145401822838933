﻿import { Servicio } from "./servicio";
import { Correo, Proveedor } from "../entidades/indice";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class ServicioProveedor extends Servicio<Proveedor> {

  constructor(http: HttpClient) {
    super(http, "proveedores");
  }

  obtenerProveedor(codigo: string): Promise<any> {
    let ruta = this.Ruta + "/" + codigo;
    return new Promise((resolve, reject) => {
      this.ClienteHttp.get(ruta).toPromise()
        .then((succcess: any) => {
          resolve(succcess);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  //aca mando el model  la supuesta entidad de Archivo
  listaProveedores(entidad: Proveedor): Observable<Proveedor[]> {
    let ruta = this.Ruta + "/LProveedores";
    return this.ClienteHttp.post(ruta, entidad)
      .pipe(map((data: Proveedor[]) => {
        return data;
      }));
  }

  /*

  */

  autorizarProveedor(idProveedor: number): Observable<Proveedor> {
    let ruta = this.Ruta + "/" + idProveedor + "/AutorizacionProveedor";
    return this.ClienteHttp.get(ruta)
      .pipe(map((proveedor: Proveedor) => proveedor));

  }

  aplicaProveedorOrdenCompra(idProveedor: number): Observable<Proveedor> {
    let ruta = this.Ruta + "/" + idProveedor + "/AplicaProveedorOrdenCompra";
    return this.ClienteHttp.get(ruta)
      .pipe(map((proveedor: Proveedor) => proveedor));

  }

  aplicaProveedorConOSinOrdenCompra(idProveedor: number): Observable<Proveedor> {
    let ruta = this.Ruta + "/" + idProveedor + "/AplicaProveedorConOSinOrdenCompra";
    return this.ClienteHttp.get(ruta)
      .pipe(map((proveedor: Proveedor) => proveedor));
  }

  /*
        validateMatricula(matricula: string, movimiento: number, _id?: string): Promise<boolean> {    
  const url = environment.apiUrl + '/validate/alumno/matricula/' + matricula;
  return new Promise((resolve, reject) => {
    this.httpclient.post(url, {_id, movimiento}).toPromise()
    .then( (success: any) => {               
      resolve(success.existe);        
    })
    .catch((error) => {
      let message: string;
      message = error.message;
      reject(message);
    });
  });
}
  */


  aplicaPruebasEntrega(idProveedor: number): Promise<boolean> {
    let ruta = this.Ruta + "/" + idProveedor + "/AplicaPruebasEntrega";
    return new Promise((resolve, reject) => {
      this.ClienteHttp.get(ruta).toPromise()
        .then(aplicaPruebas => {
          resolve(Boolean(aplicaPruebas));
        })
        .catch(error => [
          resolve(false)
        ]);
    });
  }

  aplicaPruebasEntregaProv(idProveedor: number): Promise<boolean> {
    let ruta = this.Ruta + "/" + idProveedor + "/AplicaPruebasEntregaProv";
    return new Promise((resolve, reject) => {
      this.ClienteHttp.get(ruta).toPromise()
        .then(aplicaPruebas => {
          resolve(Boolean(aplicaPruebas));
        })
        .catch(error => [
          resolve(false)
        ]);
    });
  }

  permitirFacturasMesAnterior(idProveedor: number): Observable<Proveedor> {
    let ruta = this.Ruta + "/" + idProveedor + "/PermitirFacturasMesAnterior";
    return this.ClienteHttp.get(ruta)
      .pipe(map((proveedor: Proveedor) => proveedor));

  }

  ObtenerProveedores(): Observable<Proveedor[]> {
    let ruta = this.Ruta + "/ProveedoresLista";
    return this.ClienteHttp.get(ruta).pipe(map((data: Proveedor[]) => {
      return data;
    }));
  }

}