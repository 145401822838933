﻿import { AvisoMostrarComponent } from './../avisos/aviso-mostrar/aviso-mostrar.component';
import { Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import { Servicios } from 'ClientApp/app/servicios/indice';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'inicio',
    templateUrl: './inicio.component.html'
})
export class Inicio implements OnInit {    
    constructor(      
        public servicios: Servicios,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        
    }

    ngAfterViewInit(): void {
        this.mostrarAvisos();
    }

    mostrarAvisos(){        
        this.servicios.avisos.obtenerAvisosVigentes()
        .subscribe(async avisos => {
            if(avisos && avisos.length> 0) {      
                for(const aviso of avisos){
                    await this.mostrarAviso(aviso);
                }                                         
            }                        
        });
    }

    mostrarAviso(aviso: any): Promise<boolean>{   
        
        return new Promise((resolve) => { 
            const dialogRef = this.dialog.open(AvisoMostrarComponent, {
                width: '50%',            
                data: aviso
            });
            
            setTimeout(function() {
                dialogRef.close();
            }, (5 * 1000));            
       
            dialogRef.afterClosed().subscribe(result => {                
                resolve(true);
            });
         });               
    }

    
}
