import { ExportToXlsxService } from 'ClientApp/app/servicios/exportToXls';
import { filter, map } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter,ViewChild, Renderer2 } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Observable, of } from 'rxjs';
import { Sociedad } from 'ClientApp/app/entidades/sociedad';
import { Servicios } from 'ClientApp/app/servicios/servicios';
import { DatePipe } from '@angular/common';
import { MascaraMoneda } from '../../compartida/mascara-moneda';


@Component({
  selector: 'app-reporte-facturas',
  templateUrl: './reporte-facturas.component.html',
  styleUrls: ['./reporte-facturas.component.css']
})
export class ReporteFacturasComponent implements OnInit {
  Procesando: boolean;
  Activo: boolean = false;
  @Output() xmlOprimido: EventEmitter<any> = new EventEmitter<any>();	
	@ViewChild(DataTableDirective) dtElement: DataTableDirective ;
	reloadTrigger: Subject<any> = new Subject<any>();
  cargaInicial = false;
  titulo: string = 'XML a Excel';
  private oyente: () => void;


  
  origenDatos:String = 'sistema';
 
  archivosXML: any[] = [];
  sociedades: Sociedad[] = [];
  sociedadComercial: any; 
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  archivos: any[] = [];
  origenes = [
    {
      value: 'sistema',
      display: 'Sistema'      
    },
    {
      value: 'directorio',
      display: 'Directorio Local'      
    }
  ];
  meses = [
    {
      value: '01',
      display: 'ENERO'
    },
    {
      value: '02',
      display: 'FEBRERO'
    },
    {
      value: '03',
      display: 'MARZO'
    },
    {
      value: '04',
      display: 'ABRÍL'
    },
    {
      value: '05',
      display: 'MAYO'
    },
    {
      value: '06',
      display: 'JUNIO'
    },
    {
      value: '07',
      display: 'JULIO'
    },
    {
      value: '08',
      display: 'AGOSTO'
    },
    {
      value: '09',
      display: 'SEPTIEMBRE'
    },
    {
      value: '10',
      display: 'OCTUBRE'
    },
    {
      value: '11',
      display: 'NOVIEMBRE'
    },
    {
      value: '12',
      display: 'DICIEMBRE'
    }
  ]
  mes;
  anios: any[];
  anio;
  criterioBusqueda;
  constructor(
    private servicios: Servicios,
    private renderer: Renderer2,
    public datepipe: DatePipe,
    private exportToXlsxService: ExportToXlsxService
  ) { }

  ngOnInit() {
    this.origenDatos = 'sistema';
    const fechaActual = new Date();
    this.anios = []
    for(var i = fechaActual.getFullYear()-3; i <=  fechaActual.getFullYear(); i++) {
      this.anios.push(
        {
          value: String(i),
          display: String(i)
        }
      )
    }
     
    this.cargarTabla();        
    this.Procesando = true; 
    this.cargarSociedades();
  }

  establecerOrigen() {
    
    this.limpiar();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
		this.renderer.destroy();
    this.oyente = this.renderer.listen('body', 'click', (event) => {
			let objetivo = <HTMLElement>event.target;						
		});
  }

 

  establecerFechasMes() {
		// Obtener la fecha actual
		const fechaActual = new Date();

		// Obtener el primer día del mes actual
		const primerDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
		const inicioMes = primerDiaMes.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

		// Obtener el último día del mes actual
		const ultimoDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
		const finMes = ultimoDiaMes.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
	}

  cargarSociedades() {
		this.servicios.sociedades.obtenerTodos().subscribe((lista) => {
			this.sociedades = lista;      
		});
	}

  buscar() {  
    this.criterioBusqueda = {
      sociedadComercial: this.sociedadComercial ? this.sociedadComercial : null,
      fechaDesde: null,
      fechaHasta: null
    };
    if (this.anio && this.mes) {
      const fecha = new Date(Number(this.anio), Number(this.mes)-1, 1);
      
      const primerDiaMes = new Date(fecha.getFullYear(), fecha.getMonth(), 1);
      this.criterioBusqueda.fechaDesde = primerDiaMes;        
      const ultimoDiaMes = new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0);        
      this.criterioBusqueda.fechaHasta = ultimoDiaMes
      
    } 
    this.Procesando = true;				
		this.recargarTabla();
  }

  
	public recargarTabla(): void {
    this.cargaInicial = true;
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {										
      dtInstance.ajax.reload();									
    });
		
	}

  public normalizarArchivos(archivos: any): Promise<any> {		
		return new Promise(async (resolve, reject) => {					
			const promiseNormalizarArchivos = [];		
     
        for(const archivo of archivos) {
          if (this.origenDatos === 'sistema') {
            promiseNormalizarArchivos.push(this.normalizarArchivo(archivo));
          } else {
            promiseNormalizarArchivos.push(this.normalizarArchivoLocal(archivo));
          }          
        }
        Promise.all(promiseNormalizarArchivos)
        .then(archivosCompletos => {  
          if (this.origenDatos === 'directorio') {
            this.archivosXML = [];
          }
          resolve(archivosCompletos.filter(mFacturav=> mFacturav));
        })
        .catch(error => {
          reject(error);
        });			
		});
	}

  public normalizarArchivo(archivo: any): Promise<any> {	
		return new Promise(async (resolve, reject) => {
      if (!archivo.xml || String(archivo.xml).length <= 10) {
        resolve(null);
      }
      try {                         
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(archivo.xml, 'text/xml');                  
        const emisor = xmlDoc.getElementsByTagName('cfdi:Emisor');
        const attributesEmisor = emisor[0].attributes;
        const receptor = xmlDoc.getElementsByTagName('cfdi:Receptor');
        const attributesReceptor = receptor[0].attributes;
        const comprobante = xmlDoc.getElementsByTagName('cfdi:Comprobante');
        const attributesComprobante = comprobante[0].attributes;
        const timbreFiscalDigital = xmlDoc.getElementsByTagName('tfd:TimbreFiscalDigital');
        const attributesTimbreFiscalDigital = timbreFiscalDigital[0].attributes;
        const cfdiRelacionados = xmlDoc.getElementsByTagName('cfdi:CfdiRelacionados');
        const attributesCfdiRelacionados = cfdiRelacionados && cfdiRelacionados[0] ? cfdiRelacionados[0].attributes : null;
        const cfdiRelacionado = xmlDoc.getElementsByTagName('cfdi:CfdiRelacionado');
        const attributesCfdiRelacionado = cfdiRelacionado && cfdiRelacionado[0]? cfdiRelacionado[0].attributes : null;        

        const promiseImpuestos: any = new Promise (async (resolveImpuestos: any) => {         
          const promiseNodoImpuestos: any = new Promise (async (resolveNodoImpuestos: any) => {   
            const root = xmlDoc.documentElement;
            const comprobante = xmlDoc.getElementsByTagName('cfdi:Comprobante');
            //const childs = Array.from(comprobante[0].children);
            const childs = Array.from(comprobante[0].children);
            let nodoImpuestos = {
              totalImpuestosTrasladados: 0,
              totalImpuestosRetenidos: 0,  
              traslados: [],
              retenciones: []              
            }            
            for (const childElement of childs) {
              if (childElement.tagName ==='cfdi:Impuestos') {                  
                const childsLeve1 = Array.from(childElement.children);
                for(const chidl1 of childsLeve1) {
                  if (chidl1.tagName === 'cfdi:Retenciones') {
                    const childsRetenciones = Array.from(chidl1.children);
                    for (const childRetencion of childsRetenciones) {
                      const retencion = {
                        Impuesto: '',
                        TipoFactor: '',
                        TasaOCuota: '',
                        Importe: 0
                      }
                      for (let i = 0; i < childRetencion.attributes.length; i++) { 
                        const attribute = childRetencion.attributes[i];                        
                        if (attribute.name === 'Impuesto') {
                          retencion.Impuesto =  attribute.value;
                        } else if (attribute.name === 'TipoFactor') {
                          retencion.TipoFactor =  attribute.value;
                        } else if (attribute.name === 'TasaOCuota') {
                          retencion.TasaOCuota =  attribute.value;
                        } else if (attribute.name === 'Importe') {
                          retencion.Importe = Number(attribute.value);
                        }
                      }
                      nodoImpuestos.retenciones.push(retencion);
                    }
                  } else if(chidl1.tagName === 'cfdi:Traslados') {
                    const childsTraslados = Array.from(chidl1.children);
                    for (const childTraslado of childsTraslados) {
                      const traslado = {
                        Impuesto: '',
                        TipoFactor: '',
                        TasaOCuota: '',
                        Importe: 0
                      }
                      for (let i = 0; i < childTraslado.attributes.length; i++) { 
                        const attribute = childTraslado.attributes[i];                        
                        if (attribute.name === 'Impuesto') {
                          traslado.Impuesto =  attribute.value;
                        } else if (attribute.name === 'TipoFactor') {
                          traslado.TipoFactor =  attribute.value;
                        } else if (attribute.name === 'TasaOCuota') {
                          traslado.TasaOCuota = attribute.value;
                        } else if (attribute.name === 'Importe') {
                          traslado.Importe = Number(attribute.value);
                        }

                      }
                     
                      nodoImpuestos.traslados.push(traslado);
                    }
                  }                                        
                }
                const attributes = childElement.attributes;
                for (let i = 0; i < attributes.length; i++) {
                  const attribute = attributes[i];                   
                  const attributeValue = attribute.value;
                  if (attribute.name === 'TotalImpuestosRetenidos') {
                    nodoImpuestos.totalImpuestosRetenidos =  Number(attributeValue);
                  } else if (attribute.name === 'TotalImpuestosTrasladados') {
                    nodoImpuestos.totalImpuestosTrasladados =  Number(attributeValue);
                  }                                        
                }
              }                
            }    
            resolveNodoImpuestos(nodoImpuestos);                
          });

          const promiseNodoImpuestosLocales: any = new Promise (async (resolveNodoImpuestosLocales: any) => {              
            const impuestosLocales = Array.from(xmlDoc.getElementsByTagName('implocal:ImpuestosLocales'));              
            const nodoImpuestosLocales = {
              totalRetenciones: 0,
              totalTraslados:0,
              ish: 0
            }
            if (impuestosLocales && impuestosLocales.length > 0 ) {
              for(const impuestoLocal of impuestosLocales) {
                const attributesImpuestosLocales = impuestoLocal.attributes;
                nodoImpuestosLocales.totalRetenciones = attributesImpuestosLocales['TotaldeRetenciones'] ? Number(attributesImpuestosLocales['TotaldeRetenciones'].textContent) : nodoImpuestosLocales.totalRetenciones;
                nodoImpuestosLocales.totalTraslados = attributesImpuestosLocales['TotaldeTraslados'] ? Number(attributesImpuestosLocales['TotaldeTraslados'].textContent) : nodoImpuestosLocales.totalTraslados;
                const childs = Array.from(impuestoLocal.children);
                for(const child of childs) {
                  const childAtributes = child.attributes
                  nodoImpuestosLocales.ish = childAtributes['ImpLocTrasladado']  && childAtributes['Importe'] ? childAtributes['ImpLocTrasladado'].textContent === 'ISH' ? Number(childAtributes['Importe'].textContent): nodoImpuestosLocales.ish : nodoImpuestosLocales.ish;
                }
                
              }
              resolveNodoImpuestosLocales(nodoImpuestosLocales);
            } else {
              resolveNodoImpuestosLocales(nodoImpuestosLocales);
            }
          });
          
          const nodoImpuestos = await promiseNodoImpuestos;           
          const nodoImpuestosLocales = await promiseNodoImpuestosLocales;            
          const promiseTotalIEPS: any = new Promise (async (resolveTotalEIPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003");
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveTotalEIPS(totalIEPS);
          });

          const promiseIVA8: any = new Promise (async (resolveIVA: any) => {
            let totalIVA = 0;
            const trasladosIVA = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.080000");              
            if (trasladosIVA.length > 0) {
              for(const traslado of trasladosIVA ) {
                totalIVA += traslado.Importe;
              }
            }
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.080000");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                totalIVA += retencion.Importe;
              }
            }
            resolveIVA(totalIVA);
          });

          const promiseIVA6Ret: any = new Promise (async (resolveIVA: any) => {
            let totalIVA = 0;            
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.060000");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                totalIVA += retencion.Importe;
              }
            }
            resolveIVA(totalIVA);
          });

          const promiseIVA16: any = new Promise (async (resolveIVA16: any) => {
            let totalIVA16 = 0;
            const trasladosIVA16 = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.160000");              
            if (trasladosIVA16.length > 0) {
              for(const traslado of trasladosIVA16 ) {
                totalIVA16 += traslado.Importe;
              }
            }
            const retencionesIVA16 = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.160000");
            if (retencionesIVA16.length > 0) {
              for(const retencion of retencionesIVA16 ) {
                totalIVA16 += retencion.Importe;
              }
            }
            resolveIVA16(totalIVA16);
          });

          const promiseRetenidoIVA: any = new Promise (async (resolveRetenidoIVA: any) => {
            let retenidoIVA = 0;
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                retenidoIVA += retencion.Importe;
              }
            }
            resolveRetenidoIVA(retenidoIVA);
          });

          const promiseRetenidoISR: any = new Promise (async (resolveRetenidoISR: any) => {
            let retenidoIRS = 0;
            const retencionesISR = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "001");
            if (retencionesISR.length > 0) {
              for(const retencion of retencionesISR ) {
                retenidoIRS += retencion.Importe;
              }
            }
            resolveRetenidoISR(retenidoIRS);
          });
          
          const promiseIEPS3: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.030000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.030000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS6: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.060000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.060000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS7: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.070000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.070000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS8: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.080000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.080000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS9: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.090000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.090000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS265: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.265000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.265000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS30: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.300000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.300000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS34: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.340000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.340000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS53: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.530000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.530000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS160: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "1.600000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "1.600000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });           

          const impuestos = {
            totalIEPS: await promiseTotalIEPS,
            iva8 : await promiseIVA8,
            iva16: await promiseIVA16,
            ivat6Ret: await promiseIVA6Ret,
            retenidoIVA: await promiseRetenidoIVA,
            retenidoISR: await promiseRetenidoISR,
            totalTraslados: nodoImpuestos.totalImpuestosTrasladados,
            totalRetenidos: nodoImpuestos.totalImpuestosRetenidos,
            ieps3: await promiseIEPS3,
            ieps6: await promiseIEPS6,
            ieps7: await promiseIEPS7,
            ieps8: await promiseIEPS8,
            ieps9: await promiseIEPS9,
            ieps265: await promiseIEPS265,
            ieps30: await promiseIEPS30,
            ieps34: await promiseIEPS34,
            ieps53: await promiseIEPS53,
            ieps160: await promiseIEPS160,
            totalTrasladosLocales: nodoImpuestosLocales.totalTraslados,
            totalRetencionesLocales: nodoImpuestosLocales.totalRetenciones,
            ish: nodoImpuestosLocales.ish

          }
          resolveImpuestos(impuestos);
         
        });                    
        
        const impuestos = await promiseImpuestos;                                           
        
        const factura = {            
          //"Verificado o Asociado": 'SD', //DUDA          
          "Estado SAT": archivo.estado,
          "Estado Validación": archivo.codigoEstado,
          "No Certificado Emisor": attributesComprobante['NoCertificado'] ? attributesComprobante['NoCertificado'].textContent : '', 
          "No Certificado SAT": attributesTimbreFiscalDigital['NoCertificadoSAT'] ? attributesTimbreFiscalDigital['NoCertificadoSAT'].textContent : '',
          "Versión":  attributesComprobante['Version'] ? attributesComprobante['Version'].textContent : attributesComprobante['version'] ? attributesComprobante['version'].textContent : '', 
          "TipoComprobante": attributesComprobante['TipoDeComprobante'] ? attributesComprobante['TipoDeComprobante'].textContent : '', 
          //"Tipo": 'Factura/NotaCredido',
          "Fecha Emisión": attributesComprobante['Fecha'] ? attributesComprobante['Fecha'].textContent : '', 
          "Fecha Timbrado": attributesTimbreFiscalDigital['FechaTimbrado'] ? attributesTimbreFiscalDigital['FechaTimbrado'].textContent : '', 
          "Año": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(0,4)),
          "Mes": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(5,7)),
          "Dia": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(8,10)),
          //"Estado Pago": 'SD', //DUDA
          //"Fecha Pago": 'SD', //DUDA
          
          "Serie": attributesComprobante['Serie'] ? attributesComprobante['Serie'].textContent : attributesComprobante['serie'] ? attributesComprobante['serie'].textContent : '', 
          "Folio": attributesComprobante['Folio'] ? attributesComprobante['Folio'].textContent : attributesComprobante['folio'] ? attributesComprobante['folio'].textContent : '', 
          "UUID": attributesTimbreFiscalDigital['UUID'] ? attributesTimbreFiscalDigital['UUID'].textContent : '',
          "Tipo Relación": cfdiRelacionados && attributesCfdiRelacionados && attributesCfdiRelacionados['TipoRelacion'] ? await this.getTipoRelacion(attributesCfdiRelacionados['TipoRelacion'].textContent): '',
          "UUID Relación": cfdiRelacionado && attributesCfdiRelacionado && attributesCfdiRelacionado['UUID'] ? attributesCfdiRelacionado['UUID'].textContent : '',
          "RFC Emisor": attributesEmisor['Rfc'] ? attributesEmisor['Rfc'].textContent : attributesEmisor['rfc'] ? attributesEmisor['rfc'].textContent : '',
          "Nombre Emisor": attributesEmisor['Nombre'] ? attributesEmisor['Nombre'].textContent : attributesEmisor['nombre'] ? attributesEmisor['nombre'].textContent : '',
          "Regimen Fiscal": attributesEmisor['RegimenFiscal'] ? attributesEmisor['RegimenFiscal'].textContent :  '',
          "Lugar Expedición": attributesComprobante['LugarExpedicion'] ? attributesComprobante['LugarExpedicion'].textContent :  '', 
          "RFC Receptor": attributesReceptor['Rfc'] ? attributesReceptor['Rfc'].textContent : attributesReceptor['rfc'] ? attributesReceptor['rfc'].textContent : '',
          "Nombre Receptor": attributesReceptor['Nombre'] ? attributesReceptor['Nombre'].textContent : attributesReceptor['nombre'] ? attributesReceptor['nombre'].textContent : '',
          
          //"Residencia Fiscal": 'SD', //DUDA
          //"NumRegIdTrib": 'SD', //DUDA
         
          "Uso CDFI": attributesReceptor['UsoCFDI'] ? await this.getUsoCFDI(attributesReceptor['UsoCFDI'].textContent) : '',
          "Total IEPS": impuestos.totalIEPS,
          "Total": attributesComprobante['Total'] ? Number(attributesComprobante['Total'].textContent) : attributesComprobante['total'] ? Number(attributesComprobante['total'].textContent) : '',
          "SubTotal":  attributesComprobante['SubTotal'] ? Number(attributesComprobante['SubTotal'].textContent) : '', 
          "Descuento": attributesComprobante['Descuento'] ? Number(attributesComprobante['Descuento'].textContent) : 0,          
          "IVA 16%": impuestos.iva16,
          "IVA 8%": impuestos.iva8, 
          "IVA Ret 6%": impuestos.ivat6Ret,
          "Retenido IVA": impuestos.retenidoIVA,
          "Retenido ISR": impuestos.retenidoISR,
          "ISH": impuestos.ish, 
          
         
          //"Total Original": 'SD', // DUDA
          
          "Total Trasladados": impuestos.totalTraslados,
          "Total Retenidos":impuestos.totalRetenidos,
          "Total Local Trasladado": impuestos.totalTrasladosLocales, 
          "Total Local Retenido": impuestos.totalRetencionesLocales,
          //"Complemento": 'SD', // DUDA
          "Moneda": attributesComprobante['Moneda'] ? attributesComprobante['Moneda'].textContent : '', 
          "Tipo de Cambio": attributesComprobante['TipoCambio'] ? attributesComprobante['TipoCambio'].textContent : '', 
          "Forma de Pago":  attributesComprobante['FormaPago'] ? await this.geFormaPago(attributesComprobante['FormaPago'].textContent) : '',
          "Metodo de Pago": attributesComprobante['MetodoPago'] ? attributesComprobante['MetodoPago'].textContent === 'PUE' ? String('PUE-Pago en una sola exhibición').toUpperCase() : String('PPD-Pago en parcialidades o diferido').toUpperCase() : '', 
          //"NumCtaPago": 'SD', // DUDA
          "Condición de Pago":  attributesComprobante['CondicionesDePago'] ? attributesComprobante['CondicionesDePago'].textContent : '', 
          "Conceptos": await this.obtenerConceptos(xmlDoc),
          
          //"Combustible": '', 
          "IEPS 3%" : impuestos.ieps3,
          "IEPS 6%" : impuestos.ieps6,
          "IEPS 7%" : impuestos.ieps7,
          "IEPS 8%" : impuestos.ieps8,
          "IEPS 9%" : impuestos.ieps9,
          "IEPS 265%" : impuestos.ieps265,
          "IEPS 30%" : impuestos.ieps30,
          "IEPS 304%": impuestos.ieps34,
          "IEPS 53%" : impuestos.ieps53, 
          "IEPS 160%" : impuestos.ieps160,
          "Archivo XML": archivo.nombreArchivo
          //"Direccion Emisor": 'SD', // DUDA
          //"Localidad Emisor": 'SD', // DUDA
          //"Direccion Receptor": 'SD', // DUDA
          //"Localidad Receptor": 'SD', // DUDA
         
        }        
        resolve(factura) ;
      
      }
      catch(error){
        
        resolve(null);        
      }
    });
  }


  public normalizarArchivoLocal(archivo: any ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {                         
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(archivo.xml, 'text/xml');                  
        const emisor = xmlDoc.getElementsByTagName('cfdi:Emisor');
        const attributesEmisor = emisor[0].attributes;
        const receptor = xmlDoc.getElementsByTagName('cfdi:Receptor');
        const attributesReceptor = receptor[0].attributes;
        const comprobante = xmlDoc.getElementsByTagName('cfdi:Comprobante');
        const attributesComprobante = comprobante[0].attributes;
        const timbreFiscalDigital = xmlDoc.getElementsByTagName('tfd:TimbreFiscalDigital');
        const attributesTimbreFiscalDigital = timbreFiscalDigital[0].attributes;
        const cfdiRelacionados = xmlDoc.getElementsByTagName('cfdi:CfdiRelacionados');
        const attributesCfdiRelacionados = cfdiRelacionados && cfdiRelacionados[0] ? cfdiRelacionados[0].attributes : null;
        const cfdiRelacionado = xmlDoc.getElementsByTagName('cfdi:CfdiRelacionado');
        const attributesCfdiRelacionado = cfdiRelacionado && cfdiRelacionado[0]? cfdiRelacionado[0].attributes : null;        

        const promiseImpuestos: any = new Promise (async (resolveImpuestos: any) => {         
          const promiseNodoImpuestos: any = new Promise (async (resolveNodoImpuestos: any) => {   
            const root = xmlDoc.documentElement;
            const comprobante = xmlDoc.getElementsByTagName('cfdi:Comprobante');
            //const childs = Array.from(comprobante[0].children);
            const childs = Array.from(comprobante[0].children);
            let nodoImpuestos = {
              totalImpuestosTrasladados: 0,
              totalImpuestosRetenidos: 0,  
              traslados: [],
              retenciones: []              
            }            
            for (const childElement of childs) {
              if (childElement.tagName ==='cfdi:Impuestos') {                  
                const childsLeve1 = Array.from(childElement.children);
                for(const chidl1 of childsLeve1) {
                  if (chidl1.tagName === 'cfdi:Retenciones') {
                    const childsRetenciones = Array.from(chidl1.children);
                    for (const childRetencion of childsRetenciones) {
                      const retencion = {
                        Impuesto: '',
                        TipoFactor: '',
                        TasaOCuota: '',
                        Importe: 0
                      }
                      for (let i = 0; i < childRetencion.attributes.length; i++) { 
                        const attribute = childRetencion.attributes[i];                        
                        if (attribute.name === 'Impuesto') {
                          retencion.Impuesto =  attribute.value;
                        } else if (attribute.name === 'TipoFactor') {
                          retencion.TipoFactor =  attribute.value;
                        } else if (attribute.name === 'TasaOCuota') {
                          retencion.TasaOCuota =  attribute.value;
                        } else if (attribute.name === 'Importe') {
                          retencion.Importe = Number(attribute.value);
                        }
                      }
                      nodoImpuestos.retenciones.push(retencion);
                    }
                  } else if(chidl1.tagName === 'cfdi:Traslados') {
                    const childsTraslados = Array.from(chidl1.children);
                    for (const childTraslado of childsTraslados) {
                      const traslado = {
                        Impuesto: '',
                        TipoFactor: '',
                        TasaOCuota: '',
                        Importe: 0
                      }
                      for (let i = 0; i < childTraslado.attributes.length; i++) { 
                        const attribute = childTraslado.attributes[i];                        
                        if (attribute.name === 'Impuesto') {
                          traslado.Impuesto =  attribute.value;
                        } else if (attribute.name === 'TipoFactor') {
                          traslado.TipoFactor =  attribute.value;
                        } else if (attribute.name === 'TasaOCuota') {
                          traslado.TasaOCuota = attribute.value;
                        } else if (attribute.name === 'Importe') {
                          traslado.Importe = Number(attribute.value);
                        }

                      }
                     
                      nodoImpuestos.traslados.push(traslado);
                    }
                  }                                        
                }
                const attributes = childElement.attributes;
                for (let i = 0; i < attributes.length; i++) {
                  const attribute = attributes[i];                   
                  const attributeValue = attribute.value;
                  if (attribute.name === 'TotalImpuestosRetenidos') {
                    nodoImpuestos.totalImpuestosRetenidos =  Number(attributeValue);
                  } else if (attribute.name === 'TotalImpuestosTrasladados') {
                    nodoImpuestos.totalImpuestosTrasladados =  Number(attributeValue);
                  }                                        
                }
              }                
            }    
            resolveNodoImpuestos(nodoImpuestos);                
          });

          const promiseNodoImpuestosLocales: any = new Promise (async (resolveNodoImpuestosLocales: any) => {              
            const impuestosLocales = Array.from(xmlDoc.getElementsByTagName('implocal:ImpuestosLocales'));              
            const nodoImpuestosLocales = {
              totalRetenciones: 0,
              totalTraslados:0,
              ish: 0
            }
            if (impuestosLocales && impuestosLocales.length > 0 ) {
              for(const impuestoLocal of impuestosLocales) {
                const attributesImpuestosLocales = impuestoLocal.attributes;
                nodoImpuestosLocales.totalRetenciones = attributesImpuestosLocales['TotaldeRetenciones'] ? Number(attributesImpuestosLocales['TotaldeRetenciones'].textContent) : nodoImpuestosLocales.totalRetenciones;
                nodoImpuestosLocales.totalTraslados = attributesImpuestosLocales['TotaldeTraslados'] ? Number(attributesImpuestosLocales['TotaldeTraslados'].textContent) : nodoImpuestosLocales.totalTraslados;
                const childs = Array.from(impuestoLocal.children);
                for(const child of childs) {
                  const childAtributes = child.attributes
                  nodoImpuestosLocales.ish = childAtributes['ImpLocTrasladado']  && childAtributes['Importe'] ? childAtributes['ImpLocTrasladado'].textContent === 'ISH' ? Number(childAtributes['Importe'].textContent): nodoImpuestosLocales.ish : nodoImpuestosLocales.ish;
                }
                
              }
              resolveNodoImpuestosLocales(nodoImpuestosLocales);
            } else {
              resolveNodoImpuestosLocales(nodoImpuestosLocales);
            }
          });
          
          const nodoImpuestos = await promiseNodoImpuestos;           
          const nodoImpuestosLocales = await promiseNodoImpuestosLocales;            
          const promiseTotalIEPS: any = new Promise (async (resolveTotalEIPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003");
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveTotalEIPS(totalIEPS);
          });

          const promiseIVA8: any = new Promise (async (resolveIVA: any) => {
            let totalIVA = 0;
            const trasladosIVA = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.080000");              
            if (trasladosIVA.length > 0) {
              for(const traslado of trasladosIVA ) {
                totalIVA += traslado.Importe;
              }
            }
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.080000");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                totalIVA += retencion.Importe;
              }
            }
            resolveIVA(totalIVA);
          });

          const promiseIVA6Ret: any = new Promise (async (resolveIVA: any) => {
            let totalIVA = 0;            
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.060000");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                totalIVA += retencion.Importe;
              }
            }
            resolveIVA(totalIVA);
          });

          const promiseIVA16: any = new Promise (async (resolveIVA16: any) => {
            let totalIVA16 = 0;
            const trasladosIVA16 = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.160000");              
            if (trasladosIVA16.length > 0) {
              for(const traslado of trasladosIVA16 ) {
                totalIVA16 += traslado.Importe;
              }
            }
            const retencionesIVA16 = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002" && mTraslado.TasaOCuota === "0.160000");
            if (retencionesIVA16.length > 0) {
              for(const retencion of retencionesIVA16 ) {
                totalIVA16 += retencion.Importe;
              }
            }
            resolveIVA16(totalIVA16);
          });

          const promiseRetenidoIVA: any = new Promise (async (resolveRetenidoIVA: any) => {
            let retenidoIVA = 0;
            const retencionesIVA = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "002");
            if (retencionesIVA.length > 0) {
              for(const retencion of retencionesIVA ) {
                retenidoIVA += retencion.Importe;
              }
            }
            resolveRetenidoIVA(retenidoIVA);
          });

          const promiseRetenidoISR: any = new Promise (async (resolveRetenidoISR: any) => {
            let retenidoIRS = 0;
            const retencionesISR = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "001");
            if (retencionesISR.length > 0) {
              for(const retencion of retencionesISR ) {
                retenidoIRS += retencion.Importe;
              }
            }
            resolveRetenidoISR(retenidoIRS);
          });
          
          const promiseIEPS3: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.030000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.030000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS6: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.060000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.060000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS7: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.070000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.070000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS8: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.080000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.080000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS9: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.090000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.090000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS265: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.265000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.265000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS30: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.300000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.300000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS34: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.340000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.340000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS53: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.530000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "0.530000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });

          const promiseIEPS160: any = new Promise (async (resolveIEPS: any) => {
            let totalIEPS = 0;
            const trasladosIEPS = nodoImpuestos.traslados.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "1.600000");              
            if (trasladosIEPS.length > 0) {
              for(const traslado of trasladosIEPS ) {
                totalIEPS += traslado.Importe;
              }
            }
            const retencionesIEPS = nodoImpuestos.retenciones.filter(mTraslado => mTraslado.Impuesto === "003" && mTraslado.TasaOCuota === "1.600000");
            if (retencionesIEPS.length > 0) {
              for(const retencion of retencionesIEPS ) {
                totalIEPS += retencion.Importe;
              }
            }
            resolveIEPS(totalIEPS)
          });           

          const impuestos = {
            totalIEPS: await promiseTotalIEPS,
            iva8 : await promiseIVA8,
            iva16: await promiseIVA16,
            ivat6Ret: await promiseIVA6Ret,
            retenidoIVA: await promiseRetenidoIVA,
            retenidoISR: await promiseRetenidoISR,
            totalTraslados: nodoImpuestos.totalImpuestosTrasladados,
            totalRetenidos: nodoImpuestos.totalImpuestosRetenidos,
            ieps3: await promiseIEPS3,
            ieps6: await promiseIEPS6,
            ieps7: await promiseIEPS7,
            ieps8: await promiseIEPS8,
            ieps9: await promiseIEPS9,
            ieps265: await promiseIEPS265,
            ieps30: await promiseIEPS30,
            ieps34: await promiseIEPS34,
            ieps53: await promiseIEPS53,
            ieps160: await promiseIEPS160,
            totalTrasladosLocales: nodoImpuestosLocales.totalTraslados,
            totalRetencionesLocales: nodoImpuestosLocales.totalRetenciones,
            ish: nodoImpuestosLocales.ish

          }
          resolveImpuestos(impuestos);
         
        });                    
        
        const cfdi = {
          RFC_EMISOR: attributesEmisor['Rfc'] ? attributesEmisor['Rfc'].textContent : attributesEmisor['rfc'] ? attributesEmisor['rfc'].textContent : '',
          RFC_RECEPTOR: attributesReceptor['Rfc'] ? attributesReceptor['Rfc'].textContent : attributesReceptor['rfc'] ? attributesReceptor['rfc'].textContent : '',
          SERIE:  attributesComprobante['Serie'] ? attributesComprobante['Serie'].textContent : attributesComprobante['serie'] ? attributesComprobante['serie'] : '', 
          FOLIO:  attributesComprobante['Folio'] ? attributesComprobante['Folio'].textContent : attributesComprobante['folio'] ? attributesComprobante['folio'] : '', 
          TOTAL:  attributesComprobante['Total'] ? attributesComprobante['Total'].textContent : attributesComprobante['total'] ? attributesComprobante['total'] : '', 
          UUID: attributesTimbreFiscalDigital['UUID'] ? attributesTimbreFiscalDigital['UUID'].textContent : ''
        }                      

        const validacion = await this.validarCFDI(cfdi); 
        const impuestos = await promiseImpuestos;                                         
        
        const factura = {            
          //"Verificado o Asociado": 'SD', //DUDA          
          "Estado SAT": validacion && validacion.estado ? String(validacion.estado).toUpperCase(): '',
          "Estado Validación": validacion && validacion. codigO_ESTATUS ? String(validacion.codigO_ESTATUS).toUpperCase(): '',
          "No Certificado Emisor": attributesComprobante['NoCertificado'] ? attributesComprobante['NoCertificado'].textContent : '', 
          "No Certificado SAT": attributesTimbreFiscalDigital['NoCertificadoSAT'] ? attributesTimbreFiscalDigital['NoCertificadoSAT'].textContent : '',
          "Versión":  attributesComprobante['Version'] ? attributesComprobante['Version'].textContent : attributesComprobante['version'] ? attributesComprobante['version'].textContent : '', 
          "TipoComprobante": attributesComprobante['TipoDeComprobante'] ? attributesComprobante['TipoDeComprobante'].textContent : '', 
          //"Tipo": 'Factura/NotaCredido',
          "Fecha Emisión": attributesComprobante['Fecha'] ? attributesComprobante['Fecha'].textContent : '', 
          "Fecha Timbrado": attributesTimbreFiscalDigital['FechaTimbrado'] ? attributesTimbreFiscalDigital['FechaTimbrado'].textContent : '', 
          "Año": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(0,4)),
          "Mes": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(5,7)),
          "Dia": Number(String(attributesTimbreFiscalDigital['FechaTimbrado'].textContent).substring(8,10)),
          //"Estado Pago": 'SD', //DUDA
          //"Fecha Pago": 'SD', //DUDA
          
          "Serie": attributesComprobante['Serie'] ? attributesComprobante['Serie'].textContent : attributesComprobante['serie'] ? attributesComprobante['serie'].textContent : '', 
          "Folio": attributesComprobante['Folio'] ? attributesComprobante['Folio'].textContent : attributesComprobante['folio'] ? attributesComprobante['folio'].textContent : '', 
          "UUID": attributesTimbreFiscalDigital['UUID'] ? attributesTimbreFiscalDigital['UUID'].textContent : '',
          "Tipo Relación": cfdiRelacionados && attributesCfdiRelacionados && attributesCfdiRelacionados['TipoRelacion'] ? await this.getTipoRelacion(attributesCfdiRelacionados['TipoRelacion'].textContent): '',
          "UUID Relación": cfdiRelacionado && attributesCfdiRelacionado && attributesCfdiRelacionado['UUID'] ? attributesCfdiRelacionado['UUID'].textContent : '',
          "RFC Emisor": attributesEmisor['Rfc'] ? attributesEmisor['Rfc'].textContent : attributesEmisor['rfc'] ? attributesEmisor['rfc'].textContent : '',
          "Nombre Emisor": attributesEmisor['Nombre'] ? attributesEmisor['Nombre'].textContent : attributesEmisor['nombre'] ? attributesEmisor['nombre'].textContent : '',
          "Regimen Fiscal": attributesEmisor['RegimenFiscal'] ? attributesEmisor['RegimenFiscal'].textContent :  '',
          "Lugar Expedición": attributesComprobante['LugarExpedicion'] ? attributesComprobante['LugarExpedicion'].textContent :  '', 
          "RFC Receptor": attributesReceptor['Rfc'] ? attributesReceptor['Rfc'].textContent : attributesReceptor['rfc'] ? attributesReceptor['rfc'].textContent : '',
          "Nombre Receptor": attributesReceptor['Nombre'] ? attributesReceptor['Nombre'].textContent : attributesReceptor['nombre'] ? attributesReceptor['nombre'].textContent : '',
          
          //"Residencia Fiscal": 'SD', //DUDA
          //"NumRegIdTrib": 'SD', //DUDA
         
          "Uso CDFI": attributesReceptor['UsoCFDI'] ? await this.getUsoCFDI(attributesReceptor['UsoCFDI'].textContent) : '',
          "Total IEPS": impuestos.totalIEPS,
          "Total": attributesComprobante['Total'] ? Number(attributesComprobante['Total'].textContent) : attributesComprobante['total'] ? Number(attributesComprobante['total'].textContent) : '',
          "SubTotal":  attributesComprobante['SubTotal'] ? Number(attributesComprobante['SubTotal'].textContent) : '', 
          "Descuento": attributesComprobante['Descuento'] ? Number(attributesComprobante['Descuento'].textContent) : 0,          
          "IVA 16%": impuestos.iva16,
          "IVA 8%": impuestos.iva8, 
          "IVA Ret 6%": impuestos.ivat6Ret,
          "Retenido IVA": impuestos.retenidoIVA,
          "Retenido ISR": impuestos.retenidoISR,
          "ISH": impuestos.ish, 
          
         
          //"Total Original": 'SD', // DUDA
          
          "Total Trasladados": impuestos.totalTraslados,
          "Total Retenidos":impuestos.totalRetenidos,
          "Total Local Trasladado": impuestos.totalTrasladosLocales, 
          "Total Local Retenido": impuestos.totalRetencionesLocales,
          //"Complemento": 'SD', // DUDA
          "Moneda": attributesComprobante['Moneda'] ? attributesComprobante['Moneda'].textContent : '', 
          "Tipo de Cambio": attributesComprobante['TipoCambio'] ? attributesComprobante['TipoCambio'].textContent : '', 
          "Forma de Pago":  attributesComprobante['FormaPago'] ? await this.geFormaPago(attributesComprobante['FormaPago'].textContent) : '',
          "Metodo de Pago": attributesComprobante['MetodoPago'] ? attributesComprobante['MetodoPago'].textContent === 'PUE' ? String('PUE-Pago en una sola exhibición').toUpperCase() : String('PPD-Pago en parcialidades o diferido').toUpperCase() : '', 
          //"NumCtaPago": 'SD', // DUDA
          "Condición de Pago":  attributesComprobante['CondicionesDePago'] ? attributesComprobante['CondicionesDePago'].textContent : '', 
          "Conceptos": await this.obtenerConceptos(xmlDoc),
          
          //"Combustible": '', 
          "IEPS 3%" : impuestos.ieps3,
          "IEPS 6%" : impuestos.ieps6,
          "IEPS 7%" : impuestos.ieps7,
          "IEPS 8%" : impuestos.ieps8,
          "IEPS 9%" : impuestos.ieps9,
          "IEPS 265%" : impuestos.ieps265,
          "IEPS 30%" : impuestos.ieps30,
          "IEPS 304%": impuestos.ieps34,
          "IEPS 53%" : impuestos.ieps53, 
          "IEPS 160%" : impuestos.ieps160,
          "Archivo XML": archivo.nombreArchivo,
          //"Direccion Emisor": 'SD', // DUDA
          //"Localidad Emisor": 'SD', // DUDA
          //"Direccion Receptor": 'SD', // DUDA
          //"Localidad Receptor": 'SD', // DUDA
          
        }        
        resolve(factura) ;
      
      }
      catch(error){
        
        resolve(null);        
      }
    });
  }	

  validarCFDI(cfdi: any) : Promise<any> {  
		return new Promise((resolve, reject) => {
			this.servicios.archivos.validarCFDI(cfdi)
				.then(respuesta => {					
					resolve(respuesta);
				})
				.catch(error => {
					console.log('error ==>', error);
					resolve(false);
				})		
		});		
	}

  

  cargarTabla(): void{
    this.Procesando = true;
    // 38985 35893 34782
    this.dtOptions = {
			language: { url: '/js/espanol.json' },
			searching: true,      
      ajax: (dataTablesParameters: any, callback) => {
				this.obtenerFacturas()
					.subscribe(async (lista: any) => {                          
					  const archivosNormalizados = await this.normalizarArchivos(lista);	            
						this.archivos = archivosNormalizados;			              
						this.Procesando = false;						
						callback({ data: this.archivos });
					});				
			},      
			columns: [				
				//{ title: 'Verificado o Asociado', data: 'Verificado o Asociado' },
				{ title: 'Estado SAT', data: 'Estado SAT' },
        { title: 'Estado Validación', data: 'Estado Validación' },        
        { title: 'No Certificado Emisor', data: 'No Certificado Emisor'},
        { title: 'No Certificado SAT', data: 'No Certificado SAT'},
        { title: 'Versión', data: 'Versión'},
        { title: 'TipoComprobante', data: 'TipoComprobante'},
        //{ title: 'Tipo', data: 'Tipo'},
        { 
          title: 'Fecha Emisión',
          data: 'Fecha Emisión',
          type: 'date',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					},
        },
        { 
          title: 'Fecha Timbrado', 
          data: 'Fecha Timbrado',          
          type: 'date',
					render: (data: string, type) => {
						return type === 'display' || type === 'filter'
							? this.datepipe.transform(new Date(data), 'dd/MM/yyyy')
							: new Date(data);
					}
        },
        { title: 'Año', data: 'Año'},
        { title: 'Mes', data: 'Mes'},
        { title: 'Dia', data: 'Dia'},
        //{ title: 'Estado Pago', data: 'Estado Pago'},
        //{ title: 'Fecha Pago', data: 'Fecha Pago'},
        { title: 'Serie', data: 'Serie'},
        { title: 'Folio', data: 'Folio'},
        { title: 'UUID', data: 'UUID'},
        { title: 'Tipo Relación', data: 'Tipo Relación'},
        { title: 'UUID Relación', data: 'UUID Relación'},
        { title: 'RFC Emisor', data: 'RFC Emisor'},
        { title: 'Nombre Emisor', data: 'Nombre Emisor'},
        { title: 'Regimen Fiscal', data: 'Regimen Fiscal'},
        { title: 'Lugar Expedición', data: 'Lugar Expedición'},
        { title: 'RFC Receptor', data: 'RFC Receptor'},
        { title: 'Nombre Receptor', data: 'Nombre Receptor'},
        //{ title: 'Residencia Fiscal', data: 'Residencia Fiscal'},
        //{ title: 'NumRegIdTrib', data: 'NumRegIdTrib'},
        { title: 'Uso CDFI', data: 'Uso CDFI'},
        { 
          title: 'Total IEPS', 
          data: 'Total IEPS', 
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Total', 
          data: 'Total',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'SubTotal', 
          data: 'SubTotal',
          render: (data: number) =>
						data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Descuento', 
          data: 'Descuento',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
      
        { 
          title: 'IVA 16%',
          data: 'IVA 16%',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'IVA 8%', 
          data: 'IVA 8%',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'IVA Ret 6%', 
          data: 'IVA Ret 6%',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Retenido IVA', 
          data: 'Retenido IVA',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Retenido ISR', 
          data: 'Retenido ISR',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { title: 'ISH', data: 'ISH'},
       
        //{ title: 'Total Original', data: 'Total Original'},
        { 
          title: 'Total Trasladados', 
          data: 'Total Trasladados',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Total Retenidos', 
          data: 'Total Retenidos',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Total Local Trasladado', 
          data: 'Total Local Trasladado',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'Total Local Retenido', 
          data: 'Total Local Retenido',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        //{ title: 'Complemento', data: 'Complemento'},
        { title: 'Moneda', data: 'Moneda'},
        { 
          title: 'Tipo de Cambio', 
          data: 'Tipo de Cambio',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { title: 'Forma de Pago', data: 'Forma de Pago'},
        { title: 'Metodo de Pago', data: 'Metodo de Pago'},
        //{ title: 'NumCtaPago', data: 'NumCtaPago'},
        { title: 'Condición de Pago', data: 'Condición de Pago'},
        { 
          title: 'Conceptos', 
          data: 'Conceptos',
          width: '11%'
        },
        //{ title: 'Combustible', data: 'Combustible'},
        { 
          title: 'IEPS 3%', data: 'IEPS 3%',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { 
          title: 'IEPS 6%', data: 'IEPS 6%',
          render: (data: number) =>
          data != null ? MascaraMoneda.format(data) : '$ 0.00'
        },
        { title: 'IEPS 7%', data: 'IEPS 7%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 8%', data: 'IEPS 8%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 9%', data: 'IEPS 9%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 26.5%', data: 'IEPS 265%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 30%', data: 'IEPS 30%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 30.4%', data: 'IEPS 304%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 53%', data: 'IEPS 53%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'IEPS 160%', data: 'IEPS 160%',
        render: (data: number) =>
        data != null ? MascaraMoneda.format(data) : '$ 0.00'},
        { title: 'Archivo XML', data: 'Archivo XML'},
        //{ title: 'Direccion Emisos', data: 'Direccion Emisor'},
        //{ title: 'Localidad Emisor', data: 'Localidad Emisor'},
        //{ title: 'Direccion Receptor', data: 'Direccion Receptor'},
        //{ title: 'Localidad Receptor', data: 'Localidad Receptor'},       
			],
		};		
  }

  
  obtenerFacturas(): Observable<any[]> {
    if (!this.cargaInicial) {
      return of([]); 
    } else {
      if (this.origenDatos === 'sistema') {
        return this.servicios.archivos.buscarFacturas(this.criterioBusqueda).pipe(
          map((lista: any) => {
            return lista;
          })
        );
      } else {
        const archivosTransformados: any[] = this.archivosXML.map((archivo: any) => {          
          return archivo;
        });
        return of(archivosTransformados);
      }
    }
    
  }
  
  

  public obtenerConceptos(xmlDoc: Document): Promise<string> {
    return new Promise((resolve, reject) => {
      const conceptos = xmlDoc.getElementsByTagName('cfdi:Concepto');
      const conceptosArray = Array.from(conceptos);
      const conceptosStringArray: string[] = [];
    
      for (const concepto of conceptosArray) {
        const descripcion = concepto.getAttribute('Descripcion');
        if (descripcion) {
          conceptosStringArray.push(descripcion);
        }
      }
      resolve(conceptosStringArray.join(' * '));
    });            
  }

  getTipoRelacion(tipoRelacion: string): Promise<any> {  
		return new Promise((resolve, reject) => {
        switch(tipoRelacion) {
          case '01':
            resolve(String('01-Nota de crédito de los documentos relacionados').toUpperCase());
            break;
          case '02':
            resolve(String('02-Nota de débito de los documentos relacionados').toUpperCase());
            break;
          case '03':
            resolve(String('03-Devolución de mercancía sobre facturas o traslados previos').toUpperCase());
            break;
          case '04':
            resolve(String('04-Sustitución de los CFDI previos').toUpperCase());
            break;
          case '05':
            resolve(String('05-Traslados de mercancías facturados previamente').toUpperCase());
            break;
          case '06':
            resolve(String('06-Factura generada por los traslados previos').toUpperCase());
            break;
          case '07':
            resolve(String('07-CFDI por aplicación de anticipo').toUpperCase());
            break;
          default: 
            resolve(tipoRelacion);
            break;
        }
    });
  }

  getUsoCFDI(usoCFDI: string): Promise<any> {  
		return new Promise((resolve, reject) => {
      switch(usoCFDI){
        case 'G01':
          resolve(String('G01-Adquisición de mercancías.').toUpperCase());
          break;
        case 'G02':
          resolve(String('G02-Devoluciones, descuentos o bonificaciones.').toUpperCase());
          break;
        case 'G03':
          resolve(String('G03-Gastos en general.').toUpperCase());
          break;
        case 'I01':
          resolve(String('I01-Construcciones.').toUpperCase());
          break;
        case 'I02':
          resolve(String('I02-Mobiliario y equipo de oficina por inversiones.').toUpperCase());
          break;
        case 'I03':
          resolve(String('I03-Equipo de transporte.').toUpperCase());
          break;
        case 'I04':
          resolve(String('I04-Equipo de computo y accesorios.').toUpperCase());
          break;
        case 'I05':
          resolve(String('I05-Dados, troqueles, moldes, matrices y herramental.').toUpperCase());
          break;
        case 'I06':
          resolve(String('I06-Comunicaciones telefónicas.').toUpperCase());
          break;
        case 'I07':
          resolve(String('I07-Comunicaciones satelitales.').toUpperCase());
          break;
        case 'I08':
          resolve(String('I08-Otra maquinaria y equipo.').toUpperCase());
          break;
        case 'D01':
          resolve(String('D01-Honorarios médicos, dentales y gastos hospitalarios.').toUpperCase());
          break;
        case 'D02':
          resolve(String('D02-Gastos médicos por incapacidad o discapacidad.').toUpperCase());
          break;
        case 'D03':
          resolve(String('D03-Gastos funerales.').toUpperCase());
          break;
        case 'D04':
          resolve(String('D04-Donativos.').toUpperCase());
          break;
        case 'D05':
          resolve(String('D05-Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación).').toUpperCase());
          break;
        case 'D06':
          resolve(String('D06-Aportaciones voluntarias al SAR.').toUpperCase());
          break;
        case 'D07':
          resolve(String('D07-Primas por seguros de gastos médicos.').toUpperCase());
          break;
        case 'D08':
          resolve(String('D08-Gastos de transportación escolar obligatoria.').toUpperCase());
          break;
        case 'D09':
          resolve(String('D09-Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.').toUpperCase());
          break;
        case 'D10':
          resolve(String('D10-Pagos por servicios educativos (colegiaturas).').toUpperCase());
          break;
        case 'S01':
          resolve(String('S01-Sin efectos fiscales.').toUpperCase());
          break;
        case 'CP01':
          resolve(String('CP01-Pagos').toUpperCase());
          break;
        case 'CN01':
          resolve(String('CN01-Nómina').toUpperCase());
          break;
        default:
          resolve(usoCFDI);
          break;
      }
     

    });
  }

  
  geFormaPago(formaPago: string): Promise<any> {  
		return new Promise((resolve, reject) => {
      switch(formaPago) {
        case '01':
          resolve(String('01-Efectivo').toUpperCase());
        case '02':
          resolve(String('02-Cheque nominativo').toUpperCase());
        case '03':
          resolve(String('03-Transferencia electrónica de fondos').toUpperCase());
        case '04':
          resolve(String('04-Tarjeta de crédito').toUpperCase());
        case '05':
          resolve(String('05-Monedero electrónico').toUpperCase());
        case '06':
          resolve(String('06-Dinero electrónico').toUpperCase());
        case '08':
          resolve(String('08-Vales de despensa').toUpperCase());
        case '12':
          resolve(String('12-Dación en pago').toUpperCase());
        case '13':
          resolve(String('13-Pago por subrogación').toUpperCase());
        case '14':
          resolve(String('14-Pago por consignación').toUpperCase());
        case '15':
          resolve(String('15-Condonación').toUpperCase());
        case '17':
          resolve(String('17-Compensación').toUpperCase());
        case '23':
          resolve(String('23-Novación').toUpperCase());
        case '24':
          resolve(String('24-Confusión').toUpperCase());
        case '25':
          resolve(String('25-Remisión de deuda').toUpperCase());
        case '26':
          resolve(String('26-Prescripción o caducidad').toUpperCase());
        case '27':
          resolve(String('27-A satisfacción del acreedor').toUpperCase());
        case '28':
          resolve(String('28-Tarjeta de débito').toUpperCase());
        case '29':
          resolve(String('29-Tarjeta de servicios').toUpperCase());
        case '30':
          resolve(String('30-Aplicación de anticipos').toUpperCase());
        case '31':
          resolve(String('31-Intermediario pagos').toUpperCase());
        case '99':
          resolve(String('99-Por definir').toUpperCase());
        default: 
          resolve(formaPago);
          break;
      }
    });
  }  

  reporteExcel(){
    this.exportToXlsxService.reporteXMLFacturas(this.origenDatos  === 'sistema' ? `Reporte Facturas XML Referi ${this.mes ? this.mes+"-": ''}${this.anio ? this.anio: ''}`: "`Reporte Facturas XML Directorio Local", this.archivos);
  }

  

  limpiar() {
    this.anio = null;
    this.mes = null;
    this.sociedadComercial = null;    
    this.archivosXML = [];
  }

  seleccionarDirectorio() {
    const inputElement: HTMLInputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.setAttribute('webkitdirectory', 'true');
    inputElement.setAttribute('multiple', 'true');
    inputElement.accept = '.xml';
    inputElement.addEventListener('change', (event: Event) => {
      this.cargarArchivos(event);
    });
    inputElement.click();
  }

  async cargarArchivos(event: Event) {
    const archivosSeleccionados: FileList = (event.target as HTMLInputElement).files;
    const archivos: File[] = Array.from(archivosSeleccionados).filter(archivo => archivo.name.toLowerCase().endsWith('.xml'));
    this.archivosXML = await this.leerArchivos(archivos);    
    console.log('archivosXML-->',this.archivosXML);
  }

  
  leerArchivos(archivos: File[]): Promise<any[]> {  
		
    return new Promise((resolve, reject) => {
      const archivosXML: any[] = [];
  
      archivos.forEach(archivo => {
        const lector = new FileReader();
        lector.onload = (evento: any) => {
          const contenidoArchivo: string = evento.target.result;
          const archivoXML: any = {
            nombreArchivo: archivo.name,
            xml: contenidoArchivo
          };
          archivosXML.push(archivoXML);                        
        };
        lector.readAsText(archivo);
      });
      resolve(archivosXML);
    });              
  }
  
  
   
  

  ngOnDestroy(): void {
		this.oyente();
	}

}
