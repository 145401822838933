﻿import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Confirmacion, Notificacion } from '../../compartida/indice';
import { Sociedad } from '../../entidades/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'tabla-sociedad',
    templateUrl: 'tabla.component.html'
})
export class TablaCatalogoSociedad implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<Sociedad> = new Subject();
    @Output() editarOprimido: EventEmitter<Sociedad> = new EventEmitter<Sociedad>();
    @Output() eliminarOprimido: EventEmitter<number> = new EventEmitter<number>();
    private oyente: () => void;
    sociedades: Sociedad[];
    idSeleccionado: number;

    constructor(private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit(): void {
        this.cargarTabla();
    }

    ngAfterViewInit(): void {
        this.dtTrigger.next();
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let id = parseInt(objetivo.getAttribute('data-id'));
                if (tipo == 'a') {
                    this.editar(id);
                } 
                else if(tipo == 'f'){
                    this.servicios
                    .sociedades
                    .aplicaPruebasEntrega(id)
                    .then(aplicaPruebas => {
                        if (aplicaPruebas) {
                            this.notificacion.mostrarExito("La sociedad aplica pruebas entrega.");
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                dtInstance.ajax.reload();
                            });

                        } else {
                            this.notificacion.mostrarExito("La sociedad no aplica pruebas entrega.");
                            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                                dtInstance.ajax.reload();
                               
                            });
                        }
                    })
                    .catch(error => {
                        let httpError = <HttpErrorResponse>error;
                        this.notificacion.mostrarError(httpError.error);
                    });                                        
                }
                else {
                    this.idSeleccionado = id;
                    this.preguntarSieliminar();
                }
            }
              
        });
    }

    editar(id: number): void {
        let sociedad = this.sociedades.find(u => u.id == id);
        this.editarOprimido.emit(sociedad);
    }

    preguntarSieliminar(): void {
        this.confirmacion.open({
            texto: "¿Seguro que desea continuar?",
            textoCancelar: "No",
            textoOk: "Si",
            titulo: "Confirmacion"
        });
    }

    eliminar() {
        this.servicios
            .sociedades
            .eliminar(this.idSeleccionado.toString())
            .subscribe(
                eliminado => {
                    this.notificacion.mostrarExito("Sociedad eliminada.");
                    this.recargarTabla();
                },
            error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }

    cargarTabla(): void {
        this.dtOptions = {
            language: {url: '/js/espanol.json'},
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios
                    .sociedades
                    .obtenerTodos()
                    .subscribe(sociedades => {
                        this.sociedades = sociedades;
                        callback({ data: sociedades });
                    });
            },
            columns: [
                { title: 'Id', data: 'id', visible: false },
                { title: 'RFC', data: 'rfc' },
                { title: 'Razón social', data: 'razonSocial' },
                { title: 'Nombre', data: 'nombreComercial' },
                { title: 'Correo electrónico', data: 'correoElectronico' },
                { title: 'Regimen fiscal', data: 'regimenFiscal' },
                {
                    title: 'Aplica pruebas entrega',
                    data: 'aplicaPruebasEntrega',
                    width: "15%",
                    render: (data: any, type: any, full: Sociedad) => {                            
                            if (full.aplicaPruebasEntrega) {

                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.id + `" data-inverted="" data-tooltip="Aplica prueba de entrega" data-position="top center">
                                                    <i class="check square icon orange" acc tipo="f" data-id="` + full.id + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;
                            }
                            else {
                                return `<div class="ui icon buttons">
                                                <button class="ui basic button" acc tipo="d" data-id="` + full.id + `"  data-inverted="" data-tooltip="Aplica prueba de entrega" data-position="top center">
                                                    <i class="check square outline icon orange" acc tipo="f" data-id="` + full.id + `" style="font-size:22px; margin-left:20px !important;"></i>
                                                </button>
                                           </div>`;

                            }
                    }
                },
                {
                    title: 'Opciones',
                    data: 'id',
                    width: "15%",
                    render: function (data: any, type: any, full: any) {
                        return `<div class="ui icon buttons">
                                    <button class="ui orange basic button" acc tipo="a" data-id="` + data + `" data-inverted="" data-tooltip="Editar" data-position="top center">
                                        <i class="large edit icon" acc tipo="a" data-id="` + data + `"></i>
                                    </button>
                                    <button class="ui orange basic button" acc tipo="e" data-id="` + data + `" data-inverted="" data-tooltip="Eliminar" data-position="top center">
                                        <i class="large trash alternate icon" acc tipo="e" data-id="` + data + `"></i>
                                    </button>
                                </div>`;
                    }
                }
            ]
        };
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

    ngOnDestroy(): void {
        this.oyente();
    }

}