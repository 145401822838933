import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit  } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { DatepickerMode, PopupPlacement } from 'ng2-semantic-ui';
import { DatePipe } from '@angular/common';

import * as _ from 'lodash';

import { Aviso } from '../../../entidades/indice'
import { FormaModal, Notificacion } from '../../../compartida/indice';
import { Servicios } from '../../../servicios/indice';
import { Observable } from 'rxjs';
import * as mime from 'mime';



@Component({
  selector: 'app-aviso-modal',
  templateUrl: './aviso-modal.component.html',
  styleUrls: ['./aviso-modal.component.css']
})
export class AvisoModalComponent extends FormaModal implements AfterViewInit {
  @ViewChild('modalTemplateAviso') modalTemplate: ModalTemplate<void, void, void>
  @ViewChild('forma', { read: NgForm }) forma: NgForm;  
  @ViewChild('archivosAviso') archivosAviso: ElementRef<HTMLInputElement>;  
  idAviso: number;
  aviso: Aviso;
  avisoOriginal: any;
  textoAviso: string;    
  archivosPrevios: File[] = [];
  @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();

  modoFecha = DatepickerMode.Date;
  posicionCalendario: PopupPlacement = PopupPlacement.Bottom;
  constructor(
        public modalService: SuiModalService, 
        private servicios: Servicios,
        private notificacion: Notificacion,
        public datepipe: DatePipe,
    ) {
      super(modalService);         
  } 
 
  crearNuevo(): void {
    this.aviso = new Aviso();
  }

  limpiarArchivosPrevios(){
    this.archivosPrevios= [];
    this.aviso.eliminarPrevios = true;
  }

  abrirNuevo(): void {
      this.crearNuevo();
      this.abrir("Nuevo Aviso", true);
  }

  ngAfterViewInit() {        
  }

  async abrirEditar(): Promise<void> {      
      this.aviso = new Aviso();
      this.avisoOriginal = await this.servicios.avisos.getAviso(this.idAviso);    
      this.avisoOriginal.fechaInicio = new Date(this.avisoOriginal.fechaInicio);
      this.avisoOriginal.fechaFin = new Date(this.avisoOriginal.fechaFin);
      this.avisoOriginal.textoAviso = await this.obtenerTextoOriginal(this.avisoOriginal.textoAviso);        
      this.avisoOriginal.eliminarPrevios = false;      
      this.cargarArchivos(this.avisoOriginal.archivos);      
      this.aviso = _.cloneDeep(this.avisoOriginal);      
      this.abrir("Editar Aviso", false);
  }

  cargarArchivos(archivosAviso: any) {        
    this.archivosPrevios = [];
    if (archivosAviso && archivosAviso.length > 0) {        
        for(const archivo of archivosAviso) {
            this.servicios.avisos.descargarArchivo(archivo.id).subscribe((archivoBlob: Blob) => {
                const file = new File([archivoBlob], archivo.nombre, {type: mime.getType(archivo.nombre)});     
                this.archivosPrevios.push(file);                
              }, (error) => {
                console.error(error);
              });
        }        
    } 
  }

  cancelar(): void {
      this.cerrar();
  }

  enfocar(): void {
      
  }

  limpiar(): void {
      this.forma.resetForm();
      this.aviso = new Aviso();
      if (!this.MantenerAbierto) {
          this.cerrar();
      }
      this.enfocar();
  }

  guardar(): void {        
    let datos = new FormData();
    let archivosAvisoLista = (<HTMLInputElement>(
        this.archivosAviso.nativeElement
    )).files;
    
    for (let index = 0; index < archivosAvisoLista.length; index++) {
        const element = archivosAvisoLista[index];        
        datos.append('archivosAviso', element);
    }
    let avisoGuardar = _.cloneDeep(this.aviso);    

    
    avisoGuardar.textoAviso = this.obtenerContenidoTransformado(avisoGuardar.textoAviso);    
    datos.append('avisoJson', JSON.stringify(avisoGuardar));    
    this.Procesando = true;

    let observable: Observable<Aviso>;
    let mensaje: string;
    if (this.aviso.idAviso && this.aviso.idAviso > 0) {
          datos.append('archivosPrevios', this.avisoOriginal.archivos);
        mensaje = 'Aviso actualizado.';          
        observable = this.servicios.avisos.actualizarAviso(datos);
    } else {
        mensaje = 'Aviso creado.';
        observable = this.servicios.avisos.crearAviso(datos);       
    }

    observable
      .subscribe(
            usuarios => {
                this.notificacion.mostrarExito(mensaje);
                this.Procesando = false;
                this.limpiar();
                this.procesoTerminado.emit(true);
            },
            error => {
                let httpError = <HttpErrorResponse>error;
                this.notificacion.mostrarError(httpError.error);
                this.Procesando = false;
            }
      );        
    
    }

    obtenerContenidoTransformado(textoAviso: string): string {
        let lineas = textoAviso.split('\n');
        let contenidoTransformado = '';

        for (let i = 0; i < lineas.length; i++) {
            if (lineas[i].trim() !== '') {
            contenidoTransformado += '<p>' + lineas[i] + '</p>';
            if (i !== lineas.length - 1) {
                contenidoTransformado += '<br>';
            }
            }
        }

        return contenidoTransformado;
    }  
    
    
  obtenerTextoOriginal(contenidoTransformado: string):Promise<string> {        
    return new Promise((resolve) => {
        let textoOriginal = contenidoTransformado.replace(/<p>/g, '');
        textoOriginal = textoOriginal.replace(/<\/p>/g, '\n');
        textoOriginal = textoOriginal.replace(/<br>/g, '\n');        
        resolve(textoOriginal) ;
     });
  }
}