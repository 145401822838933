﻿import { Component, Input, ElementRef, ViewChild, TemplateRef, ViewContainerRef, Output, EventEmitter } from '@angular/core';
import { ModalTemplate, TemplateModalConfig, SuiModalService, ActiveModal, IPopup } from 'ng2-semantic-ui/dist';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormaModal, Notificacion } from '../../compartida/indice';
import { Sociedad } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';

@Component({
    selector: 'forma-sociedad',
    templateUrl: 'forma.component.html'
})
export class FormaSociedad extends FormaModal {
    @ViewChild('modalTemplateSociedad') modalTemplate: ModalTemplate<void, void, void>
    @ViewChild('forma', { read: NgForm }) forma: NgForm;
    @ViewChild('txtSociedad', { read: ElementRef }) txtsociedad: ElementRef;
    sociedad: Sociedad;
    @Output() procesoTerminado: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(public modalService: SuiModalService, private servicios: Servicios, private notificacion: Notificacion) {
        super(modalService);
        this.crearNuevo();
    }

    crearNuevo(): void {
        this.sociedad = new Sociedad();
    }

    abrirNuevo(): void {
        this.crearNuevo();
        this.abrir("Nuevo", true);
    }

    abrirEditar(): void {
        this.abrir("Editar", false);
    }

    cancelar(): void {
        this.cerrar();
    }

    enfocar(): void {
        this.txtsociedad.nativeElement.focus()
    }

    limpiar(): void {
        this.forma.resetForm();
        this.sociedad = new Sociedad();
        if (!this.MantenerAbierto) {
            this.cerrar();
        }
        this.enfocar();
    }

    guardar(): void {
        this.Procesando = true;

        let observable: Observable<Sociedad>;
        let mensaje: string;
        if (this.sociedad.id > 0) {
            mensaje = 'Sociedad actualizada.';
            observable = this.servicios.sociedades.actualizar(this.sociedad.id.toString(), this.sociedad) 
        } else {
            mensaje = 'Sociedad guardada.';
            observable = this.servicios.sociedades.guardar(this.sociedad);
        }

        observable
            .subscribe(
                sociedades => {
                    this.notificacion.mostrarExito(mensaje);
                    this.Procesando = false;
                    this.limpiar();
                    this.procesoTerminado.emit(true);
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.Procesando = false;
                }
            );
    }
}