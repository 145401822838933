﻿import { Component, Renderer2, TemplateRef, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { Corporativo } from '../../entidades/indice';
import { Servicios } from '../../servicios/indice';
import { SuiModalService } from 'ng2-semantic-ui';
import { Notificacion } from '../../compartida/indice';
import { Confirmacion } from '../../compartida/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';

@Component({
    selector: "catalogo-corporativo",
    templateUrl: "catalogo.component.html"
})
export class CatalogoReenvioCorreoPruebasEntrega implements OnInit {
    titulo: string = "Reenvio correo pruebas de entrega";
    procesando: boolean;
    corporativo: Corporativo;
    Procesando: boolean;
    dtOptions: DataTables.Settings = {};
    private oyente: () => void;
    idSeleccionado: number;
    @ViewChild(Confirmacion) confirmacion: Confirmacion;
    @ViewChild(DataTableDirective) dtElement: DataTableDirective;

    constructor(public modalService: SuiModalService, private servicios: Servicios, private renderer: Renderer2, private notificacion: Notificacion) {
    }

    ngOnInit() {
        this.cargarTabla();
    }

    reporteExcel(): void { }

    cargarTabla(): void {
        this.dtOptions = {
            language: { url: "/js/espanol.json" },
            searching: true,
            ajax: (dataTablesParameters: any, callback) => {
                this.servicios.ordenCompra
                    .obtenerPruebasEntregaSinEnviar()
                    .subscribe((lista) => {
                        callback({ data: lista });
                    });
                this.Procesando = false;
            },
            columns: [
                { title: "", data: "archivoPruebaId", visible: false },
                { title: "Proveedor", data: "proveedor" },
                { title: "Fecha", data: "fechaRegistro", render: (data: string) => (new Date(data)).toLocaleDateString() },
                { title: "Orden", data: "oc" },
                { title: "Factura", data: "factura" },
                // { title: "Nombre", data: "nombre" },
                {
                    title: 'Opciones',
                    data: 'archivoPruebaId',
                    width: "5%",
                    render: (data: any, type: any) => {
                        return `<div class="ui icon buttons">
                                        <button class="ui orange basic button" acc tipo="Reenviar" data-id="` + data + `"  data-inverted="" data-tooltip="Reenviar correo" data-position="top center">
                                            <i class="check large icon" acc tipo="Reenviar" data-id="` + data + `"></i>
                                        </button>
                                    </div>`;
                    }
                },


            ],
        };
    }

    ngAfterViewInit(): void {
        this.oyente = this.renderer.listen('body', 'click', (event) => {
            let objetivo = <HTMLElement>event.target;
            if (objetivo.hasAttribute('acc')) {
                let tipo = objetivo.getAttribute('tipo');
                let id = parseInt(objetivo.getAttribute('data-id'));
                this.idSeleccionado = id;
                if (tipo == 'Reenviar') {
                    this.reenviar();
                }
            }
        });
    }

    // async preguntarSiReenviar(): Promise<void> {
    //     this.confirmacion.open({
    //         texto: "¿Desea reenviar la factura",
    //         textoCancelar: "No",
    //         textoOk: "Si",
    //         titulo: "Confirmación",
    //         accionOk: this.reenviar.bind(this)
    //     });
    // }

    reenviar() {
        this.servicios
            .ordenCompra
            .reenviarCorreoApe(this.idSeleccionado)
            .subscribe(
                cerrada => {
                    if (cerrada == "true") {
                        this.notificacion.mostrarExito("Correo reenviado.");
                        this.recargarTabla();
                    } else {
                        this.notificacion.mostrarAdvertencia("Error al reenviar correo, verifique nuevamente.");
                        this.recargarTabla();
                    }
                },
                error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                }
            );
    }

    public recargarTabla(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload();
        });
    }

    ngOnDestroy(): void {
    }
}