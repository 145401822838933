import { first } from 'rxjs/operators';
import { Comunicado } from './../../entidades/comunicado';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Servicios } from '../../servicios/indice';
import { createText } from '@angular/core/src/view/text';
import { Confirmacion, Notificacion } from '../../compartida/indice';
import { NgForm } from '@angular/forms';
import { stringify } from 'querystring';
import { SeleccionProveedoresComponent } from '../seleccion-proveedores/seleccion-proveedores.component';

@Component({
  selector: 'app-envio-comunicado',
  templateUrl: './envio-comunicado.component.html',
  styleUrls: ['./envio-comunicado.component.css']
})
export class EnvioComunicadoComponent implements OnInit {
  titulo = 'Envio de comunicados';
  comunicado = new Comunicado();
  @ViewChild('forma', { read: NgForm }) forma: NgForm;
  // @ViewChild(Confirmacion) confirmacion: Confirmacion;
  @ViewChild(SeleccionProveedoresComponent) seleccionDestinatarios: SeleccionProveedoresComponent;
  
	@ViewChild('flArchivosAdjuntos', { read: ElementRef }) flArchivosAdjuntos: ElementRef;
  enviando = false;

  constructor(private ctx: Servicios, private notificacion: Notificacion) { }
  //constructor(){}

  ngOnInit() {
  }

  confirmar() {
    if (this.forma.valid && this.comunicado.mensaje && this.comunicado.mensaje.length > 0) {
      this.seleccionDestinatarios.open();
      // this.confirmacion.open({
      //   texto: "¿Seguro que desea continuar?",
      //   textoCancelar: "No",
      //   textoOk: "Si",
      //   titulo: "Confirmacion"
      // });
    }
    else {
      this.notificacion.mostrarAdvertencia('Datos inválidos');
    }
  }

  enviar(destinatarios: string[]) {
    this.enviando = true;
    let datos = new FormData();

    this.comunicado.destinatarios = destinatarios;
    datos.append('comunicado', JSON.stringify(this.comunicado));

    let adjuntos = (<HTMLInputElement>(
			this.flArchivosAdjuntos.nativeElement
		)).files;
    for (let index = 0; index < adjuntos.length; index++) {
			const element = adjuntos[index];
			datos.append('adjuntos', element);
		}


    this.ctx.comunicados.enviar(datos)
      .pipe(first())
      .subscribe(e => {
        this.notificacion.mostrarExito('Comunicado enviado.');
        this.limpiar()
      },
        error => this.notificacion.mostrarError(error.error),
        () => {this.enviando = false});

  }

  limpiar() {
    this.comunicado.asunto = "";
    this.comunicado.mensaje = "";
    this.flArchivosAdjuntos.nativeElement.value = '';
  }

}
