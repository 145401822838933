import { Component, Renderer2, ViewChild, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subject, Observable } from 'rxjs';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Servicios, ServicioArchivos, ServicioAutenticacion } from '../../servicios/indice';
import { forEach } from '@angular/router/src/utils/collection';
import { Archivo, Proveedor, Sociedad } from '../../entidades/indice';
import { Notificacion } from '../../compartida/indice';
import { DatepickerMode, PopupPlacement } from 'ng2-semantic-ui';

@Component({
    selector: 'app-traking-cfdi',
    templateUrl: './traking-cfdi.component.html',
    styleUrls: ['./traking-cfdi.component.css']
})
export class TrakingCFDIComponent implements OnInit {

    Procesando: boolean;
    titulo: string = "Traking";
    archivos: Archivo[];
    archivo: Archivo;

    completoEnvio: boolean;
    completoRecibido: boolean;
    completoAutorizado: boolean;
    completoProgramado: boolean;
    completoPagadoParcialmente: boolean;
    completoPagado: boolean;

    procesandoEnvio: boolean;
    procesandoRecibido: boolean;
    procesandoAutorizado: boolean;
    procesandoProgramado: boolean;
    procesandoPagadoParcialmente: boolean;
    procesandoPagado: boolean;

    bloqueadoEnvio: boolean;
    bloqueadoRecibido: boolean;
    bloqueadoAutorizado: boolean;
    bloqueadoProgramado: boolean;
    bloqueadoPagadoParcialmente: boolean;
    bloqueadoPagado: boolean;

    habilitarPagado: boolean;
    habilitarPagadoParcialmente: boolean;

    modoFecha = DatepickerMode.Date;
    posicionCalendario: PopupPlacement = PopupPlacement.Bottom;

    constructor(private servicios: Servicios, private autenticacion: ServicioAutenticacion, private renderer: Renderer2, private notificacion: Notificacion) { }

    ngOnInit() {
        this.archivo = new Archivo;
    }


    deshabilitarTodo(): void {
        this.completoEnvio = false;
        this.completoRecibido = false;
        this.completoAutorizado = false;
        this.completoProgramado = false;
        this.completoPagadoParcialmente = false;
        this.completoPagado = false;

        this.procesandoEnvio = false;
        this.procesandoRecibido = false;
        this.procesandoAutorizado = false;
        this.procesandoProgramado = false;
        this.procesandoPagadoParcialmente = false;
        this.procesandoPagado = false;

        this.bloqueadoEnvio = false;
        this.bloqueadoRecibido = false;
        this.bloqueadoAutorizado = false;
        this.bloqueadoProgramado = false;
        this.bloqueadoPagadoParcialmente = false;
        this.bloqueadoPagado = false;




        this.habilitarPagado = false;
        this.habilitarPagadoParcialmente = false;
    }

    habilitar(id: number): void {

        this.deshabilitarTodo();
        
        let arc = this.archivos.find(ele => ele.idArchivo == id);
        if (arc != null) {
            if (arc.fechaRegistro != null && arc.fechaRecepcion != null && arc.fechaAutorizo != null && arc.fechaPago != null && (arc.ordenCerrada == true || arc.ordenCerradaManualmente == true)) {

                this.completoEnvio = true;
                this.completoRecibido = true;
                this.completoAutorizado = true;
                this.completoProgramado = true;
                this.habilitarPagado = true;
                this.completoPagado = true;
                return;
            }
            else if (arc.fechaRegistro != null && arc.fechaRecepcion != null && arc.fechaAutorizo != null && arc.fechaPago != null && (arc.ordenCerrada == false || arc.ordenCerradaManualmente == false)) {

                this.completoEnvio = true;
                this.completoRecibido = true;
                this.completoAutorizado = true;
                this.completoProgramado = true;
                this.habilitarPagadoParcialmente = true;
                this.procesandoPagadoParcialmente = true;
                this.habilitarPagado = false;
                return;

            }
            else if (arc.fechaRegistro != null && arc.fechaRecepcion != null && arc.fechaAutorizo != null && arc.fechaPago == null && (arc.ordenCerrada == false || arc.ordenCerradaManualmente == false)) {

                this.completoEnvio = true;
                this.completoRecibido = true;
                this.completoAutorizado = true;
                this.procesandoProgramado = true;
                this.bloqueadoPagadoParcialmente = true;
                this.habilitarPagadoParcialmente = true;
                this.habilitarPagado = false;
                return;
            }
            else if (arc.fechaRegistro != null && arc.fechaRecepcion != null && arc.fechaAutorizo == null && arc.fechaPago == null && (arc.ordenCerrada == false || arc.ordenCerradaManualmente == false)) {
                this.completoEnvio = true;
                this.completoRecibido = true;
                this.procesandoAutorizado = true;
                this.bloqueadoProgramado = true;
                this.bloqueadoPagadoParcialmente = true;
                this.habilitarPagadoParcialmente = true;
                this.habilitarPagado = false;
                return;
            }
            else if (arc.fechaRegistro != null && arc.fechaRecepcion == null && arc.fechaAutorizo == null && arc.fechaPago == null && (arc.ordenCerrada == false || arc.ordenCerradaManualmente == false)) {
                this.completoEnvio = true;
                this.procesandoRecibido = true;
                this.bloqueadoAutorizado = true;
                this.bloqueadoProgramado = true;
                this.bloqueadoPagadoParcialmente = true;
                this.habilitarPagadoParcialmente = true;
                this.habilitarPagado = false;
                return;
            }
        }
    }


    buscar(): void {
        this.archivo.idProveedor = this.autenticacion.credencial.idUsuario;
        this.servicios
            .archivos
            .obtenerReporteTrackingProveedor(this.archivo)
            .subscribe(
                lista => {
                    this.archivos = lista;

                }
            );
    }

    limpiar(): void {
        this.archivo.nombreArchivo = null;
        this.archivo.fechaRegistroDesde = null;
        this.archivo.fechaRegistroHasta = null;
        this.deshabilitarTodo();
        this.archivos = null;
    }

    descargarPDF(id: number, nombre: string): void {
        let observable = this.servicios
            .archivos
            .descargarPdf(id)
            .subscribe(
                pdf => {
                    let a = document.createElement("a");
                    let blob = new Blob([pdf], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 100);
                }
            );
    }

    descargarXML(id: number, nombre: string): void {
        let observable = this.servicios
            .archivos
            .descargarXml(id)
            .subscribe(
                xml => {
                    let a = document.createElement("a");
                    let blob = new Blob([xml], { type: "octet/stream" });
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = nombre + ".xml";
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(() => {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 100);
                }
            );
    }
}
