﻿import { HttpClient} from '@angular/common/http';
import { Observable, EMPTY } from 'rxjs';
import { OCRD } from '../entidades/ocrd';
import { OITM } from '../entidades/oitm';
import { OPRC } from '../entidades/oprc';
import { OPRJ } from '../entidades/oprj';

export class ServicioSap {

    private readonly ruta;
    get Ruta() { return this.ruta };
    get ClienteHttp() { return this.http };

    constructor(private http: HttpClient) {
        this.ruta = '/api/Sap';
    }

    obtenerProveedores(sociedadRfc: string): Observable<OCRD[]> {
        return this.http.get<OCRD[]>(`${this.ruta}/Proveedores/${sociedadRfc}`)
    }

    obtenerArticulos(sociedadRfc: string): Observable<OITM[]> {
        return this.http.get<OITM[]>(`${this.ruta}/Articulos/${sociedadRfc}`)
    }

    obtenerCentrosCostos(sociedadRfc: string): Observable<OPRC[]>{
        return this.http.get<OPRC[]>(`${this.ruta}/CentrosCostos/${sociedadRfc}`)
    }

    obtenerProyectos(sociedadRfc: string): Observable<OPRJ[]>{
        return this.http.get<OPRJ[]>(`${this.ruta}/Proyectos/${sociedadRfc}`)
    }
}