import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../entidades/indice';
import { ServicioAutenticacion } from '../../servicios/indice';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Notificacion } from '../../compartida/indice';

@Component({
    selector: 'app-acceso',
    templateUrl: './acceso.component.html',
    styleUrls: ['./acceso.component.css']
})
export class AccesoComponent implements OnInit {
    usuario: Usuario;
    procesando: boolean;
    constructor(private router: Router, private autenticacion: ServicioAutenticacion, private notificacion:Notificacion ) { }

    ngOnInit() {
        this.usuario = new Usuario;
        if (this.autenticacion.estaAutenticado) {
            this.router.navigateByUrl('/Inicio');
        }
    }

    entrar(): void {
        this.procesando = true;
        this.autenticacion
            .acceder(this.usuario)
            .subscribe(
                credenciales => {
                    //this.autenticacion.almacenarCredencial(credenciales);
                    this.router.navigate(['/Inicio']);
                    this.procesando = false;
                }, error => {
                    let httpError = <HttpErrorResponse>error;
                    this.notificacion.mostrarError(httpError.error);
                    this.procesando = false;
                }
            );
    }
}
