﻿import { TipoOrdenCompra } from './tipo-orden-compra';
import { ArchivoPruebaEntrega } from "./archivos-prueba-entrega";
import { OrdenCompraSeleccionadas } from "./ordenes-compra-seleccionadas";
import { DetalleArchivo } from './detalle-archivo';

export class Archivo {
    idArchivo: number;
    folio: string;
    nombreArchivo: string;
    idProveedor: number;
    proveedorRazonSocial: string;
    fechaAutorizo: Date;
    fechaRegistro: Date;
    fechaRecepcion: Date;
    fechaRegistroDesde: Date;
    fechaRegistroHasta: Date;
    fechaAutorizoDesde: Date;
    fechaAutorizoHasta: Date;
    fechaRecepcionDesde: Date;
    fechaRecepcionHasta: Date;        
    fechaPago: Date;
    fechaPagoDesde: Date;
    fechaPagoHasta: Date;
    fechaPPD: Date;
    fechaPPH: Date;
    pagado: string;
    autorizado: string;
    cancelado: string;
    idSociedad: number;
    sociedadNombreComercial: string;
    sociedadRFC: string;
    subtotal: number;
    iva: number;
    total: number;
    retencion: number;
    ordenCompra: string;
    moneda: string;
    //ordene compra
    idOrdenCompra: number;
    numero: string;
    numeroOrdenCompra: string;
    subTotalOrdenCompra: number;
    ivaOrdenCompra: number;
    retencionOrdenCompra: number;
    totalOrdenCompra: number;
    monedaOrdenDeCompra: string;
    montoCubierto: number;
    ordenCerrada: boolean;
    ordenCerradaManualmente: boolean;
    ordenCancelada: boolean;
    archivoPruebaEntrega: ArchivoPruebaEntrega[] = [];
    ordenesComprasSeleccionadas: OrdenCompraSeleccionadas[] = [];
    tipoComprobante: string;
    aplicaConOSinOrdenCompra: boolean;
    esMesActualXML: boolean;
    mesTimbrado: number;
    procesadaEnSap:number;
    nombreArchivoPago: string;
    codigoArticuloSap: string;
    formaPago: string;
    detalles: DetalleArchivo[];
    mensajeSap: string;
    codigoProyectoSap: string;
    codigoCentroCostosSap: string;
    razonSocialValida: boolean = true;
    codigoPostalValido: boolean = true;
    fechaXml: string = '';
    metodoPago: string = '';
    aplicaPruebasEntrega: boolean = false;
    pruebasEntregaAceptadas: boolean = false;
    canceladoEnSap: string;
    pagadoEnSap: string;
    mismoProveedor: boolean;
    uuidComplemento: string;
    mismoRegimenFiscal: boolean;
    numFac: string;
}