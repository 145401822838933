import {
  Component,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
  OnInit,
} from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { Servicios } from "../../servicios/indice";
import { OrdenCompra, Proveedor } from "../../entidades/indice";
import { Notificacion, Confirmacion } from "../../compartida/indice";
import { DatepickerMode } from "ng2-semantic-ui";
import { NgForm } from "@angular/forms";
import { ErrorEnvioSap } from "../../entidades/error-envio-sap";

@Component({
  selector: "app-error-envio-sap",
  templateUrl: "./fallo-envio-sap.component.html",
  styleUrls: ["./fallo-envio-sap.component.css"],
})
export class ErrorEnvioSapComponent implements OnInit {
  titulo: string = "Error en envíos a SAP";
  idSeleccionado: number;
  Procesando: boolean;
  private oyente: () => void;

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  @ViewChild(Confirmacion) confirmacion: Confirmacion;

  constructor(private servicios: Servicios) {}

  ngOnInit() {
    this.cargarTabla();
  }

  reporteExcel(): void {}

  cargarTabla(): void {
    this.dtOptions = {
      language: { url: "/js/espanol.json" },
      searching: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.servicios.ordenCompra
          .obtenerErroresEnviosSap()
          .subscribe((lista) => {
            callback({ data: lista });
          });
        this.Procesando = false;
      },
      columns: [
        { title: "", data: "id", visible: false },
        { title: "Fecha", data: "fechaRegistro", render: (data: string) => (new Date(data)).toLocaleDateString()},
        { title: "Orden", data: "numero" },
        { title: "Sub Total", data: "subTotal" },
        { title: "Iva", data: "iva" },
        { title: "Total", data: "total" },
        { title: "Retención", data: "retencion" },
        { title: "Monto cubierto", data: "montoCubierto" },
        { title: "Monto cubierto", data: "montoCubierto" },
        { title: "Proveedor", data: "proveedorRs" },
        { title: "Error", data: "errorEnvio" },

      ],
    };
  }

  ngOnDestroy(): void {}
}
